import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import * as Tabs from '@radix-ui/react-tabs';
import * as Label from '@radix-ui/react-label';
import * as Dialog from '@radix-ui/react-dialog';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Lock, Users, Download, Trash2, UserPlus, UserMinus, Shield } from 'lucide-react';
import { TextArea, Select } from '@radix-ui/themes';
import axios from 'axios';
import '../styles/MyAccount.css';
import Swal from 'sweetalert2';

const industries = [
  'E-commerce and Retail',
  'Technology and Startups',
  'Professional Services',
  'Healthcare and Wellness',
  'Hospitality and Travel',
  'Education and Training',
  'Media and Entertainment',
  'Manufacturing and Industrial',
  'Real Estate and Construction',
  'Finance and Insurance',
  'Non-Profit and Social Enterprise',
  'Agriculture and Food',
  'Energy and Utilities',
  'Transportation and Logistics',
  'Creative and Design Services',
  'Telecommunications',
  'Gaming and Esports',
  'Personal Services'
];

const jobs = [
  'Product Management',
  'Engineering',
  'Human ressources',
  'Finance',
  'Marketing', 
  'Sales',
  'Operations',
  'Data Science',
  'Design',
  'Legal',
  'Developer',
  'Other',
];

const AccountContent = ({ profile, handleProfileChange, handleProfileUpdate, setShowPasswordModal }) => (
  <>
    <div className="account-page-profile-section">
      <div className="account-page-avatar-section">
        <div>
          <div className="account-page-avatar-container">
            {profile.firstName?.[0]}{profile.lastName?.[0]}
          </div>
        </div>

        <form onSubmit={handleProfileUpdate} className="account-page-form-container">
          <div className="account-page-form-group">
            <Label.Root className="account-page-form-label">Full name</Label.Root>
            <input
              type="text"
              name="firstName"  // This remains as firstName
              className="account-page-form-input"
              value={`${profile.firstName} ${profile.lastName}`.trim()}  // Properly combine names
              onChange={handleProfileChange}
              placeholder="Steve Jobs"
              required
            />
          </div>

          <div className="account-page-form-group">
            <Label.Root className="account-page-form-label">What should we call you?</Label.Root>
            <input
              type="text"
              name="nickname"
              className="account-page-form-input"
              value={profile.nickname}
              onChange={handleProfileChange}
              placeholder="e.g. Steve or Master S"
            />
          </div>

          <div className="account-page-form-group">
            <Label.Root className="account-page-form-label">Name of your company</Label.Root>
            <input
              type="text"
              name="companyName"
              className="account-page-form-input"
              value={profile.companyName}
              onChange={handleProfileChange}
              placeholder="Apple Inc."
            />
          </div>

          <div className="account-page-form-group">
            <Label.Root className="account-page-form-label">What is your industry?</Label.Root>
            <Select.Root value={profile.companyIndustry} onValueChange={(value) => handleProfileChange({ target: { name: 'companyIndustry', value }})}>
              <Select.Trigger className="account-page-form-input" />
              <Select.Content>
                <Select.Group>
                  <Select.Label>Select Industry</Select.Label>
                  {industries.map((industry) => (
                    <Select.Item key={industry} value={industry}>
                      {industry}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </div>

          <div className="account-page-form-group">
            <Label.Root className="account-page-form-label">What best describes your work?</Label.Root>
            <Select.Root value={profile.companyJob} onValueChange={(value) => handleProfileChange({ target: { name: 'companyJob', value }})}>
              <Select.Trigger className="account-page-form-input" />
              <Select.Content>
                <Select.Group>
                  <Select.Label>Select Work Function</Select.Label>
                  {jobs.map((job) => (
                    <Select.Item key={job} value={job}>
                      {job}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </div>

          <div className="account-page-form-group">
            <Label.Root className="account-page-form-label">
              What personal preferences should Calk consider when answering?
            </Label.Root>
            <div className="account-page-helper-text">
              Every AI will take these preferences as context to answer the best way. It can be style preferences or 
              specific way of working that you like to align AIs to help you the best way.
            </div>
            <TextArea 
              name="preferences"
              value={profile.preferences}
              onChange={handleProfileChange}
              placeholder="I prefer responses to be concise and straight to the point..."
              style={{
                width: '100%',
                minHeight: '100px',
                fontSize: '14px',
                borderColor: '#e5e7eb',
                borderRadius: '6px',
              }}
            />
          </div>

          <div className="account-page-form-footer">
            <button type="submit" className="account-page-update-button">
              Update Profile
            </button>
          </div>
        </form>
      </div>
    </div>

    <div className="account-page-security-section">
      <h3 className="account-page-security-title">Security</h3>
      
      <div className="account-page-security-card">
        <div className="account-page-security-content">
          <Lock size={20} />
          <div>
            <div className="account-page-security-text">Change your password</div>
            <div className="account-page-security-description">Use a strong password to login to your Calk account.</div>
          </div>
        </div>
        <button className="account-page-button" onClick={() => setShowPasswordModal(true)}>
          Change Password
        </button>
      </div>

      <div className="account-page-security-card">
        <div className="account-page-security-content">
          <Users size={20} />
          <div>
            <div className="account-page-security-text">Two-factor authentication</div>
            <div className="account-page-security-description">Add additional verification to your account.</div>
          </div>
        </div>
        <button className="account-page-button soon">Coming Soon</button>
      </div>
    </div>
  </>
);

const TeamContent = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const { userRole, userEmail } = useAuth();

  useEffect(() => {
    if (userRole === 'Administrator') {
      fetchTeamMembers();
    }
  }, [userRole]);

  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/team/members`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      setTeamMembers(response.data);
    } catch (error) {
      console.error('Error fetching team members:', error);
    }
  };

  const handleUpdateRole = async (memberId, newRole) => {
    if (userRole !== 'Administrator') return;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND}/api/team/members/${memberId}`,
        { role: newRole },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
      );
      if (response.data.member) {
        setTeamMembers(prevMembers => 
          prevMembers.map(member => 
            member._id === memberId ? response.data.member : member
          )
        );
      }
    } catch (error) {
      console.error('Error updating member role:', error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Error updating member role",
        showConfirmButton: false,
        timer: 3000
      });
    }
  };

  const handleDeleteMember = async (memberId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/team/members/${memberId}`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      setTeamMembers(prevMembers => prevMembers.filter(member => member._id !== memberId));
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Member deleted successfully",
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error deleting team member:', error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Error deleting team member",
        showConfirmButton: false,
        timer: 3000
      });
    }
  };

  return (
    <div className="account-page-team-content">
      <div className="account-page-team-header">
        <h2 className="account-page-team-title">Team Members</h2>
        <button onClick={() => setIsDialogOpen(true)} className="account-page-team-invite-button">
          <UserPlus size={20} />
          Invite Member
        </button>
      </div>

      <div className="account-page-team-list">
        {teamMembers.map((member, index) => (
          <div key={member._id} className="account-page-member-row">
            <div className="account-page-member-info">
              <div className={`account-page-avatar account-page-avatar-${index % 8}`}>
                {member.firstName?.[0]}{member.lastName?.[0]}
              </div>
              <div className="account-page-member-details">
                <span className="account-page-member-name">{member.firstName} {member.lastName}</span>
                <span className="account-page-member-email">{member.email}</span>
              </div>
            </div>
            
            <span className="account-page-member-role">{member.role}</span>

            {userRole === 'Administrator' && member.email !== userEmail && (
              <DropdownMenu.Root>
                <DropdownMenu.Trigger className="account-page-dropdown-trigger">
                  •••
                </DropdownMenu.Trigger>
                <DropdownMenu.Content className="account-page-dropdown-content">
                  <DropdownMenu.Item 
                    className="account-page-dropdown-item"
                    onClick={() => handleUpdateRole(member._id, member.role === 'Administrator' ? 'User' : 'Administrator')}
                  >
                    <Shield size={16} />
                    {member.role === 'Administrator' ? 'Make User' : 'Make Admin'}
                  </DropdownMenu.Item>
                  <DropdownMenu.Item 
                    className="account-page-dropdown-item delete"
                    onClick={() => setMemberToDelete(member)}
                  >
                    <UserMinus size={16} />
                    Remove Member
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const MyAccount = () => {
  const { userFirstName, userLastName, userEmail, userNickname, userCompanyJob, userPreferences, updateProfile, updatePassword} = useAuth();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [profile, setProfile] = useState({
    firstName: userFirstName || '',
    lastName: userLastName || '',
    email: userEmail || '',
    nickname: userNickname || '',
    companyName: '',
    companyIndustry: '',
    companyJob: userCompanyJob || '',
    preferences: userPreferences || '' 
  });
  const [passwords, setPasswords] = useState({
    current: '',
    new: '',
    confirm: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const loadProfileData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/team/company-details`,
          { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
        );
        
        setProfile(prev => ({
          ...prev,
          firstName: userFirstName || '',
          lastName: userLastName || '',
          email: userEmail || '',
          nickname: userNickname || '',
          companyJob: userCompanyJob || '',
          preferences: userPreferences || '',
          companyName: response.data.companyName || '',
          companyIndustry: response.data.companyIndustry || '',
        }));
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    loadProfileData();
  }, [userFirstName, userLastName, userEmail, userNickname, userCompanyJob, userPreferences]);

  const fetchCompanyDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/team/company-details`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      setProfile(prev => ({
        ...prev,
        companyName: response.data.companyName || '',
        companyIndustry: response.data.companyIndustry || '',
        companyJob: response.data.companyJob || ''
      }));
    } catch (error) {
      console.error('Error fetching company details:', error);
    }
  };

  // const handleProfileChange = (e) => {
  //   const { name, value } = e.target;
  //   setProfile(prev => ({
  //     ...prev,
  //     [name]: value
  //   }));
  // };

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'firstName') {
      // Split full name into first and last name
      const [firstName, ...lastNameParts] = value.trim().split(' ');
      const lastName = lastNameParts.join(' ');
      setProfile(prev => ({
        ...prev,
        firstName: firstName || '',
        lastName: lastName || ''
      }));
    } else {
      setProfile(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    try {
      // Use updateProfile from AuthContext to handle the user-specific updates
      const result = await updateProfile({
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        companyJob: profile.companyJob,
        nickname: profile.nickname,
        preferences: profile.preferences 
      });

      // Only update company information separately since it's not user-specific
      await axios.patch(
        `${process.env.REACT_APP_BACKEND}/api/team/company-information`,
        {
          companyName: profile.companyName,
          companyIndustry: profile.companyIndustry,
        },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
      );
      
      if (result.success) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Profile updated successfully",
          showConfirmButton: false,
          timer: 1000
        });
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Failed to update profile",
        showConfirmButton: false,
        timer: 3000
      });
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (passwords.new !== passwords.confirm) {
      setError("Passwords don't match");
      return;
    }
    try {
      const result = await updatePassword(passwords.current, passwords.new);
      if (result.success) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Password updated successfully",
          showConfirmButton: false,
          timer: 1000
        });
        setPasswords({ current: '', new: '', confirm: '' });
        setShowPasswordModal(false);
      } else {
        setError(result.message);
      }
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Failed to change password",
        showConfirmButton: false,
        timer: 3000
      });
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/delete-account`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Account deleted successfully!",
          showConfirmButton: false,
          timer: 1000
        });
        setTimeout(() => {
          window.location.href = '/login';
        }, 2000);
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Failed to delete account",
          showConfirmButton: false,
          timer: 3000
        });
      }
    } catch (error) {
      setError('Failed to delete account');
    }
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/download-data`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to download data');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'user-data.json';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();

    } catch (error) {
      console.error('Error downloading data:', error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Failed to download data",
        showConfirmButton: false,
        timer: 3000
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const SettingsContent = () => (
    <div className="account-page-settings-content">
      <div>
        <h3 className="account-page-security-title">Download My Data</h3>
        <p className="account-page-security-description">Download all your account data and information</p>
        <button 
          className={`account-page-button-settings ${isDownloading ? 'disabled' : ''}`} 
          onClick={handleDownload}
          disabled={isDownloading}
        >
          <Download className={isDownloading ? 'opacity-50' : ''} />&nbsp;
          {isDownloading ? 'Downloading...' : 'Download Data'}
        </button>
      </div>

      <div>
        <h3 className="account-page-security-title">Delete Account</h3>
        <p className="account-page-security-description">Permanently delete your account and all associated data</p>
        <AlertDialog.Root>
          <AlertDialog.Trigger asChild>
            <button className="account-page-button-settings red">
              <Trash2 />&nbsp;
              Delete Account
            </button>
          </AlertDialog.Trigger>
          <AlertDialog.Portal>
            <AlertDialog.Overlay className="account-page-modal-overlay" />
            <AlertDialog.Content className="account-page-alert-content">
              <AlertDialog.Title className="account-page-alert-title">
                Delete Account
              </AlertDialog.Title>
              <AlertDialog.Description className="account-page-alert-description">
                This action cannot be undone. This will permanently delete your account
                and remove your data from our servers.
              </AlertDialog.Description>
              <div className="account-page-alert-buttons">
                <AlertDialog.Cancel asChild>
                  <button className="account-page-alert-button cancel">
                    Cancel
                  </button>
                </AlertDialog.Cancel>
                <AlertDialog.Action asChild>
                  <button className="account-page-alert-button delete" onClick={handleDeleteAccount}>
                    Delete Account
                  </button>
                </AlertDialog.Action>
              </div>
            </AlertDialog.Content>
          </AlertDialog.Portal>
        </AlertDialog.Root>
      </div>
    </div>
  );

  return (
    <div className="account-page-container">
      <Tabs.Root defaultValue="account">
        <Tabs.List className="account-page-tabs-list">
          <Tabs.Trigger className="account-page-tab-trigger" value="account">
            Profile
          </Tabs.Trigger>
          <Tabs.Trigger className="account-page-tab-trigger" value="team">
            Team
          </Tabs.Trigger>
          <Tabs.Trigger className="account-page-tab-trigger" value="settings">
            Account
          </Tabs.Trigger>
        </Tabs.List>

        <div className="account-page-content-panel">
          <Tabs.Content value="account">
            <AccountContent 
              profile={profile}
              handleProfileChange={handleProfileChange}
              handleProfileUpdate={handleProfileUpdate}
              setShowPasswordModal={setShowPasswordModal}
            />
          </Tabs.Content>

          <Tabs.Content value="team">
            <TeamContent />
          </Tabs.Content>

          <Tabs.Content value="settings">
            <SettingsContent />
          </Tabs.Content>
        </div>
      </Tabs.Root>

      <Dialog.Root open={showPasswordModal} onOpenChange={setShowPasswordModal}>
        <Dialog.Portal>
          <Dialog.Overlay className="account-page-modal-overlay">
            <Dialog.Content className="account-page-modal-content">
              <div className="account-page-dialog-header">
                <Dialog.Title className="account-page-dialog-title">Change Password</Dialog.Title>
              </div>
              <form onSubmit={handlePasswordChange}>
                <div className="account-page-form-group">
                  <label className="account-page-form-label">Current Password</label>
                  <input
                    type="password"
                    className="account-page-form-input"
                    value={passwords.current}
                    onChange={(e) => setPasswords({...passwords, current: e.target.value})}
                  />
                </div>
                <div className="account-page-form-group">
                  <label className="account-page-form-label">New Password</label>
                  <input
                    type="password"
                    className="account-page-form-input"
                    value={passwords.new}
                    onChange={(e) => setPasswords({...passwords, new: e.target.value})}
                  />
                </div>
                <div className="account-page-form-group">
                  <label className="account-page-form-label">Confirm New Password</label>
                  <input
                    type="password"
                    className="account-page-form-input"
                    value={passwords.confirm}
                    onChange={(e) => setPasswords({...passwords, confirm: e.target.value})}
                  />
                </div>
                <div className="account-page-dialog-footer">
                  <button 
                    type="button" 
                    className="account-page-button" 
                    onClick={() => setShowPasswordModal(false)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="account-page-button">
                    Update Password
                  </button>
                </div>
              </form>
              <Dialog.Close asChild>
                <button className="account-page-dialog-close" aria-label="Close">
                  ×
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>

      {error && <div className="account-page-error-message">{error}</div>}
      {success && <div className="account-page-success-message">{success}</div>}
    </div>
  );
};

export default MyAccount;