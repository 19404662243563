import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { MessageContainer, MessageHeader, Message, MessageActions } from '../components/MessageComponents';
import { FilesBanner, TalkingToBanner, MentionDropdown, InputButtons } from '../components/InputComponents';
import { ChatHeader } from '../components/HeaderComponents';
import { EntityTabs } from '../components/EntityComponents';
import HomeHeader from '../components/HomeHeader';
import { useAuth } from '../context/AuthContext';
import Intercom from '@intercom/messenger-js-sdk';
import hljs from 'highlight.js';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../styles/HomeChat.css';


const DRIVE_CACHE_KEY = 'drive_files_cache';
const ANALYTICS_CACHE_KEY = 'analytics_cache';
const CACHE_DURATION = 30 * 60 * 1000; // 15 minutes


const HomeChat2 = ({ chatId: initialChatId, initialSelectedEntity }) => {
  const { chatId: routeChatId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // State Management
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [title, setTitle] = useState('New Chat');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [models, setModels] = useState([]);
  const [agents, setAgents] = useState([]);
  const [currentMode, setCurrentMode] = useState('models');
  const [selectedEntity, setSelectedEntity] = useState(null);
  const entityFromState = location.state?.initialSelectedEntity;
  const [isConversationStarted, setIsConversationStarted] = useState(false);
  const [showMentionDropdown, setShowMentionDropdown] = useState(false);
  const [mentionIndex, setMentionIndex] = useState(-1);
  const [lastMentionIndex, setLastMentionIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [imageData, setImageData] = useState('');
  const [imageLoaded, setImageLoaded] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const [fileContent, setFileContent] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isEnhancing, setIsEnhancing] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [connectedIntegrations, setConnectedIntegrations] = useState([]);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [isFirstMessageHere, setIsFirstMessageHere] = useState(false);
  const [progressState, setProgressState] = useState(null);
  const [participatingEntities, setParticipatingEntities] = useState([]);

  // Refs
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const titleInputRef = useRef(null);
  const { userFirstName, userLastName, userEmail, companyId } = useAuth();
  const dropdownRef = useRef(null);
  const selectedItemRef = useRef(null);


  Intercom ({
    app_id: 'vilfkctg',
    // user_id: user.id,
    name: userFirstName,
    email: userEmail,
    companyId: companyId
  });

  useEffect(() => {
    const prefetchData = async () => {
      try {
        // Check if we have valid cache for Drive files
        const driveCached = localStorage.getItem(DRIVE_CACHE_KEY);
        if (!driveCached || Date.now() - JSON.parse(driveCached).timestamp > CACHE_DURATION) {
          const driveResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/google/files`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
          );
          localStorage.setItem(DRIVE_CACHE_KEY, JSON.stringify({
            data: driveResponse.data,
            timestamp: Date.now()
          }));
        }

        // Check if we have valid cache for Analytics
        const analyticsCached = localStorage.getItem(ANALYTICS_CACHE_KEY);
        if (!analyticsCached || Date.now() - JSON.parse(analyticsCached).timestamp > CACHE_DURATION) {
          const analyticsResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/analytics/user-stats`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
          );
          localStorage.setItem(ANALYTICS_CACHE_KEY, JSON.stringify({
            data: analyticsResponse.data,
            timestamp: Date.now()
          }));
        }
      } catch (error) {
        console.error('Error pre-fetching data:', error);
      }
    };

    // Only pre-fetch if the user is logged in (we have a token)
    if (localStorage.getItem('token')) {
      prefetchData();
    }
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    const refreshInterval = setInterval(async () => {
      try {
        // Refresh Drive cache
        const driveResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/google/files`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        localStorage.setItem(DRIVE_CACHE_KEY, JSON.stringify({
          data: driveResponse.data,
          timestamp: Date.now()
        }));

        // Refresh Analytics cache
        const analyticsResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/analytics/user-stats`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        localStorage.setItem(ANALYTICS_CACHE_KEY, JSON.stringify({
          data: analyticsResponse.data,
          timestamp: Date.now()
        }));
      } catch (error) {
        console.error('Error refreshing cache:', error);
      }
    }, CACHE_DURATION); // Refresh every 15 minutes

    return () => clearInterval(refreshInterval);
  }, []);

  // Initial Setup
  useEffect(() => {
    fetchModels();
    fetchAgents();
    setIsEnhancing(false);
  }, []);

  useEffect(() => {
    setIsFirstMessageHere(messages.length > 0);
  }, [messages]);

  useEffect(() => {
    if (entityFromState) {
      setSelectedEntity(entityFromState);
      setSelectedEntities([entityFromState]);
    }
  }, [entityFromState]);

  useEffect(() => {
    const initializeExistingChat = async () => {
      if (!routeChatId) return;

      try {
        // Get basic chat info first
        const chatResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/chat/${routeChatId}`,
          { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
        );

        if (!chatResponse.data) {
          throw new Error('Chat not found');
        }

        setTitle(chatResponse.data.title);
        setChatId(routeChatId);
        setIsConversationStarted(true);
        setParticipatingEntities(chatResponse.data.participatingEntities || []);

        // Determine if this is an agent chat by checking for agentId
        const isAgentChat = !!chatResponse.data.agentId;

        if (isAgentChat) {
          // Fetch agent details
          const agentResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/agents/details/${chatResponse.data.agentId}`,
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
          );

          const matchingAgent = agents.find(a => a._id === chatResponse.data.agentId);
          if (matchingAgent) {
            const agentEntity = {
              ...agentResponse.data,
              type: 'agent',
              entityType: 'agent',
              icon: matchingAgent.icon,
              iconPath: `/images/all_icons/${matchingAgent.icon}`
            };
            setSelectedEntity(agentEntity);
            setSelectedEntities([agentEntity]);
          }

          // Fetch messages for agent chat
          const messagesResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/agents/${chatResponse.data.agentId}/chat/${routeChatId}/messages`,
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
          );

          // Process messages to ensure they have the correct agent info
          const processedMessages = messagesResponse.data.map(message => {
            if (message.role === 'assistant') {
              return {
                ...message,
                agentId: agentResponse.data._id,
                agentName: agentResponse.data.name
              };
            }
            return message;
          });
          // Filter out duplicate user messages
          const seenUserMessages = new Set();
          const uniqueMessages = processedMessages.filter(message => {
            if (message.role === 'user' && message.content.includes('@')) {
              if (seenUserMessages.has(message.content)) {
                return false;
              }
              seenUserMessages.add(message.content);
            }
            return true;
          });

          setMessages(uniqueMessages);

          // setMessages(processedMessages);
          // Scroll to bottom after messages are loaded
          if (isInitialLoad) {
            setTimeout(() => {
              scrollToBottom();
              setIsInitialLoad(false);
            }, 100);
          }
        } else {
          // Handle model chat
          const matchingModel = models.find(m => m.name === chatResponse.data.aiModel);
          if (matchingModel) {
            const modelEntity = {
              ...matchingModel,
              type: 'model',
              icon: `/images/all_icons/${matchingModel.icon}`
            };
            setSelectedEntity(matchingModel);
            setSelectedEntities([matchingModel]);
          }

          // Fetch messages for model chat
          const messagesResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/chat/${routeChatId}/messages`,
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
          );

          // Filter out duplicate user messages
          const seenUserMessages = new Set();
          const uniqueMessages = messagesResponse.data.filter(message => {
            if (message.role === 'user' && message.content.includes('@')) {
              if (seenUserMessages.has(message.content)) {
                return false;
              }
              seenUserMessages.add(message.content);
            }
            return true;
          });

          setMessages(uniqueMessages);
          // setMessages(messagesResponse.data);
        }

        // Scroll to bottom after messages are loaded
        if (isInitialLoad) {
          setTimeout(() => {
            scrollToBottom();
            setIsInitialLoad(false);
          }, 100);
        }

      } catch (error) {
        console.error('Error initializing chat:', error);
        setError('Failed to load chat');
        navigate('/home');
      }
    };

    initializeExistingChat();

  }, [routeChatId, location.pathname, models, navigate]);

  // Modify your useEffect to set initial selection
  useEffect(() => {
    if (showMentionDropdown) {
      setMentionIndex(0); // Set first item as selected by default
    }
  }, [showMentionDropdown]);

  // Add this useEffect to handle scrolling
  useEffect(() => {
    if (selectedItemRef.current && dropdownRef.current) {
      const dropdownContainer = dropdownRef.current;
      const selectedItem = selectedItemRef.current;
      
      const containerHeight = dropdownContainer.offsetHeight;
      const itemTop = selectedItem.offsetTop;
      const itemHeight = selectedItem.offsetHeight;
      
      // Check if selected item is not fully visible
      if (itemTop < dropdownContainer.scrollTop || 
          itemTop + itemHeight > dropdownContainer.scrollTop + containerHeight) {
        // Center the item in the dropdown
        dropdownContainer.scrollTop = itemTop - (containerHeight / 2) + (itemHeight / 2);
      }
    }
  }, [mentionIndex]);

  useEffect(() => {
    setIsInitialLoad(true);
  }, [routeChatId]);

  useEffect(() => {
    scrollToBottom();
  }, [input]);

  // Syntax highlighting effect
  useEffect(() => {
    document.querySelectorAll('pre code').forEach((block) => {
      hljs.highlightElement(block);
    });
  }, [messages]);

  useEffect(() => {
    if (isConversationStarted && selectedEntity) {
      // If the selected entity has an agentId property, it's an agent
      setCurrentMode(selectedEntity.agentId ? 'agents' : 'models');
    }
  }, [isConversationStarted, selectedEntity]);

  useEffect(() => {
    const fetchConnectedIntegrations = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/integrations/connected`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setConnectedIntegrations(response.data.filter(integration => 
          integration !== 'google-calendar' && integration !== 'gmail'
        ));
      } catch (error) {
        console.error('Error fetching connected integrations:', error);
      }
    };

    fetchConnectedIntegrations();
  }, []);

  const handleCreateAgent = () => {
    navigate('/agents/create');
  };

  const getAllEntities = (models, agents) => {
    return [
      ...models.map(model => ({
        ...model,
        entityType: 'model',
        iconPath: `/images/all_icons/${model.icon}`
      })),
      ...agents.map(agent => ({
        ...agent,
        entityType: 'agent',
        iconPath: `/images/all_icons/${agent.icon}`
      }))
    ];
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // console.log('Text copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const handleFeedback = async (messageId, isPositive) => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/chat/${chatId}/feedback`, 
        { messageId, isPositive },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      // Update local state to reflect the feedback
      setMessages(prevMessages => prevMessages.map(msg => 
        msg._id === messageId ? { ...msg, feedback: isPositive ? 'positive' : 'negative' } : msg
      ));
    } catch (error) {
      console.error('Error sending feedback:', error);
      setError('Error sending feedback. Please try again.');
    }
  };

  const handleEnhancePrompt = async () => {
    setIsEnhancing(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/chat/enhance-prompt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ userInput: input })
      });
      const data = await response.json();
      setInput(data.generatedPrompt);
      // Wait for next tick to ensure the textarea has updated with new input
      setTimeout(() => {
        if (inputRef.current) {
          adjustTextareaHeight(inputRef.current);
        }
      }, 0);
    } catch (error) {
      console.error('Error enhancing prompt:', error);
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Oops...",
        text: "Error enhancing prompt!",
        showConfirmButton: false,
        timer: 2000,
        width: 300,
      });
    } finally {
      setIsEnhancing(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Fetch Functions
  const fetchModels = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chatmodels`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const filteredModels = response.data.filter(model => model.name !== 'Multi Modal');
      setModels(filteredModels);
      // setModels(response.data);
    } catch (error) {
      console.error('Error fetching chat models:', error);
      setError('Error fetching models');
    }
  };

  const fetchAgents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/agents`, {
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem('token')}` 
        }
      });
      setAgents(response.data);
    } catch (error) {
      console.error('Error fetching agents:', error);
      setError('Error fetching agents');
    }
  };


  // Chat Functionality
// Update startNewChat function to handle both types
  const startNewChat = async (entity) => {
    try {
      // const isAgent = entity.entityType === 'agent';
      const isAgent = currentMode === 'agents';
      let response;
      
      if (isAgent) {
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/agents/${entity[0]._id}/chat`,
          {
            title: `Chat with ${entity[0].name}`,
            aiModel: entity[0].aiModel
          },
          {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/chat/new`,
          {
            title: `Chat with ${entity[0].name}`,
            aiModel: entity[0].name
          },
          {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
          }
        );
      }
      
      setChatId(response.data._id);
      setIsConversationStarted(true);

      const initialEntity = {
          name: entity[0].name,
          icon: entity[0].iconPath || `/images/all_icons/${entity[0].icon}`,
          entityType: isAgent ? 'agent' : 'model',
          ...(isAgent && { agentId: entity[0]._id })
      };
      setParticipatingEntities([initialEntity]);


      if (inputRef.current) {
        inputRef.current.style.height = 'auto';
      }
    } catch (error) {
      console.error('Error starting chat:', error);
      setError('Failed to start chat');
    }
  };

  const getModelEndpoint = (model) => {
    switch (model.toLowerCase()) {
      case 'o1':
        return 'o1';
      case 'o1 mini':
        return 'o1mini';
      case 'gpt 4o mini':
        return 'gpt4omini';
      case 'gpt 4':
        return 'gpt4';
      case 'claude 3.5':
        return 'claude3.5';
      case 'claude sonnet':
        return 'claudesonnet';
      case 'claude opus':
        return 'claude-opus';
      case 'claude haiku':
        return 'claude-haiku';
      case 'mistral ai large':
        return 'mistral-large';
      case 'mistral ai medium':
        return 'mistral-medium';
      case 'mistral ai small':
        return 'mistral-small';
      case 'codestral':
        return 'codestral';
      case 'llama 3.1':
        return 'llama3.1';
      case 'gemini 1.5':
        return 'gemini1.5';
      case 'cohere':
        return 'cohere';
      // case 'perplexity':
      //   return 'perplexity';
      case 'stable diffusion':
      case 'stablediffusion':
        return 'stablediffusion';
      case 'dall-e 3':
        return 'dall-e3';
      case 'dall-e3':
        return 'dall-e3';
      default:
        return model.toLowerCase().replace(' ', '');
    }
  };

// DROP ZONE IMPORT

  // Add these handlers before the return statement
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    const files = Array.from(e.dataTransfer.files);
    if (files.length === 0) return;

    // Check file sizes
    const oversizedFiles = files.filter(file => file.size > 5 * 1024 * 1024);
    if (oversizedFiles.length > 0) {
      Swal.fire({
        icon: "error",
        title: "File too large",
        text: "Maximum file size is 5MB",
        timer: 2000,
        width: 300,
        showConfirmButton: false
      });
      return;
    }
    
    setIsUploading(true);
    
    try {
      const newFileContents = [];
      const newFileInfos = [];
      
      for (const file of files) {
        const extension = file.name.split('.').pop().toLowerCase();
        const isImage = file.type.startsWith('image/');
        
        let fileContent;
        
        if (!isImage) {
          // Handle non-image files
          const fileContent = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
          
          newFileContents.push(fileContent);
          newFileInfos.push({
            name: file.name,
            type: file.name.split('.').pop().toLowerCase(),
            content: fileContent,
            isImage: false,
            mimeType: file.type
          });
        } else {
          // For images, only store the base64 data
          const base64Content = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
          
          newFileContents.push(base64Content);
          newFileInfos.push({
            name: file.name,
            type: file.name.split('.').pop().toLowerCase(),
            content: base64Content,
            isImage: true,
            mimeType: file.type
          });
        }
      }
      
      setFileContent(newFileContents.join('\n\n'));
      setUploadedFiles(prev => [...prev, ...newFileInfos]);

      // If there are existing messages, process files immediately
      // if (isFirstMessageHere && chatId) {
      //   await processFiles(newFileInfos);
      // }
      // Only process non-image files
      const nonImageFiles = newFileInfos.filter(file => !file.isImage);
      if (isFirstMessageHere && chatId && nonImageFiles.length > 0) {
        await processFiles(nonImageFiles);
      }

    } catch (error) {
      console.error('Error processing dropped files:', error);
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Oops...",
        text: "Error processing dropped files!",
        showConfirmButton: false,
        timer: 1500,
        width: 300,
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileUpload = async (event) => {
      const files = event.target.files;
      if (files.length === 0) return;

      // Check file sizes
      const oversizedFiles = Array.from(files).filter(file => file.size > 5 * 1024 * 1024);
      if (oversizedFiles.length > 0) {
        Swal.fire({
          icon: "error", 
          title: "File too large",
          text: "Maximum file size is 5MB",
          timer: 2000,
          showConfirmButton: false
        });
        event.target.value = '';
        return;
      }
      
      setIsUploading(true);

      try {
        const newFileContents = [];
        const newFileInfos = [];
        
        for (const file of files) {
          const extension = file.name.split('.').pop().toLowerCase();
          const isImage = file.type.startsWith('image/');
          
          let fileContent;
          
          if (!isImage) {
              // Handle non-image files as before
              const fileContent = await new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onload = (e) => resolve(reader.result);
                  reader.onerror = reject;
                  reader.readAsDataURL(file);
              });

              newFileContents.push(fileContent);
              newFileInfos.push({
                  name: file.name,
                  type: file.name.split('.').pop().toLowerCase(),
                  content: fileContent,
                  isImage: false,
                  mimeType: file.type
              });
          } else {
              // For images, only store the base64 data
              const base64Content = await new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onload = () => resolve(reader.result);
                  reader.onerror = reject;
                  reader.readAsDataURL(file);
              });

              newFileContents.push(base64Content);
              newFileInfos.push({
                  name: file.name,
                  type: file.name.split('.').pop().toLowerCase(),
                  content: base64Content,
                  isImage: true,
                  mimeType: file.type
              });
          }
      }
        
        setFileContent(newFileContents.join('\n\n'));
        setUploadedFiles(prev => [...prev, ...newFileInfos]);

        // If there are existing messages, process files immediately
        // if (isFirstMessageHere && chatId) {
        //   await processFiles(newFileInfos);
        // }

        // Only process non-image files
        const nonImageFiles = newFileInfos.filter(file => !file.isImage);
        if (isFirstMessageHere && chatId && nonImageFiles.length > 0) {
            await processFiles(nonImageFiles);
        }

      } catch (error) {
        console.error('Error processing files:', error);
        Swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Oops...",
          text: "Error processing dropped files!",
          showConfirmButton: false,
          timer: 1500,
          width: 300,
          // footer: '<a href="#">Why do I have this issue?</a>'
        });
      } finally {
        setIsUploading(false);
        event.target.value = '';
      }
  };


  const processFiles = async (files) => {
    setIsUploading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/chat/${chatId}/process-files`,
        { files },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
    } catch (error) {
      console.error('Error processing files:', error);
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Error processing files",
        showConfirmButton: false,
        timer: 1500,
        width: 300,
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileRemove = (indexToRemove) => {
    setUploadedFiles(prev => prev.filter((_, index) => index !== indexToRemove));
    
    // Update the fileContent state by removing the corresponding file content
    setFileContent(prev => {
      const contentArray = prev.split('\n\n');
      const newContent = contentArray
        .filter((_, index) => index !== indexToRemove)
        .join('\n\n');
      return newContent || '';
    });
  };

  const fetchMessages = async () => {
    if (!chatId) return;

    try {
      // First get the chat info to determine if it's an agent chat
      const chatResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/chat/${chatId}`,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
      );

      if (!chatResponse.data) {
        throw new Error('Chat not found');
      }

      // Determine if this is an agent chat by checking for agentId
      const isAgentChat = !!chatResponse.data.agentId;
      let messagesResponse;

      if (isAgentChat) {
        // Fetch agent details
        const agentResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/agents/details/${chatResponse.data.agentId}`,
          { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
        );

        // Fetch messages for agent chat
        messagesResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/agents/${chatResponse.data.agentId}/chat/${chatId}/messages`,
          { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
        );

        // Process messages to ensure they have the correct agent info
        const processedMessages = messagesResponse.data.map(message => {
          if (message.role === 'assistant') {
            return {
              ...message,
              agentId: message.agentId || message._id,
              agentName: message.agentName || message.aiModel,
              entityType: 'agent',
              icon: `/images/all_icons/${agentResponse.data.icon}`,
            };
          }
          return message;
        });
        

        // Filter out duplicate user messages
        const seenUserMessages = new Set();
        const uniqueMessages = processedMessages.filter(message => {
          if (message.role === 'user' && message.content.includes('@')) {
            if (seenUserMessages.has(message.content)) {
              return false;
            }
            seenUserMessages.add(message.content);
          }
          return true;
        });

        setMessages(uniqueMessages);

      } else {
        // Fetch messages for model chat
        messagesResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/chat/${chatId}/messages`,
          { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
        );

        // Process model messages with correct metadata
        const processedMessages = messagesResponse.data.map(message => {
          if (message.role === 'assistant') {
            const modelMapping = {
              'o1': { icon: 'gpt.png', displayName: 'o1' },
              'o1mini': { icon: 'gpt.png', displayName: 'o1 Mini' },
              'gpt4': { icon: 'gpt.png', displayName: 'GPT 4' },
              'gpt4o': { icon: 'gpt.png', displayName: 'GPT 4o' },
              'gpt4omini': { icon: 'gpt.png', displayName: 'GPT 4o Mini' },
              'claude3.5': { icon: 'claude.png', displayName: 'Claude 3.5' },
              'claudesonnet': { icon: 'claude.png', displayName: 'Claude Sonnet' },
              'claude-opus': { icon: 'claude.png', displayName: 'Claude Opus' },
              'claude-haiku': { icon: 'claude.png', displayName: 'Claude Haiku' },
              'mistral-large': { icon: 'mistral.png', displayName: 'Mistral AI Large' },
              'mistral-medium': { icon: 'mistral.png', displayName: 'Mistral AI Medium' },
              'mistral-small': { icon: 'mistral.png', displayName: 'Mistral AI Small' },
              'codestral': { icon: 'mistral.png', displayName: 'Codestral' },
              'llama3.1': { icon: 'llama.png', displayName: 'Llama 3.1' },
              'gemini1.5': { icon: 'gemini.png', displayName: 'Gemini 1.5' },
              'cohere': { icon: 'cohere.png', displayName: 'Cohere' },
              'perplexity': { icon: 'perplexity.png', displayName: 'Perplexity' },
              'stablediffusion': { icon: 'stablediffusion.png', displayName: 'Stable Diffusion' },
              'dall-e3': { icon: 'gpt.png', displayName: 'DALL-E 3' }
            };

            const modelInfo = modelMapping[message.aiModel.toLowerCase()] || 
              { icon: 'default.png', displayName: message.aiModel };

            return {
              ...message,
              entityType: 'model',
              icon: `/images/all_icons/${modelInfo.icon}`,
              displayName: message.agentName || modelInfo.displayName
            };
          }
          return message;
        });

        // Filter out duplicate user messages
        const seenUserMessages = new Set();
        const uniqueMessages = processedMessages.filter(message => {
          if (message.role === 'user' && message.content.includes('@')) {
            if (seenUserMessages.has(message.content)) {
              return false;
            }
            seenUserMessages.add(message.content);
          }
          return true;
        });

        setMessages(uniqueMessages);
      }

      // Handle image loading states
      const newImageLoaded = {};
      messagesResponse.data.forEach(msg => {
        if (msg.isImage) {
          newImageLoaded[msg._id] = false;
          if (typeof msg.content === 'string' && msg.content.startsWith('data:image/')) {
            const img = new Image();
            img.onload = () => {
              setImageLoaded(prev => ({ ...prev, [msg._id]: true }));
            };
            img.src = msg.content;
          }
        }
      });
      setImageLoaded(newImageLoaded);

      // Scroll to bottom after messages are loaded
      if (isInitialLoad) {
        setTimeout(() => {
          scrollToBottom();
          setIsInitialLoad(false);
        }, 100);
      }
      // setTimeout(() => {
      //   scrollToBottom();
      // }, 100);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError('Error fetching messages. Please try again.');
    }
  };

  const extractUrls = (text) => {
    const urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
    const matches = text.match(urlRegex) || [];
    let styledText = text;
    
    matches.forEach(url => {
      styledText = styledText.replace(url, `<span style="color: white; text-decoration: underline;">${url}</span>`);
    });

    return {
      urls: matches,
    };
  };

  const sendMessage = async () => {
    if (!input.trim() || selectedEntities.length === 0 || !chatId) return;

    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
    }

    // Extract URLs from input
    const urls = extractUrls(input);

    // Handle non-image files only
    const nonImageFiles = uploadedFiles.filter(file => !file.isImage);
    if (nonImageFiles.length > 0) {
        if (!isFirstMessageHere) {
            await processFiles(nonImageFiles);
        }
      // For existing conversations, files are already processed upon upload
    }


    let cleanedMessage = input;
    selectedEntities.forEach(entity => {
      const entityRegex = new RegExp(`@${entity.name}\\s*`, 'g');
      cleanedMessage = cleanedMessage.replace(entityRegex, '');
    });
    cleanedMessage = cleanedMessage.trim();

    // Get any image files that were uploaded
    const imageFiles = uploadedFiles.filter(file => file.isImage);
    const fileContents = imageFiles.map(file => file.content);

    const userMessage = {
      content: cleanedMessage,
      role: 'user',
      entities: selectedEntities.map(entity => ({
        id: entity._id,
        name: entity.name,
        type: entity.entityType
      }))
    };

    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setUploadedFiles([]);
    setError(null);

    try {
      let context = messages.length > 4 ? [...messages.slice(-4)] : messages;
      context = context.filter(message => 
        !(typeof message.content === 'string' && message.content.startsWith('data:image/'))
      );

      // Create placeholders with complete metadata
      const placeholders = selectedEntities.map(entity => {
        const isAgent = entity.entityType === 'agent';
        return {
          id: `temp-${Date.now()}-${entity._id}`,
          role: 'assistant',
          content: '',
          aiModel: isAgent ? entity.aiModel : entity.name,
          entityType: entity.entityType,
          icon: `/images/all_icons/${entity.icon}`,
          displayName: entity.name,
          ...(isAgent && { 
            agentId: entity._id, 
            agentName: entity.name 
          })
        };
      });

      setMessages(prev => [...prev, ...placeholders]);

      await Promise.all(selectedEntities.map(async (entity, entityIndex) => {
        const isAgent = entity.entityType === 'agent';
        const messageId = placeholders[entityIndex].id;
        
        const endpoint = isAgent
          ? `${process.env.REACT_APP_BACKEND}/api/agents/${entity._id}/chat/${chatId}/message`
          : `${process.env.REACT_APP_BACKEND}/api/chat/${chatId}/${getModelEndpoint(entity.name.toLowerCase())}`;

        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            message: input,
            context: context,
            fileContent: fileContents,
            urls: urls,
            // isSearchEnabled: isSearchEnabled,
            ...(isAgent && { agentId: entity._id, agentName: entity.name })
          })
        });

        setParticipatingEntities(prev => {
            // Helper function to normalize model names
            const normalizeModelName = (name) => {
                const nameMap = {
                  'o1': 'o1',
                  'o1mini': 'o1 Mini',
                  'gpt4': 'GPT 4',
                  'gpt4o': 'GPT 4o',
                  'gpt4omini': 'GPT 4o Mini',
                  'claude3.5': 'Claude 3.5',
                  'claudesonnet': 'Claude Sonnet',
                  'claude-opus': 'Claude Opus',
                  'claude-haiku': 'Claude Haiku',
                  'mistral-large': 'Mistral AI Large',
                  'mistral-medium': 'Mistral AI Medium',
                  'mistral-small': 'Mistral AI Small',
                  'codestral': 'Codestral',
                  'llama3.1': 'Llama 3.1',
                  'gemini1.5': 'Gemini 1.5',
                  'cohere': 'Cohere',
                  'perplexity': 'Perplexity',
                  'stablediffusion': 'Stable Diffusion',
                  'dall-e3': 'DALL-E 3'
                };
                return nameMap[name.toLowerCase().replace(/\s+/g, '')] || name;
            };

            // Filter out duplicates using normalized names
            const mergedEntities = [...prev];
            
            selectedEntities.forEach(entity => {
                // Check if entity already exists using normalized names
                const exists = mergedEntities.some(e => 
                    (e.entityType === 'model' && 
                     normalizeModelName(e.name).toLowerCase() === normalizeModelName(entity.name).toLowerCase()) ||
                    (e.entityType === 'agent' && e.agentId === entity._id)
                );
                
                if (!exists) {
                    const newEntity = {
                        name: normalizeModelName(entity.name),
                        icon: entity.iconPath || `/images/all_icons/${entity.icon}`,
                        entityType: entity.entityType,
                        ...(entity.entityType === 'agent' && { agentId: entity._id })
                    };
                    mergedEntities.push(newEntity);
                }
            });
            
            return mergedEntities;
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let sources = null;

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value);
          const lines = chunk.split('\n').filter(line => line.trim() !== '');

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const data = line.slice(5);
              //if (data === '[DONE]') continue;
              if (data === '[DONE]') {
                  setProgressState(null);  // Clear progress state at completion
                  continue;
              }
              try {

                // const parsed = JSON.parse(data);
                const parsed = JSON.parse(data);

                // Handle new title update
                if (parsed.newTitle) {
                    // Update title state
                    setTitle(parsed.newTitle);
                    
                    // Add shimmer effect
                    const titleElements = document.querySelectorAll('.chat-title');
                    titleElements.forEach(element => {
                        element.classList.add('title-update');
                        setTimeout(() => {
                            element.classList.remove('title-update');
                        }, 2000);
                    });

                    // Trigger a chat refresh event
                    const refreshEvent = new CustomEvent('chatTitleUpdated', {
                        detail: { chatId, newTitle: parsed.newTitle }
                    });
                    window.dispatchEvent(refreshEvent);
                }
                
                if (parsed.progressState !== undefined && parsed.progressState !== null) {
                  setProgressState(parsed.progressState);
                }

                if (parsed.content) {
                  setMessages(prev => {
                    return prev.map(msg => {
                      if (msg.id === messageId) {
                        return {
                          ...msg,
                          content: (msg.content || '') + parsed.content
                        };
                      }
                      return msg;
                    });
                  });
                  // setTimeout(() => scrollToBottom(), 0);
                } else if (parsed.sources) {
                  sources = parsed.sources;
                }
              } catch (e) {
                // Ignore parsing errors
              }
            }
          }
        }
      }));

    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message');
      Swal.fire({
        icon: "error",
        title: "Oops ! Calk AI was OOO 🏝️",
        text: "Either the model you requested is lazy or there was another issue processing your request!",
        footer: 'If this issue persists please contact us through our help center at <a href="mailto:help@calk-ai.com">help@calk-ai.com</a>.',
        showConfirmButton: true,
        confirmButtonText: 'Regenerate message',
      }).then((result) => {
        if (result.isConfirmed) {
          sendMessage();
        }
      });
    } finally {
      // We still need fetchMessages() to sync with backend, but our UI won't flicker
      // because we already have the correct metadata
      fetchMessages();
      if (inputRef.current) {
        inputRef.current.style.height = 'auto';
      }
    }
  };

  // Input Handling
  const handleInputChange = (e) => {
    const newInput = e.target.value;
    setInput(newInput);
    
    adjustTextareaHeight(e.target);
    
    const lastAtIndex = newInput.lastIndexOf('@');
    if (lastAtIndex > lastMentionIndex) {
      setShowMentionDropdown(true);
      setLastMentionIndex(lastAtIndex);
    } else if (lastAtIndex === -1) {
      setShowMentionDropdown(false);
      setLastMentionIndex(-1);
    }
    
    setMentionIndex(-1);
  };

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };


  const handleKeyDown = (e) => {
      // Determine entities based on conversation type rather than just currentMode
    const entities = currentMode === 'models' ? models : agents;
    
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        return; // Allow new line with Shift+Enter
      }
      
      e.preventDefault();

      if (isUploading) return; // No Sending message while uploading
      
      if (showMentionDropdown && mentionIndex !== -1) {
        // Handle dropdown selection
        handleEntitySelect(entities[mentionIndex]);
        return;
      }
      sendMessage();
      // if (input.trim() && selectedEntity) {
      //   sendMessage();
      // }

      if (!isConversationStarted) {
         startNewChat(selectedEntities);
         sendMessage();
      }

    } else if (showMentionDropdown) {
      switch (e.key) {
        case 'ArrowUp':
          e.preventDefault();
          setMentionIndex(prev => 
            prev <= 0 ? entities.length - 1 : prev - 1
          );
          break;
        case 'ArrowDown':
          e.preventDefault();
          setMentionIndex(prev => 
            prev >= entities.length - 1 ? 0 : prev + 1
          );
          break;
        case 'Tab':
          e.preventDefault();
          if (mentionIndex !== -1) {
            handleEntitySelect(entities[mentionIndex]);
          }
          break;
        default:
          break;
      }
    }
  };

  const handleEntitySelect = async (entity) => {
    // Get the last @ index and everything after it
    const lastAtIndex = input.lastIndexOf('@');
    const textBeforeAt = input.slice(0, lastAtIndex).trim();
    const textAfterLastAt = input.slice(lastAtIndex);
    
    // If input is empty or there's no text before @, clear entities and start fresh
    if (!textBeforeAt || !input.trim()) {
      const formattedEntity = {
        ...entity,
        // entityType: currentMode === 'agents' ? 'agent' : 'model',
        entityType: entity.hasOwnProperty('mood') ? 'agent' : 'model',
        iconPath: currentMode === 'agents' 
          ? `/images/all_icons/${entity.icon}`
          : `/images/all_icons/${entity.icon}`
      };
      setSelectedEntities([]);  // Clear all entities first
      setSelectedEntities([formattedEntity]);  // Then set the new one
      setInput(`@${entity.name} `);
      // console.log(formattedEntity);
      setShowMentionDropdown(false);
      if (inputRef.current) {
        inputRef.current.focus();
        setTimeout(() => {
          inputRef.current.selectionStart = inputRef.current.value.length;
          inputRef.current.selectionEnd = inputRef.current.value.length;
        }, 0);
      }
      return;
    }

    // If we're starting a new @ mention and already have 2 entities,
    // clear everything and start fresh
    if (selectedEntities.length >= 4 && textAfterLastAt === '@') {
      setSelectedEntities([entity]);
      setInput(`@${entity.name} `);
    } else {
      // Check if entity is already selected
      const isAlreadySelected = selectedEntities.some(e => e._id === entity._id);
      
      // If already selected, remove it
      if (isAlreadySelected) {
        setSelectedEntities(prev => prev.filter(e => e._id !== entity._id));
        const mentionText = `@${entity.name}`;
        setInput(input.replace(mentionText, '').trim());
      } else {
        // Only allow up to 2 entities
        if (selectedEntities.length >= 4) {
          const oldestEntity = selectedEntities[0];
          setSelectedEntities([selectedEntities[1], entity]);
          const oldMention = `@${oldestEntity.name}`;
          setInput(input.replace(oldMention, '').trim() + ` @${entity.name} `);
        } else {
          setSelectedEntities(prev => [...prev, entity]);
          const atMention = `@${entity.name}`;
          const atIndex = input.lastIndexOf('@');
          if (atIndex !== -1) {
            setInput(input.slice(0, atIndex) + atMention + ' ');
          } else {
            setInput((prev) => prev + (prev.endsWith(' ') ? '' : ' ') + atMention + ' ');
          }
        }
      }
    }

    setShowMentionDropdown(false);
    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current.selectionStart = inputRef.current.value.length;
        inputRef.current.selectionEnd = inputRef.current.value.length;
      }, 0);
    }
  };

  const handleCardClick = (entity) => {
    // Clear input first
    // setInput('');
    
    // Create the entity with correct type and icon path
    const formattedEntity = {
      ...entity,
      entityType: currentMode === 'agents' ? 'agent' : 'model',
      iconPath: currentMode === 'agents' 
        ? `/images/all_icons/${entity.icon}`
        : `/images/all_icons/${entity.icon}`
    };

    // Set as the only selected entity
    setSelectedEntities([formattedEntity]);
    
    // Update input with mention
    setInput(`@${entity.name} `);

    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
    }

    // Remove the old selectedEntity state since we're using selectedEntities
    setSelectedEntity(formattedEntity);

    if (!isConversationStarted) {
      setInput('');
      if (inputRef.current) {
        inputRef.current.style.height = 'auto';
      }
    }
  };

  // Handle send button click
  const handleSendClick = () => {
    if (input.trim() && selectedEntity && !isLoading) {
      sendMessage();
    }
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
    }
  };

  // Update the mention dropdown rendering
  const getMentionIconBanner = (entity, currentMode) => {
    if (currentMode === 'models') {
      return `/images/all_icons/${entity.icon}`;
    }
    return `/images/all_icons/${entity.icon}`;
  };

  const getEntityNameForMessage = (message) => {
    if (!message || !message.aiModel) return 'Loading';

    if (message.agentId) {
      // If message has a valid agentName, use it
      if (message.agentName && message.agentName !== 'Unknown') {
        return message.agentName;
      }

      const matchedAgent = agents.find(a => a._id === message.agentId);
      if (matchedAgent) {
        return matchedAgent.name;
      }
    }

    // Otherwise, handle as a model name
    const modelMapping = {
      'o1': 'o1',
      'o1mini': 'o1 Mini',
      'gpt4': 'GPT 4',
      'gpt4o': 'GPT 4o',
      'gpt4omini': 'GPT 4o Mini',
      'claude3.5': 'Claude 3.5',
      'claudesonnet': 'Claude Sonnet',
      'claude-opus': 'Claude Opus',
      'claude-haiku': 'Claude Haiku',
      'mistral-large': 'Mistral AI Large',
      'mistral-medium': 'Mistral AI Medium',
      'mistral-small': 'Mistral AI Small',
      'codestral': 'Codestral',
      'llama3.1': 'Llama 3.1',
      'gemini1.5': 'Gemini 1.5',
      'cohere': 'Cohere',
      'perplexity': 'Perplexity',
      'stablediffusion': 'Stable Diffusion',
      'dall-e3': 'DALL-E 3'
    };

    return modelMapping[message.aiModel.toLowerCase()] || message.aiModel;
  };

  const getMessageIcon = (message) => {
    if (!message || !message.aiModel) return '/images/all_icons/gpt.png';

    if (message.agentId) {
      const matchedAgent = agents.find(a => a._id === message.agentId);
      if (matchedAgent) {
        return `/images/all_icons/${matchedAgent.icon}`;
      }
    }

    const modelMapping = [
      { name: ['o1', 'o1'], icon: 'gpt.png' },
      { name: ['o1mini', 'o1 mini'], icon: 'gpt.png' },
      { name: ['gpt4', 'gpt 4', 'gpt-4'], icon: 'gpt.png' },
      { name: ['GPT 4o', 'gpt4o', 'gpt 4o'], icon: 'gpt.png' },
      { name: ['gpt4omini', 'gpt 4o mini'], icon: 'gpt.png' },
      { name: ['claude3.5', 'claude 3.5'], icon: 'claude.png' },
      { name: ['claudesonnet', 'claude sonnet'], icon: 'claude.png' },
      { name: ['claude-opus', 'claude opus'], icon: 'claude.png' },
      { name: ['claude-haiku', 'claude haiku'], icon: 'claude.png' },
      { name: ['Mistral AI Large', 'mistral-large', 'mistral ai large'], icon: 'mistral.png' },
      { name: ['mistral-medium', 'mistral ai medium'], icon: 'mistral.png' },
      { name: ['mistral-small', 'mistral ai small'], icon: 'mistral.png' },
      { name: ['codestral'], icon: 'mistral.png' },
      { name: ['llama3.1', 'llama 3.1'], icon: 'llama.png' },
      { name: ['gemini1.5', 'gemini 1.5'], icon: 'gemini.png' },
      { name: ['cohere', 'cohere'], icon: 'cohere.png' },
      { name: ['perplexity'], icon: 'perplexity.png' },
      { name: ['stablediffusion', 'stable diffusion'], icon: 'stablediffusion.png' },
      { name: ['dall-e3', 'dall-e 3'], icon: 'gpt.png' }
    ];

    const model = modelMapping.find(m => 
      m.name.some(n => n.toLowerCase() === message.aiModel.toLowerCase())
    );
    
    return model ? `/images/all_icons/${model.icon}` : '/images/default.png';
  };

  const fetchChatMessages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chat/${chatId}/messages`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setMessages(response.data);
      // Reset image loaded state for all messages
      const newImageLoaded = {};
      response.data.forEach(msg => {
        if (msg.isImage) {
          newImageLoaded[msg._id] = false;
        }
      });
      setImageLoaded(newImageLoaded);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError('Error fetching messages. Please try again.');
    }
  };

  const fetchAgentMessages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/agents/${selectedEntity._id}/chat/${chatId}/messages`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      const fetchedMessages = response.data.map(msg => {
        if (msg.isImage && typeof msg.content === 'string' && msg.content.startsWith('data:image/')) {
          return { ...msg, isImage: true };
        }
        return msg;
      });

      setMessages(fetchedMessages);

      // Reset image loaded state for all messages
      const newImageLoaded = {};
      fetchedMessages.forEach(msg => {
        if (msg.isImage) {
          newImageLoaded[msg._id] = false;
        }
      });
      setImageLoaded(newImageLoaded);

      // Trigger image loading for image messages
      fetchedMessages.forEach(msg => {
        if (msg.isImage && typeof msg.content === 'string' && msg.content.startsWith('data:image/')) {
          const img = new Image();
          img.onload = () => {
            setImageLoaded(prev => ({ ...prev, [msg._id]: true }));
          };
          img.src = msg.content;
        }
      });
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError('Error fetching messages. Please try again.');
    }
  };

  const fetchChatTitle = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chat/${chatId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setTitle(response.data.title);
    } catch (error) {
      console.error('Error fetching chat title:', error);
      setError('Error fetching chat title. Please try again.');
    }
  };

  const updateChatTitle = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/chat/${chatId}/title`, 
        { title },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setIsEditingTitle(false);
    } catch (error) {
      console.error('Error updating chat title:', error);
      setError('Error updating chat title. Please try again.');
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Oops...",
        text: "Error updating chat title. Please try again.",
        showConfirmButton: false,
        timer: 1500,
        width: 300,
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    }
  };

  const updateAgentChatTitle = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/agents/${selectedEntity._id}/chat/${chatId}/title`, 
        { title },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setIsEditingTitle(false);
    } catch (error) {
      console.error('Error updating chat title:', error);
      setError('Error updating chat title. Please try again.');
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Oops...",
        text: "Error updating chat title. Please try again.",
        showConfirmButton: false,
        timer: 1500,
        width: 300,
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    }
  };

  const handleTitleSave = async () => {
    if (currentMode === 'agents') {
      await updateAgentChatTitle();
    } else {
      await updateChatTitle();
    }
  };

  const handleTitleEdit = () => {
    setIsEditingTitle(true);
    setTimeout(() => titleInputRef.current.focus(), 0);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleTitleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleTitleSave();
    }
  };

  const groupMessages = (messages) => {
    const groupedMessages = [];
    let skipNext = false;

    messages.forEach((message, index) => {
      // Skip if this message was already handled
      if (skipNext) {
        skipNext = false;
        return;
      }

      const nextMessage = messages[index + 1];
      const prevMessage = messages[index - 1];

      if (message.role === 'user') {
        // Handle user messages individually
        groupedMessages.push([message]);
      } else if (message.role === 'assistant') {
        // Check if this is part of a parallel response
        // Add condition to check if there are exactly 2 assistant messages
        const isDoubleResponse = nextMessage && 
            nextMessage.role === 'assistant' && 
            prevMessage?.role === 'user' &&
            (!messages[index + 2] || messages[index + 2].role === 'user');

        if (isDoubleResponse) {
          // Create a group with current and next message
          groupedMessages.push([message, nextMessage]);
          skipNext = true; // Skip the next message since we've used it
        } else if (!skipNext) { // Only add if not marked for skipping
          groupedMessages.push([message]);
        }
      }
    });

    return groupedMessages;
  };

  const handleDeleteChat = async (chatId, e, chatType) => {
    // e.preventDefault();
    // e.stopPropagation();
    try {
      const endpoint = chatType === 'agent'
        ? `${process.env.REACT_APP_BACKEND}/api/agents/${chatId}/chat/${chatId}`
        : `${process.env.REACT_APP_BACKEND}/api/chat/${chatId}`;

      await axios.delete(endpoint, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      
      navigate('/home');

    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  return (
    <div className="home-chat-container">
      <div className={`chat-content ${isConversationStarted ? 'conversation-started' : ''}`}>
        <HomeHeader userFirstName={userFirstName} />

        {isConversationStarted && (
          <>
            <ChatHeader 
              navigate={navigate}
              title={title}
              isEditingTitle={isEditingTitle}
              titleInputRef={titleInputRef}
              handleTitleChange={handleTitleChange}
              handleTitleSave={handleTitleSave}
              handleTitleKeyPress={handleTitleKeyPress}
              handleTitleEdit={handleTitleEdit}
              handleDeleteChat={handleDeleteChat}
              chatId={chatId}
              currentMode={currentMode}
              participatingEntities={participatingEntities} 
            />

            <div 
              className={`chat-area ${isDragging ? 'drag-over' : ''}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              {/* Messages section */}
              {groupMessages(messages).map((messageGroup, groupIndex) => (
                <MessageContainer 
                  key={groupIndex}
                  messageGroup={messageGroup}
                  MessageHeader={MessageHeader}
                  Message={Message}
                  MessageActions={MessageActions}
                  userFirstName={userFirstName}
                  userLastName={userLastName}
                  imageData={imageData}
                  getMessageIcon={getMessageIcon}
                  getEntityNameForMessage={getEntityNameForMessage}
                  handleFeedback={handleFeedback}
                  copyToClipboard={copyToClipboard}
                  progressState={progressState}
                />
              ))}
              <div ref={messagesEndRef} />
            </div>
          </>
        )}

        {/* Input Section */}
        <div className="input-section">

          {selectedEntities.length > 0 && (
            <TalkingToBanner selectedEntities={selectedEntities} />
          )}

          <div 
            className={`input-container ${isDragging ? 'drag-over' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {showMentionDropdown && (
              <MentionDropdown 
                dropdownRef={dropdownRef}
                input={input}
                mentionIndex={mentionIndex}
                selectedItemRef={selectedItemRef}
                handleEntitySelect={handleEntitySelect}
                getAllEntities={getAllEntities}
                models={models}
                agents={agents}
              />
            )}

            {(uploadedFiles.length > 0 || isUploading) && (
              <FilesBanner 
                uploadedFiles={uploadedFiles}
                handleFileRemove={handleFileRemove}
                isUploading={isUploading}
              />
            )}

            <textarea
              ref={inputRef}
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder={`Start a conversation ${selectedEntity ? `with ${selectedEntity.name}` : 'using @'}`}
              rows={1}
              className={`chat-input ${isEnhancing ? 'enhancing' : ''}`}
              disabled={isEnhancing || isUploading}
            />

            <InputButtons 
              fileInputRef={fileInputRef}
              isUploading={isUploading}
              handleFileUpload={handleFileUpload}
              isEnhancing={isEnhancing}
              handleEnhancePrompt={handleEnhancePrompt}
              handleSendClick={handleSendClick}
              input={input}
              selectedEntity={selectedEntity}
            />
          </div>
        </div>

        {/* Entity Tabs - Only shown when conversation hasn't started */}
        {!isConversationStarted && (
          <EntityTabs
            currentMode={currentMode}
            setCurrentMode={setCurrentMode}
            models={models}
            agents={agents}
            handleCardClick={handleCardClick}
            handleCreateAgent={handleCreateAgent}
          />
        )}
      </div>
    </div>
  );
};

export default HomeChat2;