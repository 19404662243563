import React, { useMemo, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Search } from 'lucide-react';

const CategorizedIconDropdown = ({ 
  selectedIcon, 
  onIconSelect, 
  isOpen, 
  onOpenChange, 
  availableIcons 
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  // Group icons by category
  const categorizedIcons = useMemo(() => {
    const categories = [
      { name: 'Smileys', prefix: 'smiley_' },
      { name: 'Nature', prefix: 'animal_' },
      { name: 'Companies', prefix: 'company_' },
      { name: 'People', prefix: 'people_' },
      { name: 'Food', prefix: 'food_' },
      { name: 'Places', prefix: 'places_' },
      { name: 'Objects', prefix: 'object_' },
      { name: 'Flags', prefix: 'flag_' },
      { name: 'Symbols', prefix: 'symbols_' },
      { name: 'Misc', prefix: '' }
    ];

    // Filter icons based on search query
    const filteredIcons = searchQuery
      ? availableIcons.filter(icon => 
          icon.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : availableIcons;

  //   return categories.map(category => ({
  //     name: category.name,
  //     icons: availableIcons.filter(icon => 
  //       category.name === 'Misc' 
  //         ? !categories.slice(0, -1).some(c => icon.startsWith(c.prefix))
  //         : icon.startsWith(category.prefix)
  //     )
  //   })).filter(category => category.icons.length > 0);
  // }, [availableIcons]);

    return categories.map(category => ({
      name: category.name,
      icons: filteredIcons.filter(icon => 
        category.name === 'Misc' 
          ? !categories.slice(0, -1).some(c => icon.startsWith(c.prefix))
          : icon.startsWith(category.prefix)
      )
    })).filter(category => category.icons.length > 0);
  }, [availableIcons, searchQuery]);

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={onOpenChange}>
      <DropdownMenu.Trigger className="icon-dropdown-trigger">
        <img
          src={`/images/all_icons/${selectedIcon}`}
          alt={selectedIcon}
          className="agent-icon"
        />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content 
          className="icon-dropdown-content" 
          side="bottom"
          sideOffset={5}
          align="start"
        >

          <div className="search-container-emoji mb-4 px-2">
            <div className="relative">
              <Search className="search-goggle" />
              <input
                type="text"
                placeholder="Search emojis..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full p-2 pl-8 border rounded-md text-sm"
              />
            </div>
          </div>

          <div className="icons-container">
            {categorizedIcons.map(category => (
              <React.Fragment key={category.name}>
                <div className="category-label">{category.name}</div>
                {category.icons.map((icon) => (
                  <DropdownMenu.Item
                    key={icon}
                    className="icon-item"
                    onSelect={() => onIconSelect(icon)}
                  >
                    <img
                      src={`/images/all_icons/${icon}`}
                      alt={icon}
                      className="icon-preview"
                    />
                  </DropdownMenu.Item>
                ))}
              </React.Fragment>
            ))}
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default CategorizedIconDropdown;