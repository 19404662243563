// FormatMessage.jsx
import React from 'react';
import hljs from 'highlight.js';
import { Button } from '@radix-ui/themes';
import { LuCopy } from "react-icons/lu";
import GraphRenderer from './GraphRenderer';

const CodeBlock = ({ code, language, copyToClipboard }) => {
  const highlightedCode = hljs.getLanguage(language) ? hljs.highlight(code, { language }).value : hljs.highlight(code, { language: 'plaintext' }).value;

  // Check if this is a graph code block
  if (code.includes('const data =') && 
      code.includes('const config =') && 
      code.includes('type:') &&
      /type:\s*['"](?:line|bar|pie|area)['"]/.test(code)) {
    try {
      return <GraphRenderer code={code} />;
    } catch (error) {
      console.error('Error rendering graph:', error);
    }
  }

  return (
    <div className="code-block-wrapper">
      <Button 
        className="copy-code-button"
        onClick={() => copyToClipboard(code)}
      >
        <LuCopy size="15"/>
      </Button>
      <pre className="code-block">
        <code 
          className={`language-${language}`} 
          dangerouslySetInnerHTML={{ __html: highlightedCode }} 
        />
      </pre>
    </div>
  );
};

const Table = ({ headers, rows, alignments }) => {
  return (
    <table className="markdown-table">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th 
              key={`th-${index}`}
              style={{ 
                textAlign: alignments[index] || 'left',
                padding: '8px',
                borderBottom: '2px solid #0D00FF'
              }}
            >
              {FormatInlineElements(header.trim())}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={`tr-${rowIndex}`}>
            {row.map((cell, cellIndex) => (
              <td 
                key={`td-${rowIndex}-${cellIndex}`}
                style={{ 
                  textAlign: alignments[cellIndex] || 'left',
                  padding: '8px',
                  borderBottom: '1px solid #0D00FF'
                }}
              >
                {FormatInlineElements(cell.trim())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const FormatInlineElements = (text) => {
  const parts = text.split(/(\*\*|\*|`|\[.*?\]\(.*?\))/);
  let inBold = false;
  let inItalic = false;
  let inCode = false;
  let codeContent = '';

  return parts.map((part, index) => {
    if (part === '**') {
      inBold = !inBold;
      return null;
    } else if (part === '*') {
      inItalic = !inItalic;
      return null;
    } else if (part === '`') {
      if (inCode) {
        const highlightedCode = hljs.highlightAuto(codeContent).value;
        inCode = false;
        const result = <code key={index} className="inline-code" dangerouslySetInnerHTML={{ __html: highlightedCode }} />;
        codeContent = '';
        return result;
      } else {
        inCode = true;
        return null;
      }
    } else if (part.startsWith('[') && part.endsWith(')')) {
      const [linkText, url] = part.slice(1, -1).split('](');
      return <a key={index} href={url} target="_blank" rel="noopener noreferrer">{linkText}</a>;
    } else if (part.startsWith('[ACTION:google') && part.endsWith(']')) {
      return '';
    } else {
      if (inCode) {
        codeContent += part;
        return null;
      } else if (inBold && inItalic) {
        return <strong key={index}><em>{part}</em></strong>;
      } else if (inBold) {
        return <strong key={index}>{part}</strong>;
      } else if (inItalic) {
        return <em key={index}>{part}</em>;
      } else {
        const urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
        const urls = part.match(urlRegex) || [];
        let lastIndex = 0;
        const elements = [];
        
        for (const url of urls) {
          const index = part.indexOf(url, lastIndex);
          if (index > lastIndex) {
            elements.push(part.slice(lastIndex, index));
          }
          elements.push(
            <a 
              key={`url-${index}`} 
              href={url} 
              style={{color: 'white', textDecoration: 'underline'}} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {url}
            </a>
          );
          lastIndex = index + url.length;
        }
        
        if (lastIndex < part.length) {
          elements.push(part.slice(lastIndex));
        }
        
        return elements;
      }
    }
  }).filter(Boolean);
};

export const FormatMessage = ({ content, copyToClipboard, progressState }) => {
  if (content === '') {
    return (
      <div className="analyzing-container-message">
        <video 
          autoPlay 
          loop 
          muted 
          playsInline
          className="loader-video-message"
        >
          <source src="/loader.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {progressState && progressState !== 'null' && (
          <span className="shimmer-text">
            {progressState}
          </span>
        )}
      </div>
    );
  }

  const lines = content.split('\n');
  let formattedContent = [];
  let inCodeBlock = false;
  let codeBlockContent = [];
  let codeLanguage = '';
  let inList = false;
  let listItems = [];
  let listType = null;
  let listCounter = 0;
  let inTable = false;
  let tableHeaders = [];
  let tableRows = [];
  let tableAlignments = [];

  const finishList = () => {
    if (inList) {
      formattedContent.push(
        React.createElement(listType, { key: `list-${formattedContent.length}` }, listItems)
      );
      inList = false;
      listItems = [];
      listType = null;
      listCounter = 0;
    }
  };

  const finishTable = () => {
    if (inTable && tableHeaders.length > 0) {
      formattedContent.push(
        <Table 
          key={`table-${formattedContent.length}`}
          headers={tableHeaders}
          rows={tableRows}
          alignments={tableAlignments}
        />
      );
      inTable = false;
      tableHeaders = [];
      tableRows = [];
      tableAlignments = [];
    }
  };

  const parseTableRow = (line) => {
    return line.split('|')
      .filter((cell, index, array) => index > 0 && index < array.length - 1)
      .map(cell => cell.trim());
  };

  const parseTableAlignments = (line) => {
    return line.split('|')
      .filter((cell, index, array) => index > 0 && index < array.length - 1)
      .map(cell => {
        cell = cell.trim();
        if (cell.startsWith(':') && cell.endsWith(':')) return 'center';
        if (cell.endsWith(':')) return 'right';
        return 'left';
      });
  };

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];

    if (line.trim().startsWith('```')) {
      finishList();
      if (inCodeBlock) {
        formattedContent.push(
          <CodeBlock 
            key={`code-${i}`}
            code={codeBlockContent.join('\n')}
            language={codeLanguage}
            copyToClipboard={copyToClipboard}
          />
        );
        inCodeBlock = false;
        codeBlockContent = [];
        codeLanguage = '';
      } else {
        inCodeBlock = true;
        codeLanguage = line.trim().slice(3).trim();
      }
      continue;
    }

    if (inCodeBlock) {
      codeBlockContent.push(line);
      continue;
    }

    // Handle table rows
    if (line.includes('|')) {
      const cells = line.split('|').filter(cell => cell.trim().length > 0);
      if (cells.length > 0) {
        if (!inTable) {
          finishList();
          inTable = true;
          tableHeaders = parseTableRow(line);
        } else if (line.includes('-')) {
          tableAlignments = parseTableAlignments(line);
        } else {
          tableRows.push(parseTableRow(line));
        }
        continue;
      }
    } else if (inTable) {
      finishTable();
    }

    // Headers
    const headerMatch = line.match(/^(#{1,6})\s(.+)/);
    if (headerMatch) {
      finishList();
      finishTable();
      const level = headerMatch[1].length;
      formattedContent.push(
        React.createElement(
          `h${level}`, 
          { key: `header-${i}` }, 
          FormatInlineElements(headerMatch[2])
        )
      );
      continue;
    }

    // Lists
    if (line.startsWith('- ') || line.startsWith('• ')) {
      finishTable();
      if (!inList || listType !== 'ul') {
        finishList();
        inList = true;
        listType = 'ul';
      }
      listItems.push(
        <li key={`li-${i}`}>
          {FormatInlineElements(line.slice(2))}
        </li>
      );
      continue;
    }

    const orderedListMatch = line.match(/^\d+\.(\d+)\.\s(.+)/);
    if (orderedListMatch) {
      finishTable();
      if (!inList || listType !== 'ol') {
        finishList();
        inList = true;
        listType = 'ol';
        listCounter = 0;
      }
      listCounter++;
      const [, , content] = orderedListMatch;
      listItems.push(
        <li key={`li-${i}`}>
          <strong>{listCounter}. </strong>
          {FormatInlineElements(content)}
        </li>
      );
      continue;
    }

    // Regular paragraph
    if (line !== '') {
      finishList();
      finishTable();
      formattedContent.push(
        <p key={`p-${i}`}>
          {FormatInlineElements(line)}
        </p>
      );
    }
  }

  // Handle any remaining blocks
  finishList();
  finishTable();
  if (inCodeBlock && codeBlockContent.length > 0) {
    formattedContent.push(
      <CodeBlock 
        key={`code-final`}
        code={codeBlockContent.join('\n')}
        language={codeLanguage}
        copyToClipboard={copyToClipboard}
      />
    );
  }

  return <>{formattedContent}</>;
};

export default FormatMessage;