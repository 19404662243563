import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Card, Button, Flex, Tooltip, IconButton, Switch, AlertDialog } from '@radix-ui/themes';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import 'highlight.js/styles/base16/synth-midnight-terminal-dark.css';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import * as HoverCard from '@radix-ui/react-hover-card';
import { FaWandMagicSparkles } from "react-icons/fa6";
import { MdOutlineAttachFile } from "react-icons/md";
import { FaTrash, FaShareAlt } from "react-icons/fa";
import { PiFilesBold } from "react-icons/pi";
import FileUploadDropdown from '../components/FileUploadDropdown';
import ModelInfoHoverCard from '../components/ModelInfoHoverCard';
import { TextField } from '@radix-ui/react-form';
import hljs from 'highlight.js';
import { useAuth } from '../context/AuthContext';
import Intercom from '@intercom/messenger-js-sdk';
import HighlightedInput from '../components/HighlightedInput';
import { IoIosGlobe } from "react-icons/io";
import * as Tabs from '@radix-ui/react-tabs';
import { Spinner } from "@radix-ui/themes";
import { IoArrowBack } from "react-icons/io5";
import { IoIosSend } from "react-icons/io";
import GraphRenderer from '../components/GraphRenderer';
import { LuCopy } from "react-icons/lu";
import Swal from 'sweetalert2';
import HomeHeader from '../components/HomeHeader';
import '../styles/HomeChat.css';

const DRIVE_CACHE_KEY = 'drive_files_cache';
const ANALYTICS_CACHE_KEY = 'analytics_cache';
const CACHE_DURATION = 30 * 60 * 1000; // 15 minutes


const HomeChat = ({ chatId: initialChatId, initialSelectedEntity }) => {
  const { chatId: routeChatId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // State Management
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [title, setTitle] = useState('New Chat');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [models, setModels] = useState([]);
  const [agents, setAgents] = useState([]);
  const [currentMode, setCurrentMode] = useState('models');
  const [selectedEntity, setSelectedEntity] = useState(null);
  const entityFromState = location.state?.initialSelectedEntity;
  const [isConversationStarted, setIsConversationStarted] = useState(false);
  const [showMentionDropdown, setShowMentionDropdown] = useState(false);
  const [mentionIndex, setMentionIndex] = useState(-1);
  const [lastMentionIndex, setLastMentionIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [imageData, setImageData] = useState('');
  const [imageLoaded, setImageLoaded] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const [fileContent, setFileContent] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isEnhancing, setIsEnhancing] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [connectedIntegrations, setConnectedIntegrations] = useState([]);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [isFirstMessageHere, setIsFirstMessageHere] = useState(false);
  const [progressState, setProgressState] = useState(null);

  // Refs
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const titleInputRef = useRef(null);
  const { userFirstName, userLastName, userEmail, companyId } = useAuth();
  const dropdownRef = useRef(null);
  const selectedItemRef = useRef(null);


  Intercom ({
    app_id: 'vilfkctg',
    // user_id: user.id,
    name: userFirstName,
    email: userEmail,
    companyId: companyId
  });

  useEffect(() => {
    const prefetchData = async () => {
      try {
        // Check if we have valid cache for Drive files
        const driveCached = localStorage.getItem(DRIVE_CACHE_KEY);
        if (!driveCached || Date.now() - JSON.parse(driveCached).timestamp > CACHE_DURATION) {
          const driveResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/google/files`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
          );
          localStorage.setItem(DRIVE_CACHE_KEY, JSON.stringify({
            data: driveResponse.data,
            timestamp: Date.now()
          }));
        }

        // Check if we have valid cache for Analytics
        const analyticsCached = localStorage.getItem(ANALYTICS_CACHE_KEY);
        if (!analyticsCached || Date.now() - JSON.parse(analyticsCached).timestamp > CACHE_DURATION) {
          const analyticsResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/analytics/user-stats`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
          );
          localStorage.setItem(ANALYTICS_CACHE_KEY, JSON.stringify({
            data: analyticsResponse.data,
            timestamp: Date.now()
          }));
        }
      } catch (error) {
        console.error('Error pre-fetching data:', error);
      }
    };

    // Only pre-fetch if the user is logged in (we have a token)
    if (localStorage.getItem('token')) {
      prefetchData();
    }
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    const refreshInterval = setInterval(async () => {
      try {
        // Refresh Drive cache
        const driveResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/google/files`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        localStorage.setItem(DRIVE_CACHE_KEY, JSON.stringify({
          data: driveResponse.data,
          timestamp: Date.now()
        }));

        // Refresh Analytics cache
        const analyticsResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/analytics/user-stats`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        localStorage.setItem(ANALYTICS_CACHE_KEY, JSON.stringify({
          data: analyticsResponse.data,
          timestamp: Date.now()
        }));
      } catch (error) {
        console.error('Error refreshing cache:', error);
      }
    }, CACHE_DURATION); // Refresh every 15 minutes

    return () => clearInterval(refreshInterval);
  }, []);

  // Initial Setup
  useEffect(() => {
    fetchModels();
    fetchAgents();
    setIsEnhancing(false);
  }, []);

  useEffect(() => {
    setIsFirstMessageHere(messages.length > 0);
  }, [messages]);

  useEffect(() => {
    if (entityFromState) {
      setSelectedEntity(entityFromState);
      setSelectedEntities([entityFromState]);
    }
  }, [entityFromState]);

  useEffect(() => {
    const initializeExistingChat = async () => {
      if (!routeChatId) return;

      try {
        // Get basic chat info first
        const chatResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/chat/${routeChatId}`,
          { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
        );

        if (!chatResponse.data) {
          throw new Error('Chat not found');
        }

        setTitle(chatResponse.data.title);
        setChatId(routeChatId);
        setIsConversationStarted(true);

        // Determine if this is an agent chat by checking for agentId
        const isAgentChat = !!chatResponse.data.agentId;

        if (isAgentChat) {
          // Fetch agent details
          const agentResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/agents/details/${chatResponse.data.agentId}`,
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
          );

          const matchingAgent = agents.find(a => a._id === chatResponse.data.agentId);
          if (matchingAgent) {
            const agentEntity = {
              ...agentResponse.data,
              type: 'agent',
              entityType: 'agent',
              icon: matchingAgent.icon,
              iconPath: `/images/all_icons/${matchingAgent.icon}`
            };
            setSelectedEntity(agentEntity);
            setSelectedEntities([agentEntity]);
          }

          // Fetch messages for agent chat
          const messagesResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/agents/${chatResponse.data.agentId}/chat/${routeChatId}/messages`,
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
          );

          // Process messages to ensure they have the correct agent info
          const processedMessages = messagesResponse.data.map(message => {
            if (message.role === 'assistant') {
              return {
                ...message,
                agentId: agentResponse.data._id,
                agentName: agentResponse.data.name
              };
            }
            return message;
          });
          // Filter out duplicate user messages
          const seenUserMessages = new Set();
          const uniqueMessages = processedMessages.filter(message => {
            if (message.role === 'user' && message.content.includes('@')) {
              if (seenUserMessages.has(message.content)) {
                return false;
              }
              seenUserMessages.add(message.content);
            }
            return true;
          });

          setMessages(uniqueMessages);

          // setMessages(processedMessages);
          // Scroll to bottom after messages are loaded
          if (isInitialLoad) {
            setTimeout(() => {
              scrollToBottom();
              setIsInitialLoad(false);
            }, 100);
          }
        } else {
          // Handle model chat
          const matchingModel = models.find(m => m.name === chatResponse.data.aiModel);
          if (matchingModel) {
            const modelEntity = {
              ...matchingModel,
              type: 'model',
              icon: `/images/all_icons/${matchingModel.icon}`
            };
            setSelectedEntity(matchingModel);
            setSelectedEntities([matchingModel]);
          }

          // Fetch messages for model chat
          const messagesResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/chat/${routeChatId}/messages`,
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
          );

          // Filter out duplicate user messages
          const seenUserMessages = new Set();
          const uniqueMessages = messagesResponse.data.filter(message => {
            if (message.role === 'user' && message.content.includes('@')) {
              if (seenUserMessages.has(message.content)) {
                return false;
              }
              seenUserMessages.add(message.content);
            }
            return true;
          });

          setMessages(uniqueMessages);
          // setMessages(messagesResponse.data);
        }

        // Scroll to bottom after messages are loaded
        if (isInitialLoad) {
          setTimeout(() => {
            scrollToBottom();
            setIsInitialLoad(false);
          }, 100);
        }

      } catch (error) {
        console.error('Error initializing chat:', error);
        setError('Failed to load chat');
        navigate('/home');
      }
    };

    initializeExistingChat();

  }, [routeChatId, location.pathname, models, navigate]);

  // Modify your useEffect to set initial selection
  useEffect(() => {
    if (showMentionDropdown) {
      setMentionIndex(0); // Set first item as selected by default
    }
  }, [showMentionDropdown]);

  // Add this useEffect to handle scrolling
  useEffect(() => {
    if (selectedItemRef.current && dropdownRef.current) {
      const dropdownContainer = dropdownRef.current;
      const selectedItem = selectedItemRef.current;
      
      const containerHeight = dropdownContainer.offsetHeight;
      const itemTop = selectedItem.offsetTop;
      const itemHeight = selectedItem.offsetHeight;
      
      // Check if selected item is not fully visible
      if (itemTop < dropdownContainer.scrollTop || 
          itemTop + itemHeight > dropdownContainer.scrollTop + containerHeight) {
        // Center the item in the dropdown
        dropdownContainer.scrollTop = itemTop - (containerHeight / 2) + (itemHeight / 2);
      }
    }
  }, [mentionIndex]);

  useEffect(() => {
    setIsInitialLoad(true);
  }, [routeChatId]);

  useEffect(() => {
    scrollToBottom();
  }, [input]);

  // Syntax highlighting effect
  useEffect(() => {
    document.querySelectorAll('pre code').forEach((block) => {
      hljs.highlightElement(block);
    });
  }, [messages]);

  useEffect(() => {
    if (isConversationStarted && selectedEntity) {
      // If the selected entity has an agentId property, it's an agent
      setCurrentMode(selectedEntity.agentId ? 'agents' : 'models');
    }
  }, [isConversationStarted, selectedEntity]);

  useEffect(() => {
    const fetchConnectedIntegrations = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/integrations/connected`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setConnectedIntegrations(response.data.filter(integration => 
          integration !== 'google-calendar' && integration !== 'gmail'
        ));
      } catch (error) {
        console.error('Error fetching connected integrations:', error);
      }
    };

    fetchConnectedIntegrations();
  }, []);

  const handleCreateAgent = () => {
    navigate('/agents/create');
  };

  const getAllEntities = (models, agents) => {
    return [
      ...models.map(model => ({
        ...model,
        entityType: 'model',
        iconPath: `/images/all_icons/${model.icon}`
      })),
      ...agents.map(agent => ({
        ...agent,
        entityType: 'agent',
        iconPath: `/images/all_icons/${agent.icon}`
      }))
    ];
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // console.log('Text copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const handleFeedback = async (messageId, isPositive) => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/chat/${chatId}/feedback`, 
        { messageId, isPositive },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      // Update local state to reflect the feedback
      setMessages(prevMessages => prevMessages.map(msg => 
        msg._id === messageId ? { ...msg, feedback: isPositive ? 'positive' : 'negative' } : msg
      ));
    } catch (error) {
      console.error('Error sending feedback:', error);
      setError('Error sending feedback. Please try again.');
    }
  };

  const handleEnhancePrompt = async () => {
    setIsEnhancing(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/chat/enhance-prompt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ userInput: input })
      });
      const data = await response.json();
      setInput(data.generatedPrompt);
      // Wait for next tick to ensure the textarea has updated with new input
      setTimeout(() => {
        if (inputRef.current) {
          adjustTextareaHeight(inputRef.current);
        }
      }, 0);
    } catch (error) {
      console.error('Error enhancing prompt:', error);
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Oops...",
        text: "Error enhancing prompt!",
        showConfirmButton: false,
        timer: 2000,
        width: 300,
      });
    } finally {
      setIsEnhancing(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Fetch Functions
  const fetchModels = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chatmodels`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const filteredModels = response.data.filter(model => model.name !== 'Multi Modal');
      setModels(filteredModels);
      // setModels(response.data);
    } catch (error) {
      console.error('Error fetching chat models:', error);
      setError('Error fetching models');
    }
  };

  const fetchAgents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/agents`, {
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem('token')}` 
        }
      });
      setAgents(response.data);
    } catch (error) {
      console.error('Error fetching agents:', error);
      setError('Error fetching agents');
    }
  };

  const formatMessage = (message) => {
    if (message.content === '') {
      return (
        <div className="analyzing-container-message">
          <video 
            autoPlay 
            loop 
            muted 
            playsInline
            className="loader-video-message"
          >
            <source src="/loader.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {progressState && progressState !== 'null' && (
            <span className="shimmer-text">
              {progressState}
            </span>
          )}
        </div>
      );
    }

    if (typeof message.content === 'string' && message.content.startsWith('data:image/')) {
      return (
        <div className="image-message">
          <img 
            src={message.content}
            alt="Generated image"
            className="chat-image"
            style={{ width: '100%', maxWidth: '512px', height: 'auto' }}
            onLoad={() => setImageLoaded(prev => ({ ...prev, [message._id]: true }))}
            onError={(e) => {
              console.error('Image failed to load:', e);
              e.target.style.display = 'none';
              e.target.nextSibling.style.display = 'block';
            }}
          />
          <div className="base64-fallback" style={{display: 'none'}}>
            <p>Unable to render image. Base64 string preview:</p>
            <textarea 
              readOnly 
              value={`${message.content.substring(0, 100)}...`}
              onClick={(e) => e.target.select()}
            />
            <button onClick={() => copyToClipboard(message.content)}>
              Copy Full Base64
            </button>
          </div>
        </div>
      );
    }//

    const content = typeof message.content === 'string' ? message.content : String(message.content);
    const lines = content.split('\n');
    let formattedContent = [];
    let inCodeBlock = false;
    let codeBlockContent = [];
    let codeLanguage = '';
    let inList = false;
    let listItems = [];
    let listType = null;
    let listCounter = 0;
    // Add table state variables
    let inTable = false;
    let tableHeaders = [];
    let tableRows = [];
    let tableAlignments = [];

    const finishList = () => {
      if (inList) {
        formattedContent.push(
          React.createElement(listType, { key: `list-${formattedContent.length}` }, listItems)
        );
        inList = false;
        listItems = [];
        listType = null;
        listCounter = 0;
      }
    };

    const finishTable = () => {
      if (inTable && tableHeaders.length > 0) {
        formattedContent.push(
          <table key={`table-${formattedContent.length}`} className="markdown-table">
            <thead>
              <tr>
                {tableHeaders.map((header, index) => (
                  <th 
                    key={`th-${index}`}
                    style={{ 
                      textAlign: tableAlignments[index] || 'left',
                      padding: '8px',
                      borderBottom: '2px solid #0D00FF'
                    }}
                  >
                    {formatInlineElements(header.trim())}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableRows.map((row, rowIndex) => (
                <tr key={`tr-${rowIndex}`}>
                  {row.map((cell, cellIndex) => (
                    <td 
                      key={`td-${rowIndex}-${cellIndex}`}
                      style={{ 
                        textAlign: tableAlignments[cellIndex] || 'left',
                        padding: '8px',
                        borderBottom: '1px solid #0D00FF'
                      }}
                    >
                      {formatInlineElements(cell.trim())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
        inTable = false;
        tableHeaders = [];
        tableRows = [];
        tableAlignments = [];
      }
    };//

    const parseTableRow = (line) => {
      return line.split('|')
        .filter((cell, index, array) => index > 0 && index < array.length - 1)
        .map(cell => cell.trim());
    };

    const parseTableAlignments = (line) => {
      return line.split('|')
        .filter((cell, index, array) => index > 0 && index < array.length - 1)
        .map(cell => {
          cell = cell.trim();
          if (cell.startsWith(':') && cell.endsWith(':')) return 'center';
          if (cell.endsWith(':')) return 'right';
          return 'left';
        });
    };

    const processCodeBlock = (codeLines) => {
      const code = codeLines.join('\n');
      
      // Check if this is a graph code block by looking for our specific data+config pattern
      if (code.includes('const data =') && 
          code.includes('const config =') && 
          code.includes('type:') &&
          /type:\s*['"](?:line|bar|pie|area)['"]/.test(code)) {  // Look for type specification
        try {
          return <GraphRenderer code={code} />;
        } catch (error) {
          console.error('Error rendering graph:', error);
          // Fallback to code display if graph rendering fails
          const language = hljs.getLanguage(codeLanguage) ? codeLanguage : 'plaintext';
          const highlightedCode = hljs.highlight(code, { language }).value;
          return (
            <div className="code-block-wrapper">
              <Button 
                className="copy-code-button"
                onClick={() => copyToClipboard(code)}
              >
                <LuCopy size="15"/>
              </Button>
              <pre className="code-block">
                <code className={`language-${language}`} dangerouslySetInnerHTML={{ __html: highlightedCode }} />
              </pre>
            </div>

          );
        }
      }
      // // Find the minimum indentation level
      // const minIndent = codeLines.reduce((min, line) => {
      //   if (line.trim() === '') return min;
      //   const indent = line.match(/^\s*/)[0].length;
      //   return Math.min(min, indent);
      // }, Infinity);

      // // Remove the common indentation from all lines
      // const trimmedCode = codeLines.map(line => line.slice(minIndent)).join('\n');

      const language = hljs.getLanguage(codeLanguage) ? codeLanguage : 'plaintext';
      const highlightedCode = hljs.highlight(code, { language }).value;

      return (
        <div className="code-block-wrapper">
          <Button 
            className="copy-code-button"
            onClick={() => copyToClipboard(code)}
          >
            <LuCopy size="15"/>
          </Button>
          <pre className="code-block">
            <code className={`language-${language}`} dangerouslySetInnerHTML={{ __html: highlightedCode }} />
          </pre>
        </div>
      );
    };//

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];

      if (line.trim().startsWith('```')) {
        finishList();
        if (inCodeBlock) {
          formattedContent.push(processCodeBlock(codeBlockContent));
          inCodeBlock = false;
          codeBlockContent = [];
          codeLanguage = '';
        } else {
          inCodeBlock = true;
          codeLanguage = line.trim().slice(3).trim();
        }
        continue;
      }

      if (inCodeBlock) {
        codeBlockContent.push(line);
        continue;
      }

      // Handle table rows
      if (line.includes('|')) {
        const cells = line.split('|').filter(cell => cell.trim().length > 0);
        if (cells.length > 0) {
          if (!inTable) {
            finishList(); // End any ongoing list
            inTable = true;
            tableHeaders = parseTableRow(line);
          } else if (line.includes('-')) {
            // This is the alignment row
            tableAlignments = parseTableAlignments(line);
          } else {
            tableRows.push(parseTableRow(line));
          }
          continue;
        }
      } else if (inTable) {
        finishTable();
      }

      // Headers
      const headerMatch = line.match(/^(#{1,6})\s(.+)/);
      if (headerMatch) {
        finishList();
        finishTable();
        const level = headerMatch[1].length;
        formattedContent.push(React.createElement(`h${level}`, { key: `header-${i}` }, formatInlineElements(headerMatch[2])));
        continue;
      }

      // Unordered list items
      if (line.startsWith('- ') || line.startsWith('• ')) {
        finishTable();
        if (!inList || listType !== 'ul') {
          finishList();
          inList = true;
          listType = 'ul';
        }
        listItems.push(<li key={`li-${i}`}>{formatInlineElements(line.slice(2))}</li>);
        continue;
      }

      // Ordered list items
      const orderedListMatch = line.match(/^\d+\.(\d+)\.\s(.+)/);
      if (orderedListMatch) {
        finishTable();
        if (!inList || listType !== 'ol') {
          finishList();
          inList = true;
          listType = 'ol';
          listCounter = 0;
        }
        listCounter++;
        const [, , content] = orderedListMatch;
        listItems.push(
          <li key={`li-${i}`}>
            <strong>{listCounter}. </strong>
            {formatInlineElements(content)}
          </li>
        );
        continue;
      }

      // Regular paragraph
      if (line !== '') {
        finishList();
        finishTable();
        formattedContent.push(<p key={`p-${i}`}>{formatInlineElements(line)}</p>);
      }
    }

    finishList();
    finishTable();

    // Handle any remaining code block
    if (inCodeBlock && codeBlockContent.length > 0) {
      formattedContent.push(processCodeBlock(codeBlockContent));
    }

    return formattedContent;
  };

  const formatInlineElements = (text) => {
    const parts = text.split(/(\*\*|\*|`|\[.*?\]\(.*?\))/);
    let inBold = false;
    let inItalic = false;
    let inCode = false;
    let codeContent = '';

    return parts.map((part, index) => {
      if (part === '**') {
        inBold = !inBold;
        return null;
      } else if (part === '*') {
        inItalic = !inItalic;
        return null;
      } else if (part === '`') {
        if (inCode) {
          const highlightedCode = hljs.highlightAuto(codeContent).value;
          inCode = false;
          const result = <code key={index} className="inline-code" dangerouslySetInnerHTML={{ __html: highlightedCode }} />;
          codeContent = '';
          return result;
        } else {
          inCode = true;
          return null;
        }
      } else if (part.startsWith('[') && part.endsWith(')')) {
        const [linkText, url] = part.slice(1, -1).split('](');
        return <a key={index} href={url} target="_blank" rel="noopener noreferrer">{linkText}</a>;
      } else if (part.startsWith('[ACTION:google') && part.endsWith(']')) {
        return '';//
      } else {
        if (inCode) {
          codeContent += part;
          return null;
        } else if (inBold && inItalic) {
          return <strong key={index}><em>{part}</em></strong>;
        } else if (inBold) {
          return <strong key={index}>{part}</strong>;
        } else if (inItalic) {
          return <em key={index}>{part}</em>;
        } else {
          const urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
          const urls = part.match(urlRegex) || [];
          let lastIndex = 0;
          const elements = [];
          
          for (const url of urls) {
            const index = part.indexOf(url, lastIndex);
            if (index > lastIndex) {
              elements.push(part.slice(lastIndex, index));
            }
            elements.push(
              <a 
                key={`url-${index}`} 
                href={url} 
                style={{color: 'white', textDecoration: 'underline'}} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                {url}
              </a>//
            );
            lastIndex = index + url.length;
          }
          
          if (lastIndex < part.length) {
            elements.push(part.slice(lastIndex));
          }
          
          return elements;
        }
      }
    }).filter(Boolean);
  };

  // Chat Functionality
// Update startNewChat function to handle both types
  const startNewChat = async (entity) => {
    try {
      // const isAgent = entity.entityType === 'agent';
      const isAgent = currentMode === 'agents';
      let response;
      
      if (isAgent) {
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/agents/${entity[0]._id}/chat`,
          {
            title: `Chat with ${entity[0].name}`,
            aiModel: entity[0].aiModel
          },
          {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/chat/new`,
          {
            title: `Chat with ${entity[0].name}`,
            aiModel: entity[0].name
          },
          {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
          }
        );
      }
      
      setChatId(response.data._id);
      setIsConversationStarted(true);
      if (inputRef.current) {
        inputRef.current.style.height = 'auto';
      }
    } catch (error) {
      console.error('Error starting chat:', error);
      setError('Failed to start chat');
    }
  };

  const getModelEndpoint = (model) => {
    switch (model.toLowerCase()) {
      case 'o1':
        return 'o1';
      case 'o1 mini':
        return 'o1mini';
      case 'gpt 4o mini':
        return 'gpt4omini';
      case 'gpt 4':
        return 'gpt4';
      case 'claude 3.5':
        return 'claude3.5';
      case 'claude sonnet':
        return 'claudesonnet';
      case 'claude opus':
        return 'claude-opus';
      case 'claude haiku':
        return 'claude-haiku';
      case 'mistral ai large':
        return 'mistral-large';
      case 'mistral ai medium':
        return 'mistral-medium';
      case 'mistral ai small':
        return 'mistral-small';
      case 'codestral':
        return 'codestral';
      case 'llama 3.1':
        return 'llama3.1';
      case 'gemini 1.5':
        return 'gemini1.5';
      case 'cohere':
        return 'cohere';
      // case 'perplexity':
      //   return 'perplexity';
      case 'stable diffusion':
      case 'stablediffusion':
        return 'stablediffusion';
      case 'dall-e 3':
        return 'dall-e3';
      case 'dall-e3':
        return 'dall-e3';
      default:
        return model.toLowerCase().replace(' ', '');
    }
  };

// DROP ZONE IMPORT

  // Add these handlers before the return statement
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    const files = Array.from(e.dataTransfer.files);
    if (files.length === 0) return;

    // Check file sizes
    const oversizedFiles = files.filter(file => file.size > 5 * 1024 * 1024);
    if (oversizedFiles.length > 0) {
      Swal.fire({
        icon: "error",
        title: "File too large",
        text: "Maximum file size is 5MB",
        timer: 2000,
        width: 300,
        showConfirmButton: false
      });
      return;
    }
    
    setIsUploading(true);
    
    try {
      const newFileContents = [];
      const newFileInfos = [];
      
      for (const file of files) {
        const extension = file.name.split('.').pop().toLowerCase();
        const isImage = file.type.startsWith('image/');
        
        let fileContent;
        
        if (!isImage) {
          // Handle non-image files
          const fileContent = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
          
          newFileContents.push(fileContent);
          newFileInfos.push({
            name: file.name,
            type: file.name.split('.').pop().toLowerCase(),
            content: fileContent,
            isImage: false,
            mimeType: file.type
          });
        } else {
          // For images, only store the base64 data
          const base64Content = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
          
          newFileContents.push(base64Content);
          newFileInfos.push({
            name: file.name,
            type: file.name.split('.').pop().toLowerCase(),
            content: base64Content,
            isImage: true,
            mimeType: file.type
          });
        }
      }
      
      setFileContent(newFileContents.join('\n\n'));
      setUploadedFiles(prev => [...prev, ...newFileInfos]);

      // If there are existing messages, process files immediately
      // if (isFirstMessageHere && chatId) {
      //   await processFiles(newFileInfos);
      // }
      // Only process non-image files
      const nonImageFiles = newFileInfos.filter(file => !file.isImage);
      if (isFirstMessageHere && chatId && nonImageFiles.length > 0) {
        await processFiles(nonImageFiles);
      }

    } catch (error) {
      console.error('Error processing dropped files:', error);
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Oops...",
        text: "Error processing dropped files!",
        showConfirmButton: false,
        timer: 1500,
        width: 300,
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    } finally {
      setIsUploading(false);
    }
  };

// No images version
  // const handleDrop = async (e) => {
  //   e.preventDefault();
  //   setIsDragging(false);
    
  //   const files = Array.from(e.dataTransfer.files);
  //   if (files.length === 0) return;

  //   // Check if any of the files are images
  //   const hasImages = files.some(file => file.type.startsWith('image/'));
  //   if (hasImages) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "Calk is blind, he can't see your images right now!",
  //       showConfirmButton: false,
  //       timer: 1500
  //     });
  //     return;
  //   }

  //   // Check file sizes
  //   const oversizedFiles = files.filter(file => file.size > 5 * 1024 * 1024);
  //   if (oversizedFiles.length > 0) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "File too large",
  //       text: "Maximum file size is 5MB",
  //       timer: 2000,
  //       showConfirmButton: false
  //     });
  //     return;
  //   }
    
  //   setIsUploading(true);
    
  //   try {
  //     const newFileContents = [];
  //     const newFileInfos = [];
      
  //     for (const file of files) {
  //       const fileContent = await new Promise((resolve, reject) => {
  //         const reader = new FileReader();
  //         reader.onload = (e) => resolve(reader.result);
  //         reader.onerror = reject;
  //         reader.readAsDataURL(file);
  //       });
        
  //       newFileContents.push(fileContent);
  //       newFileInfos.push({
  //         name: file.name,
  //         type: file.name.split('.').pop().toLowerCase(),
  //         content: fileContent,
  //         isImage: false,
  //         mimeType: file.type
  //       });
  //     }
      
  //     setFileContent(newFileContents.join('\n\n'));
  //     setUploadedFiles(prev => [...prev, ...newFileInfos]);

  //     if (isFirstMessageHere && chatId && newFileInfos.length > 0) {
  //       await processFiles(newFileInfos);
  //     }

  //   } catch (error) {
  //     console.error('Error processing dropped files:', error);
  //     Swal.fire({
  //       position: "bottom-end",
  //       icon: "error",
  //       title: "Oops...",
  //       text: "Error processing dropped files!",
  //       showConfirmButton: false,
  //       timer: 1500
  //     });
  //   } finally {
  //     setIsUploading(false);
  //   }
  // };

//////////

  const handleFileUpload = async (event) => {
      const files = event.target.files;
      if (files.length === 0) return;

      // Check file sizes
      const oversizedFiles = Array.from(files).filter(file => file.size > 5 * 1024 * 1024);
      if (oversizedFiles.length > 0) {
        Swal.fire({
          icon: "error", 
          title: "File too large",
          text: "Maximum file size is 5MB",
          timer: 2000,
          showConfirmButton: false
        });
        event.target.value = '';
        return;
      }
      
      setIsUploading(true);

      try {
        const newFileContents = [];
        const newFileInfos = [];
        
        for (const file of files) {
          const extension = file.name.split('.').pop().toLowerCase();
          const isImage = file.type.startsWith('image/');
          
          let fileContent;
          
          if (!isImage) {
              // Handle non-image files as before
              const fileContent = await new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onload = (e) => resolve(reader.result);
                  reader.onerror = reject;
                  reader.readAsDataURL(file);
              });

              newFileContents.push(fileContent);
              newFileInfos.push({
                  name: file.name,
                  type: file.name.split('.').pop().toLowerCase(),
                  content: fileContent,
                  isImage: false,
                  mimeType: file.type
              });
          } else {
              // For images, only store the base64 data
              const base64Content = await new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onload = () => resolve(reader.result);
                  reader.onerror = reject;
                  reader.readAsDataURL(file);
              });

              newFileContents.push(base64Content);
              newFileInfos.push({
                  name: file.name,
                  type: file.name.split('.').pop().toLowerCase(),
                  content: base64Content,
                  isImage: true,
                  mimeType: file.type
              });
          }
      }
        
        setFileContent(newFileContents.join('\n\n'));
        setUploadedFiles(prev => [...prev, ...newFileInfos]);

        // If there are existing messages, process files immediately
        // if (isFirstMessageHere && chatId) {
        //   await processFiles(newFileInfos);
        // }

        // Only process non-image files
        const nonImageFiles = newFileInfos.filter(file => !file.isImage);
        if (isFirstMessageHere && chatId && nonImageFiles.length > 0) {
            await processFiles(nonImageFiles);
        }

      } catch (error) {
        console.error('Error processing files:', error);
        Swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Oops...",
          text: "Error processing dropped files!",
          showConfirmButton: false,
          timer: 1500,
          width: 300,
          // footer: '<a href="#">Why do I have this issue?</a>'
        });
      } finally {
        setIsUploading(false);
        event.target.value = '';
      }
  };

// No images version
  // const handleFileUpload = async (event) => {
  //     const files = event.target.files;
  //     if (files.length === 0) return;

  //     // Check if any of the files are images
  //     const hasImages = Array.from(files).some(file => file.type.startsWith('image/'));
  //     if (hasImages) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Calk is blind, he can't see your images right now!",
  //         showConfirmButton: false,
  //         timer: 1500
  //       });
  //       event.target.value = '';
  //       return;
  //     }

  //     // Check file sizes
  //     const oversizedFiles = Array.from(files).filter(file => file.size > 5 * 1024 * 1024);
  //     if (oversizedFiles.length > 0) {
  //       Swal.fire({
  //         icon: "error", 
  //         title: "File too large",
  //         text: "Maximum file size is 5MB",
  //         timer: 2000,
  //         showConfirmButton: false
  //       });
  //       event.target.value = '';
  //       return;
  //     }
      
  //     setIsUploading(true);

  //     try {
  //       const newFileContents = [];
  //       const newFileInfos = [];
        
  //       for (const file of files) {
  //         const fileContent = await new Promise((resolve, reject) => {
  //             const reader = new FileReader();
  //             reader.onload = (e) => resolve(reader.result);
  //             reader.onerror = reject;
  //             reader.readAsDataURL(file);
  //         });

  //         newFileContents.push(fileContent);
  //         newFileInfos.push({
  //             name: file.name,
  //             type: file.name.split('.').pop().toLowerCase(),
  //             content: fileContent,
  //             isImage: false,
  //             mimeType: file.type
  //         });
  //       }
        
  //       setFileContent(newFileContents.join('\n\n'));
  //       setUploadedFiles(prev => [...prev, ...newFileInfos]);

  //       if (isFirstMessageHere && chatId && newFileInfos.length > 0) {
  //           await processFiles(newFileInfos);
  //       }

  //     } catch (error) {
  //       console.error('Error processing files:', error);
  //       Swal.fire({
  //         position: "bottom-end",
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Error processing dropped files!",
  //         showConfirmButton: false,
  //         timer: 1500
  //       });
  //     } finally {
  //       setIsUploading(false);
  //       event.target.value = '';
  //     }
  // };

  const processFiles = async (files) => {
    setIsUploading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/chat/${chatId}/process-files`,
        { files },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
    } catch (error) {
      console.error('Error processing files:', error);
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Error processing files",
        showConfirmButton: false,
        timer: 1500,
        width: 300,
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileRemove = (indexToRemove) => {
    setUploadedFiles(prev => prev.filter((_, index) => index !== indexToRemove));
    
    // Update the fileContent state by removing the corresponding file content
    setFileContent(prev => {
      const contentArray = prev.split('\n\n');
      const newContent = contentArray
        .filter((_, index) => index !== indexToRemove)
        .join('\n\n');
      return newContent || '';
    });
  };

  const fetchMessages = async () => {
    if (!chatId) return;

    try {
      // First get the chat info to determine if it's an agent chat
      const chatResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/chat/${chatId}`,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
      );

      if (!chatResponse.data) {
        throw new Error('Chat not found');
      }

      // Determine if this is an agent chat by checking for agentId
      const isAgentChat = !!chatResponse.data.agentId;
      let messagesResponse;

      if (isAgentChat) {
        // Fetch agent details
        const agentResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/agents/details/${chatResponse.data.agentId}`,
          { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
        );

        // Fetch messages for agent chat
        messagesResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/agents/${chatResponse.data.agentId}/chat/${chatId}/messages`,
          { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
        );

        // Process messages to ensure they have the correct agent info
        const processedMessages = messagesResponse.data.map(message => {
          if (message.role === 'assistant') {
            return {
              ...message,
              agentId: message.agentId || message._id,
              agentName: message.agentName || message.aiModel,
              entityType: 'agent',
              icon: `/images/all_icons/${agentResponse.data.icon}`,
            };
          }
          return message;
        });
        

        // Filter out duplicate user messages
        const seenUserMessages = new Set();
        const uniqueMessages = processedMessages.filter(message => {
          if (message.role === 'user' && message.content.includes('@')) {
            if (seenUserMessages.has(message.content)) {
              return false;
            }
            seenUserMessages.add(message.content);
          }
          return true;
        });

        setMessages(uniqueMessages);

      } else {
        // Fetch messages for model chat
        messagesResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/chat/${chatId}/messages`,
          { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
        );

        // Process model messages with correct metadata
        const processedMessages = messagesResponse.data.map(message => {
          if (message.role === 'assistant') {
            const modelMapping = {
              'o1': { icon: 'gpt.png', displayName: 'o1' },
              'o1mini': { icon: 'gpt.png', displayName: 'o1 Mini' },
              'gpt4': { icon: 'gpt.png', displayName: 'GPT 4' },
              'gpt4o': { icon: 'gpt.png', displayName: 'GPT 4o' },
              'gpt4omini': { icon: 'gpt.png', displayName: 'GPT 4o Mini' },
              'claude3.5': { icon: 'claude.png', displayName: 'Claude 3.5' },
              'claudesonnet': { icon: 'claude.png', displayName: 'Claude Sonnet' },
              'claude-opus': { icon: 'claude.png', displayName: 'Claude Opus' },
              'claude-haiku': { icon: 'claude.png', displayName: 'Claude Haiku' },
              'mistral-large': { icon: 'mistral.png', displayName: 'Mistral AI Large' },
              'mistral-medium': { icon: 'mistral.png', displayName: 'Mistral AI Medium' },
              'mistral-small': { icon: 'mistral.png', displayName: 'Mistral AI Small' },
              'codestral': { icon: 'mistral.png', displayName: 'Codestral' },
              'llama3.1': { icon: 'llama.png', displayName: 'Llama 3.1' },
              'gemini1.5': { icon: 'gemini.png', displayName: 'Gemini 1.5' },
              'cohere': { icon: 'cohere.png', displayName: 'Cohere' },
              'perplexity': { icon: 'perplexity.png', displayName: 'Perplexity' },
              'stablediffusion': { icon: 'stablediffusion.png', displayName: 'Stable Diffusion' },
              'dall-e3': { icon: 'gpt.png', displayName: 'DALL-E 3' }
            };

            const modelInfo = modelMapping[message.aiModel.toLowerCase()] || 
              { icon: 'default.png', displayName: message.aiModel };

            return {
              ...message,
              entityType: 'model',
              icon: `/images/all_icons/${modelInfo.icon}`,
              displayName: message.agentName || modelInfo.displayName
            };
          }
          return message;
        });

        // Filter out duplicate user messages
        const seenUserMessages = new Set();
        const uniqueMessages = processedMessages.filter(message => {
          if (message.role === 'user' && message.content.includes('@')) {
            if (seenUserMessages.has(message.content)) {
              return false;
            }
            seenUserMessages.add(message.content);
          }
          return true;
        });

        setMessages(uniqueMessages);
      }

      // Handle image loading states
      const newImageLoaded = {};
      messagesResponse.data.forEach(msg => {
        if (msg.isImage) {
          newImageLoaded[msg._id] = false;
          if (typeof msg.content === 'string' && msg.content.startsWith('data:image/')) {
            const img = new Image();
            img.onload = () => {
              setImageLoaded(prev => ({ ...prev, [msg._id]: true }));
            };
            img.src = msg.content;
          }
        }
      });
      setImageLoaded(newImageLoaded);

      // Scroll to bottom after messages are loaded
      if (isInitialLoad) {
        setTimeout(() => {
          scrollToBottom();
          setIsInitialLoad(false);
        }, 100);
      }
      // setTimeout(() => {
      //   scrollToBottom();
      // }, 100);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError('Error fetching messages. Please try again.');
    }
  };

  const extractUrls = (text) => {
    const urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
    const matches = text.match(urlRegex) || [];
    let styledText = text;
    
    matches.forEach(url => {
      styledText = styledText.replace(url, `<span style="color: white; text-decoration: underline;">${url}</span>`);
    });

    return {
      urls: matches,
    };
  };

  const sendMessage = async () => {
    if (!input.trim() || selectedEntities.length === 0 || !chatId) return;

    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
    }

    // Extract URLs from input
    const urls = extractUrls(input);

    // If we have files, process them first
    // if (uploadedFiles && uploadedFiles.length > 0) {
    //     setIsUploading(true);
    //     try {
    //         // First process all files
    //         await axios.post(
    //             `${process.env.REACT_APP_BACKEND}/api/chat/${chatId}/process-files`,
    //             { files: uploadedFiles },
    //             {
    //                 headers: {
    //                     'Authorization': `Bearer ${localStorage.getItem('token')}`
    //                 }
    //             }
    //         );
    //     } catch (error) {
    //         console.error('Error processing files:', error);
    //         Swal.fire({
    //             position: "bottom-end",
    //             icon: "error",
    //             title: "Error processing files",
    //             showConfirmButton: false,
    //             timer: 1500
    //         });
    //         setIsUploading(false);
    //         return;
    //     }
    //     setIsUploading(false);
    // }

    // Modified section in sendMessage function to use the new processFiles helper
    // Replace the existing file processing block with this:


    // if (uploadedFiles && uploadedFiles.length > 0) {
    //   if (!isFirstMessageHere) {
    //     // For new conversations, process files before sending message
    //     await processFiles(uploadedFiles);
    //   }
    //   // For existing conversations, files are already processed upon upload
    // }

    // Handle non-image files only
    const nonImageFiles = uploadedFiles.filter(file => !file.isImage);
    if (nonImageFiles.length > 0) {
        if (!isFirstMessageHere) {
            await processFiles(nonImageFiles);
        }
      // For existing conversations, files are already processed upon upload
    }


    let cleanedMessage = input;
    selectedEntities.forEach(entity => {
      const entityRegex = new RegExp(`@${entity.name}\\s*`, 'g');
      cleanedMessage = cleanedMessage.replace(entityRegex, '');
    });
    cleanedMessage = cleanedMessage.trim();

    // Get any image files that were uploaded
    const imageFiles = uploadedFiles.filter(file => file.isImage);
    const fileContents = imageFiles.map(file => file.content);

    const userMessage = {
      content: cleanedMessage,
      role: 'user',
      entities: selectedEntities.map(entity => ({
        id: entity._id,
        name: entity.name,
        type: entity.entityType
      }))
    };

    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setUploadedFiles([]);
    setError(null);

    try {
      let context = messages.length > 4 ? [...messages.slice(-4)] : messages;
      context = context.filter(message => 
        !(typeof message.content === 'string' && message.content.startsWith('data:image/'))
      );

      // Create placeholders with complete metadata
      const placeholders = selectedEntities.map(entity => {
        const isAgent = entity.entityType === 'agent';
        return {
          id: `temp-${Date.now()}-${entity._id}`,
          role: 'assistant',
          content: '',
          aiModel: isAgent ? entity.aiModel : entity.name,
          entityType: entity.entityType,
          icon: `/images/all_icons/${entity.icon}`,
          displayName: entity.name,
          ...(isAgent && { 
            agentId: entity._id, 
            agentName: entity.name 
          })
        };
      });

      setMessages(prev => [...prev, ...placeholders]);

      await Promise.all(selectedEntities.map(async (entity, entityIndex) => {
        const isAgent = entity.entityType === 'agent';
        const messageId = placeholders[entityIndex].id;
        
        const endpoint = isAgent
          ? `${process.env.REACT_APP_BACKEND}/api/agents/${entity._id}/chat/${chatId}/message`
          : `${process.env.REACT_APP_BACKEND}/api/chat/${chatId}/${getModelEndpoint(entity.name.toLowerCase())}`;

        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            message: input,
            context: context,
            fileContent: fileContents,
            urls: urls,
            // isSearchEnabled: isSearchEnabled,
            ...(isAgent && { agentId: entity._id, agentName: entity.name })
          })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let sources = null;

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value);
          const lines = chunk.split('\n').filter(line => line.trim() !== '');

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const data = line.slice(5);
              //if (data === '[DONE]') continue;
              if (data === '[DONE]') {
                  setProgressState(null);  // Clear progress state at completion
                  continue;
              }
              try {

                // const parsed = JSON.parse(data);
                const parsed = JSON.parse(data);
                
                // Handle progress state updates
                // Only update progress state if it's a non-null value
                if (parsed.progressState !== undefined && parsed.progressState !== null) {
                  setProgressState(parsed.progressState);
                }

                if (parsed.content) {
                  setMessages(prev => {
                    return prev.map(msg => {
                      if (msg.id === messageId) {
                        return {
                          ...msg,
                          content: (msg.content || '') + parsed.content
                        };
                      }
                      return msg;
                    });
                  });
                  // setTimeout(() => scrollToBottom(), 0);
                } else if (parsed.sources) {
                  sources = parsed.sources;
                }
              } catch (e) {
                // Ignore parsing errors
              }
            }
          }
        }
      }));

    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message');
      Swal.fire({
        icon: "error",
        title: "Oops ! Calk AI was OOO 🏝️",
        text: "Either the model you requested is lazy or there was another issue processing your request!",
        footer: 'If this issue persists please contact us through our help center at <a href="mailto:help@calk-ai.com">help@calk-ai.com</a>.',
        showConfirmButton: true,
        confirmButtonText: 'Regenerate message',
      }).then((result) => {
        if (result.isConfirmed) {
          sendMessage();
        }
      });
    } finally {
      // We still need fetchMessages() to sync with backend, but our UI won't flicker
      // because we already have the correct metadata
      fetchMessages();
      if (inputRef.current) {
        inputRef.current.style.height = 'auto';
      }
    }
  };

  // Input Handling
  const handleInputChange = (e) => {
    const newInput = e.target.value;
    setInput(newInput);
    
    adjustTextareaHeight(e.target);
    
    const lastAtIndex = newInput.lastIndexOf('@');
    if (lastAtIndex > lastMentionIndex) {
      setShowMentionDropdown(true);
      setLastMentionIndex(lastAtIndex);
    } else if (lastAtIndex === -1) {
      setShowMentionDropdown(false);
      setLastMentionIndex(-1);
    }
    
    setMentionIndex(-1);
  };

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

///// Highlight section

  // const handleInputChange = (e) => {
  //   const newInput = e.target.value;
  //   let finalInput = newInput;

  //   // Store current cursor position
  //   const cursorPosition = e.target.selectionStart;

  //   // Check if an entity was partially deleted or modified
  //   selectedEntities.forEach(entity => {
  //     const mention = `@${entity.name}`;
  //     const mentionRegex = new RegExp(`@${entity.name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\s*`);

  //     // If the mention was in the previous input but is now partially deleted or modified
  //     if (input.includes(mention) && !newInput.includes(mention)) {
  //       // Remove the entire entity mention and any trailing space
  //       finalInput = input.replace(mentionRegex, '');
        
  //       // Remove the entity from selectedEntities
  //       setSelectedEntities(prev => prev.filter(e => e.name !== entity.name));
  //       setLastMentionIndex(-1);
  //       setShowMentionDropdown(false);

  //       // Manually update the textarea value and cursor position
  //       e.target.value = finalInput;
  //       e.target.selectionStart = finalInput.length;
  //       e.target.selectionEnd = finalInput.length;
  //     }
  //   });

  //   setInput(finalInput);
    
  //   if (e.target && inputRef.current) {
  //     adjustTextareaHeight(e.target);
  //   }
    
  //   const lastAtIndex = finalInput.lastIndexOf('@');
  //   if (lastAtIndex !== -1) {
  //     setShowMentionDropdown(true);
  //     setLastMentionIndex(lastAtIndex);
  //     setMentionIndex(0);
  //   } else {
  //     setShowMentionDropdown(false);
  //     setLastMentionIndex(-1);
  //   }
  // };

  // const adjustTextareaHeight = (textarea) => {
  //   if (!textarea || !textarea.style) return;
  //   textarea.style.height = 'auto';
  //   textarea.style.height = `${textarea.scrollHeight}px`;
  // };

  // const handleEntitySelect = async (entity) => {
  //   // Get the last @ index and everything after it
  //   const lastAtIndex = input.lastIndexOf('@');
  //   const textBeforeAt = input.slice(0, lastAtIndex);
    
  //   // If input is empty or there's no text before @, clear entities and start fresh
  //   if (!textBeforeAt || !input.trim()) {
  //     const formattedEntity = {
  //       ...entity,
  //       entityType: entity.hasOwnProperty('mood') ? 'agent' : 'model',
  //       iconPath: currentMode === 'agents' 
  //         ? `/images/all_icons/${entity.icon}`
  //         : `/images/all_icons/${entity.icon}`
  //     };
  //     setSelectedEntities([formattedEntity]);
  //     setInput(`@${entity.name} `);
  //     setShowMentionDropdown(false);
  //     if (inputRef.current) {
  //       inputRef.current.focus();
  //       setTimeout(() => {
  //         inputRef.current.selectionStart = inputRef.current.value.length;
  //         inputRef.current.selectionEnd = inputRef.current.value.length;
  //       }, 0);
  //     }
  //     return;
  //   }

  //   // Check if entity is already selected
  //   const isAlreadySelected = selectedEntities.some(e => e._id === entity._id);
    
  //   if (isAlreadySelected) {
  //     setSelectedEntities(prev => prev.filter(e => e._id !== entity._id));
  //     const mentionText = `@${entity.name}`;
  //     setInput(input.replace(mentionText, '').trim());
  //   } else {
  //     if (selectedEntities.length >= 4) {
  //       const oldestEntity = selectedEntities[0];
  //       setSelectedEntities([selectedEntities[1], entity]);
  //       const oldMention = `@${oldestEntity.name}`;
  //       setInput(input.replace(oldMention, '').trim() + ` @${entity.name} `);
  //     } else {
  //       setSelectedEntities(prev => [...prev, entity]);
  //       setInput(textBeforeAt + `@${entity.name} `);
  //     }
  //   }

  //   setShowMentionDropdown(false);
  //   setLastMentionIndex(-1); // Reset lastMentionIndex to allow immediate re-tagging
    
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //     setTimeout(() => {
  //       inputRef.current.selectionStart = inputRef.current.value.length;
  //       inputRef.current.selectionEnd = inputRef.current.value.length;
  //     }, 0);
  //   }
  // };

//////////


  const handleKeyDown = (e) => {
      // Determine entities based on conversation type rather than just currentMode
    const entities = currentMode === 'models' ? models : agents;
    
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        return; // Allow new line with Shift+Enter
      }
      
      e.preventDefault();

      if (isUploading) return; // No Sending message while uploading
      
      if (showMentionDropdown && mentionIndex !== -1) {
        // Handle dropdown selection
        handleEntitySelect(entities[mentionIndex]);
        return;
      }
      sendMessage();
      // if (input.trim() && selectedEntity) {
      //   sendMessage();
      // }

      if (!isConversationStarted) {
         startNewChat(selectedEntities);
         sendMessage();
      }

    } else if (showMentionDropdown) {
      switch (e.key) {
        case 'ArrowUp':
          e.preventDefault();
          setMentionIndex(prev => 
            prev <= 0 ? entities.length - 1 : prev - 1
          );
          break;
        case 'ArrowDown':
          e.preventDefault();
          setMentionIndex(prev => 
            prev >= entities.length - 1 ? 0 : prev + 1
          );
          break;
        case 'Tab':
          e.preventDefault();
          if (mentionIndex !== -1) {
            handleEntitySelect(entities[mentionIndex]);
          }
          break;
        default:
          break;
      }
    }
  };

  const handleEntitySelect = async (entity) => {
    // Get the last @ index and everything after it
    const lastAtIndex = input.lastIndexOf('@');
    const textBeforeAt = input.slice(0, lastAtIndex).trim();
    const textAfterLastAt = input.slice(lastAtIndex);
    
    // If input is empty or there's no text before @, clear entities and start fresh
    if (!textBeforeAt || !input.trim()) {
      const formattedEntity = {
        ...entity,
        // entityType: currentMode === 'agents' ? 'agent' : 'model',
        entityType: entity.hasOwnProperty('mood') ? 'agent' : 'model',
        iconPath: currentMode === 'agents' 
          ? `/images/all_icons/${entity.icon}`
          : `/images/all_icons/${entity.icon}`
      };
      setSelectedEntities([]);  // Clear all entities first
      setSelectedEntities([formattedEntity]);  // Then set the new one
      setInput(`@${entity.name} `);
      // console.log(formattedEntity);
      setShowMentionDropdown(false);
      if (inputRef.current) {
        inputRef.current.focus();
        setTimeout(() => {
          inputRef.current.selectionStart = inputRef.current.value.length;
          inputRef.current.selectionEnd = inputRef.current.value.length;
        }, 0);
      }
      return;
    }

    // If we're starting a new @ mention and already have 2 entities,
    // clear everything and start fresh
    if (selectedEntities.length >= 4 && textAfterLastAt === '@') {
      setSelectedEntities([entity]);
      setInput(`@${entity.name} `);
    } else {
      // Check if entity is already selected
      const isAlreadySelected = selectedEntities.some(e => e._id === entity._id);
      
      // If already selected, remove it
      if (isAlreadySelected) {
        setSelectedEntities(prev => prev.filter(e => e._id !== entity._id));
        const mentionText = `@${entity.name}`;
        setInput(input.replace(mentionText, '').trim());
      } else {
        // Only allow up to 2 entities
        if (selectedEntities.length >= 4) {
          const oldestEntity = selectedEntities[0];
          setSelectedEntities([selectedEntities[1], entity]);
          const oldMention = `@${oldestEntity.name}`;
          setInput(input.replace(oldMention, '').trim() + ` @${entity.name} `);
        } else {
          setSelectedEntities(prev => [...prev, entity]);
          const atMention = `@${entity.name}`;
          const atIndex = input.lastIndexOf('@');
          if (atIndex !== -1) {
            setInput(input.slice(0, atIndex) + atMention + ' ');
          } else {
            setInput((prev) => prev + (prev.endsWith(' ') ? '' : ' ') + atMention + ' ');
          }
        }
      }
    }

    setShowMentionDropdown(false);
    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current.selectionStart = inputRef.current.value.length;
        inputRef.current.selectionEnd = inputRef.current.value.length;
      }, 0);
    }
  };

  const handleCardClick = (entity) => {
    // Clear input first
    // setInput('');
    
    // Create the entity with correct type and icon path
    const formattedEntity = {
      ...entity,
      entityType: currentMode === 'agents' ? 'agent' : 'model',
      iconPath: currentMode === 'agents' 
        ? `/images/all_icons/${entity.icon}`
        : `/images/all_icons/${entity.icon}`
    };

    // Set as the only selected entity
    setSelectedEntities([formattedEntity]);
    
    // Update input with mention
    setInput(`@${entity.name} `);

    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
    }

    // Remove the old selectedEntity state since we're using selectedEntities
    setSelectedEntity(formattedEntity);

    if (!isConversationStarted) {
      setInput('');
      if (inputRef.current) {
        inputRef.current.style.height = 'auto';
      }
    }
  };

  // Handle send button click
  const handleSendClick = () => {
    if (input.trim() && selectedEntity && !isLoading) {
      sendMessage();
    }
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
    }
  };

  // Update the mention dropdown rendering
  const getMentionIconBanner = (entity, currentMode) => {
    if (currentMode === 'models') {
      return `/images/all_icons/${entity.icon}`;
    }
    return `/images/all_icons/${entity.icon}`;
  };

  const getEntityNameForMessage = (message) => {
    if (!message || !message.aiModel) return 'Loading';

    if (message.agentId) {
      // If message has a valid agentName, use it
      if (message.agentName && message.agentName !== 'Unknown') {
        return message.agentName;
      }

      const matchedAgent = agents.find(a => a._id === message.agentId);
      if (matchedAgent) {
        return matchedAgent.name;
      }
    }

    // Otherwise, handle as a model name
    const modelMapping = {
      'o1': 'o1',
      'o1mini': 'o1 Mini',
      'gpt4': 'GPT 4',
      'gpt4o': 'GPT 4o',
      'gpt4omini': 'GPT 4o Mini',
      'claude3.5': 'Claude 3.5',
      'claudesonnet': 'Claude Sonnet',
      'claude-opus': 'Claude Opus',
      'claude-haiku': 'Claude Haiku',
      'mistral-large': 'Mistral AI Large',
      'mistral-medium': 'Mistral AI Medium',
      'mistral-small': 'Mistral AI Small',
      'codestral': 'Codestral',
      'llama3.1': 'Llama 3.1',
      'gemini1.5': 'Gemini 1.5',
      'cohere': 'Cohere',
      'perplexity': 'Perplexity',
      'stablediffusion': 'Stable Diffusion',
      'dall-e3': 'DALL-E 3'
    };

    return modelMapping[message.aiModel.toLowerCase()] || message.aiModel;
  };

  const getMessageIcon = (message) => {
    if (!message || !message.aiModel) return '/images/all_icons/gpt.png';

    if (message.agentId) {
      const matchedAgent = agents.find(a => a._id === message.agentId);
      if (matchedAgent) {
        return `/images/all_icons/${matchedAgent.icon}`;
      }
    }

    const modelMapping = [
      { name: ['o1', 'o1'], icon: 'gpt.png' },
      { name: ['o1mini', 'o1 mini'], icon: 'gpt.png' },
      { name: ['gpt4', 'gpt 4', 'gpt-4'], icon: 'gpt.png' },
      { name: ['GPT 4o', 'gpt4o', 'gpt 4o'], icon: 'gpt.png' },
      { name: ['gpt4omini', 'gpt 4o mini'], icon: 'gpt.png' },
      { name: ['claude3.5', 'claude 3.5'], icon: 'claude.png' },
      { name: ['claudesonnet', 'claude sonnet'], icon: 'claude.png' },
      { name: ['claude-opus', 'claude opus'], icon: 'claude.png' },
      { name: ['claude-haiku', 'claude haiku'], icon: 'claude.png' },
      { name: ['Mistral AI Large', 'mistral-large', 'mistral ai large'], icon: 'mistral.png' },
      { name: ['mistral-medium', 'mistral ai medium'], icon: 'mistral.png' },
      { name: ['mistral-small', 'mistral ai small'], icon: 'mistral.png' },
      { name: ['codestral'], icon: 'mistral.png' },
      { name: ['llama3.1', 'llama 3.1'], icon: 'llama.png' },
      { name: ['gemini1.5', 'gemini 1.5'], icon: 'gemini.png' },
      { name: ['cohere', 'cohere'], icon: 'cohere.png' },
      { name: ['perplexity'], icon: 'perplexity.png' },
      { name: ['stablediffusion', 'stable diffusion'], icon: 'stablediffusion.png' },
      { name: ['dall-e3', 'dall-e 3'], icon: 'gpt.png' }
    ];

    const model = modelMapping.find(m => 
      m.name.some(n => n.toLowerCase() === message.aiModel.toLowerCase())
    );
    
    return model ? `/images/all_icons/${model.icon}` : '/images/default.png';
  };

  const fetchChatMessages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chat/${chatId}/messages`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setMessages(response.data);
      // Reset image loaded state for all messages
      const newImageLoaded = {};
      response.data.forEach(msg => {
        if (msg.isImage) {
          newImageLoaded[msg._id] = false;
        }
      });
      setImageLoaded(newImageLoaded);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError('Error fetching messages. Please try again.');
    }
  };

  const fetchAgentMessages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/agents/${selectedEntity._id}/chat/${chatId}/messages`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      const fetchedMessages = response.data.map(msg => {
        if (msg.isImage && typeof msg.content === 'string' && msg.content.startsWith('data:image/')) {
          return { ...msg, isImage: true };
        }
        return msg;
      });

      setMessages(fetchedMessages);

      // Reset image loaded state for all messages
      const newImageLoaded = {};
      fetchedMessages.forEach(msg => {
        if (msg.isImage) {
          newImageLoaded[msg._id] = false;
        }
      });
      setImageLoaded(newImageLoaded);

      // Trigger image loading for image messages
      fetchedMessages.forEach(msg => {
        if (msg.isImage && typeof msg.content === 'string' && msg.content.startsWith('data:image/')) {
          const img = new Image();
          img.onload = () => {
            setImageLoaded(prev => ({ ...prev, [msg._id]: true }));
          };
          img.src = msg.content;
        }
      });
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError('Error fetching messages. Please try again.');
    }
  };

  const fetchChatTitle = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chat/${chatId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setTitle(response.data.title);
    } catch (error) {
      console.error('Error fetching chat title:', error);
      setError('Error fetching chat title. Please try again.');
    }
  };

  const updateChatTitle = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/chat/${chatId}/title`, 
        { title },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setIsEditingTitle(false);
    } catch (error) {
      console.error('Error updating chat title:', error);
      setError('Error updating chat title. Please try again.');
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Oops...",
        text: "Error updating chat title. Please try again.",
        showConfirmButton: false,
        timer: 1500,
        width: 300,
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    }
  };

  const updateAgentChatTitle = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/agents/${selectedEntity._id}/chat/${chatId}/title`, 
        { title },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setIsEditingTitle(false);
    } catch (error) {
      console.error('Error updating chat title:', error);
      setError('Error updating chat title. Please try again.');
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Oops...",
        text: "Error updating chat title. Please try again.",
        showConfirmButton: false,
        timer: 1500,
        width: 300,
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    }
  };

  const handleTitleSave = async () => {
    if (currentMode === 'agents') {
      await updateAgentChatTitle();
    } else {
      await updateChatTitle();
    }
  };

  const handleTitleEdit = () => {
    setIsEditingTitle(true);
    setTimeout(() => titleInputRef.current.focus(), 0);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleTitleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleTitleSave();
    }
  };

  // const groupMessages = (messages) => {
  //   const groupedMessages = [];
  //   let skipNext = false;

  //   messages.forEach((message, index) => {
  //     // Skip if this message was already handled
  //     if (skipNext) {
  //       skipNext = false;
  //       return;
  //     }

  //     const nextMessage = messages[index + 1];
  //     const prevMessage = messages[index - 1];

  //     if (message.role === 'user') {
  //       // Handle user messages individually
  //       groupedMessages.push([message]);
  //     } else if (message.role === 'assistant') {
  //       // Check if this is part of a parallel response
  //       if (nextMessage && 
  //           nextMessage.role === 'assistant' && 
  //           prevMessage?.role === 'user') {
  //         // Create a group with current and next message
  //         groupedMessages.push([message, nextMessage]);
  //         skipNext = true; // Skip the next message since we've used it
  //       } else if (!skipNext) { // Only add if not marked for skipping
  //         groupedMessages.push([message]);
  //       }
  //     }
  //   });

  //   return groupedMessages;
  // };

  const groupMessages = (messages) => {
    const groupedMessages = [];
    let skipNext = false;

    messages.forEach((message, index) => {
      // Skip if this message was already handled
      if (skipNext) {
        skipNext = false;
        return;
      }

      const nextMessage = messages[index + 1];
      const prevMessage = messages[index - 1];

      if (message.role === 'user') {
        // Handle user messages individually
        groupedMessages.push([message]);
      } else if (message.role === 'assistant') {
        // Check if this is part of a parallel response
        // Add condition to check if there are exactly 2 assistant messages
        const isDoubleResponse = nextMessage && 
            nextMessage.role === 'assistant' && 
            prevMessage?.role === 'user' &&
            (!messages[index + 2] || messages[index + 2].role === 'user');

        if (isDoubleResponse) {
          // Create a group with current and next message
          groupedMessages.push([message, nextMessage]);
          skipNext = true; // Skip the next message since we've used it
        } else if (!skipNext) { // Only add if not marked for skipping
          groupedMessages.push([message]);
        }
      }
    });

    return groupedMessages;
  };

  const handleDeleteChat = async (chatId, e, chatType) => {
    // e.preventDefault();
    // e.stopPropagation();
    try {
      const endpoint = chatType === 'agent'
        ? `${process.env.REACT_APP_BACKEND}/api/agents/${chatId}/chat/${chatId}`
        : `${process.env.REACT_APP_BACKEND}/api/chat/${chatId}`;

      await axios.delete(endpoint, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      
      navigate('/home');

    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

return (
    <div className="home-chat-container">
      <div className={`chat-content ${isConversationStarted ? 'conversation-started' : ''}`}>
        <HomeHeader userFirstName={userFirstName} />

        {/* Messages Area - Only shown when conversation is started */}
        {isConversationStarted && (
          <div 
            className={`chat-area ${isDragging ? 'drag-over' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {isDragging && (
              <div className={`drop-overlay-chat ${isDragging ? 'visible' : ''}`}>
                Drop files here
              </div>
            )}
            <div className="chat-header-section">
              <Button 
                onClick={() => {
                  navigate('/home');
                  window.location.reload();
                }}
                variant="ghost"
                className="back-button"
              >
                <Flex align="center" gap="2">
                  <IoArrowBack />
                </Flex>
              </Button>

              <div className="title-section">
                {isEditingTitle ? (
                  <input
                    ref={titleInputRef}
                    type="text"
                    value={title}
                    onChange={handleTitleChange}
                    onBlur={handleTitleSave}
                    onKeyPress={handleTitleKeyPress}
                    className="chat-title-input"
                  />
                ) : (
                  <h2 className="chat-title">{title}</h2>
                )}
                <button onClick={handleTitleEdit} className="edit-title-btn">
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              </div>
              <div className="header-right-section">
                <Tooltip content="View the conversation files">
                  <button className="header-icon-btn">
                    <PiFilesBold size={18} />
                  </button>
                </Tooltip>
                <Tooltip content="Share your conversation">
                  <button className="header-icon-btn">
                    <FaShareAlt size={16} />
                  </button>
                </Tooltip>
                <AlertDialog.Root>
                  <AlertDialog.Trigger>
                    <Tooltip content="Delete the conversation">
                      <button className="header-icon-btn">
                        <FaTrash size={14} />
                      </button>
                    </Tooltip>
                  </AlertDialog.Trigger>
                  
                  <AlertDialog.Content>
                    <AlertDialog.Title>Delete the conversation?</AlertDialog.Title>
                    <AlertDialog.Description>
                      Are you sure you want to delete <b>{title}</b>? This chat will no longer be accessible and you will not be able to retrieve it.
                    </AlertDialog.Description>
                    
                    <Flex gap="3" mt="4" justify="end">
                      <AlertDialog.Cancel>
                        <Button variant="soft" color="gray">
                          Cancel
                        </Button>
                      </AlertDialog.Cancel>
                      <AlertDialog.Action>
                        <Button 
                          color="red"
                          onClick={() => {
                            handleDeleteChat(chatId, currentMode === 'agents' ? 'agents' : 'models');
                            navigate('/home');
                            window.location.reload();
                          }}
                        >
                          Delete
                        </Button>
                      </AlertDialog.Action>
                    </Flex>
                  </AlertDialog.Content>
                </AlertDialog.Root>
              </div>
            </div>
            {groupMessages(messages).map((messageGroup, groupIndex) => (
              <React.Fragment key={groupIndex}>
                {messageGroup.length > 1 ? (
                  // Render parallel messages side by side
                  <div className="messages-row">
                    {messageGroup.map((message, messageIndex) => (
                      <div key={`${groupIndex}-${messageIndex}`} className={`message-container ${message.role}`}>
                        <div className="message-header">
                          {message.role === 'assistant' ? (
                            <>
                              <img 
                                src={getMessageIcon(message)} 
                                alt={getEntityNameForMessage(message)} 
                              />
                              <span>{getEntityNameForMessage(message)}</span>
                            </>
                          ) : (
                            <span>{userFirstName}</span>
                          )}
                        </div>
                        <div className="message">
                          {message.isImage && message.aiModel.toLowerCase() === 'stablediffusion' ? (
                            <div>Generating image...</div>
                          ) : message.isImage ? (
                            <img 
                              src={`data:image/png;base64,${imageData}`}
                              alt="Generated" 
                              className="generated-image"
                            />
                          ) : (
                            formatMessage(message)
                          )}
                          {message.role === 'assistant' && (
                            <div className="message-actions">
                              <button 
                                className={`feedback-button ${message.feedback === 'positive' ? 'active' : ''}`}
                                onClick={() => handleFeedback(message._id, true)}
                                title="Thumbs up"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill={message.feedback === 'positive' ? 'rgba(0, 89, 255, 0.2)' : 'currentColor'}>
                                  <path d="M2 20h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1H2v11zm19.83-7.12c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66-.23-.45-.52-.86-.88-1.22L14 2 7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.84C7 18.95 8.05 20 9.34 20h8.11c.7 0 1.36-.37 1.72-.97l2.66-6.15z"/>
                                </svg>
                              </button>
                              <button 
                                className={`feedback-button ${message.feedback === 'negative' ? 'active' : ''}`}
                                onClick={() => handleFeedback(message._id, false)}
                                title="Thumbs down"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill={message.feedback === 'negative' ? 'black' : 'currentColor'}>
                                  <path d="M22 4h-2c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h2V4zM2.17 11.12c-.11.25-.17.52-.17.8V13c0 1.1.9 2 2 2h5.5l-.92 4.65c-.05.22-.02.46.08.66.23.45.52.86.88 1.22L10 22l6.41-6.41c.38-.38.59-.89.59-1.42V6.34C17 5.05 15.95 4 14.66 4h-8.1c-.71 0-1.36.37-1.72.97l-2.67 6.15z"/>
                                </svg>
                              </button>
                              <button 
                                className="copy-button" 
                                onClick={() => copyToClipboard(message.content)}
                                title="Copy message"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                  <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                  <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                </svg>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  // Render single messages normally
                  <div className={`message-container ${messageGroup[0].role}`}>
                    <div className="message-header">
                      {messageGroup[0].role === 'assistant' ? (
                        <>
                          <img 
                            src={getMessageIcon(messageGroup[0])} 
                            alt={getEntityNameForMessage(messageGroup[0])} 
                          />
                          <span>{getEntityNameForMessage(messageGroup[0])}</span>
                        </>
                      ) : (
                        <>
                          <div className="user-initials-circle">
                            {userFirstName?.charAt(0)}{userLastName?.charAt(0)}
                          </div>
                          <span>{userFirstName}</span>
                        </>
                      )}
                    </div>
                    <div className="message">
                      {messageGroup[0].isImage && messageGroup[0].aiModel.toLowerCase() === 'stablediffusion' ? (
                        <div>Generating image...</div>
                      ) : messageGroup[0].isImage ? (
                        <img 
                          src={`data:image/png;base64,${imageData}`}
                          alt="Generated" 
                          className="generated-image"
                        />
                      ) : (
                        formatMessage(messageGroup[0])
                      )}
                      {messageGroup[0].role === 'assistant' && (
                        <div className="message-actions">
                          <button 
                            className={`feedback-button ${messageGroup[0].feedback === 'positive' ? 'active' : ''}`}
                            onClick={() => handleFeedback(messageGroup[0]._id, true)}
                            title="Thumbs up"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill={messageGroup[0].feedback === 'positive' ? 'rgba(0, 89, 255, 0.2)' : 'currentColor'}>
                              <path d="M2 20h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1H2v11zm19.83-7.12c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66-.23-.45-.52-.86-.88-1.22L14 2 7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.84C7 18.95 8.05 20 9.34 20h8.11c.7 0 1.36-.37 1.72-.97l2.66-6.15z"/>
                            </svg>
                          </button>
                          <button 
                            className={`feedback-button ${messageGroup[0].feedback === 'negative' ? 'active' : ''}`}
                            onClick={() => handleFeedback(messageGroup[0]._id, false)}
                            title="Thumbs down"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill={messageGroup[0].feedback === 'negative' ? 'black' : 'currentColor'}>
                              <path d="M22 4h-2c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h2V4zM2.17 11.12c-.11.25-.17.52-.17.8V13c0 1.1.9 2 2 2h5.5l-.92 4.65c-.05.22-.02.46.08.66.23.45.52.86.88 1.22L10 22l6.41-6.41c.38-.38.59-.89.59-1.42V6.34C17 5.05 15.95 4 14.66 4h-8.1c-.71 0-1.36.37-1.72.97l-2.67 6.15z"/>
                            </svg>
                          </button>
                          <button 
                            className="copy-button" 
                            onClick={() => copyToClipboard(messageGroup[0].content)}
                            title="Copy message"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                            </svg>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
            <div ref={messagesEndRef} />
          </div>
        )}
        {/* Input Section */}
        <div className="input-section">
          {(uploadedFiles.length > 0 || isUploading) && (
              <div className="files-banner">
                  {uploadedFiles.map((file, index) => (
                      <div key={index} className="file-box">
                          <button 
                              className="file-delete-btn"
                              onClick={(e) => {
                                  e.stopPropagation();
                                  handleFileRemove(index);
                              }}
                              disabled={isUploading}
                          >
                              ×
                          </button>
                          <span className="file-name">{file.name}</span>
                          {isUploading ? (
                              <div className="file-type uploading">
                                  <svg className="circular-progress" viewBox="0 0 36 36">
                                      <path
                                          d="M18 2.0845
                                              a 15.9155 15.9155 0 0 1 0 31.831
                                              a 15.9155 15.9155 0 0 1 0 -31.831"
                                          fill="none"
                                          stroke="#E6E6E6"
                                          strokeWidth="2"
                                      />
                                      <path
                                          className="progress"
                                          d="M18 2.0845
                                              a 15.9155 15.9155 0 0 1 0 31.831
                                              a 15.9155 15.9155 0 0 1 0 -31.831"
                                          fill="none"
                                          stroke="#0D00FF"
                                          strokeWidth="2"
                                      />
                                  </svg>
                                  <span>Uploading...</span>
                              </div>
                          ) : (
                              <span className="file-type">{file.type}</span>
                          )}
                      </div>
                  ))}
              </div>
          )}
          {selectedEntities.length > 0 && (
            <div className="talking-to-banner">
              Talking to{' '}
              {selectedEntities.map((entity, index) => (
                <React.Fragment key={entity._id || index}>
                  <img 
                    src={entity.entityType === 'agent' 
                      ? `/images/all_icons/${entity.icon}`
                      : `/images/all_icons/${entity.icon}`
                    }
                    alt={entity.name} 
                    className="mention-icon"
                  />
                  {entity.name}
                  {index < selectedEntities.length - 1 && ' and '}
                </React.Fragment>
              ))}
            </div>
          )}
          <div 
            className={`input-container ${!isConversationStarted && isDragging ? 'drag-over' : ''}`}
            onDragOver={(e) => {
              e.preventDefault();
              if (!isConversationStarted) setIsDragging(true);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              if (!isConversationStarted) setIsDragging(false);
            }}
            onDrop={(e) => {
              if (!isConversationStarted) handleDrop(e);
            }}
          >
            {showMentionDropdown && (
              <div className="mention-dropdown" ref={dropdownRef}>
                {getAllEntities(models, agents)
                  .filter(entity => {
                    const searchText = input.slice(input.lastIndexOf('@') + 1).toLowerCase();
                    return entity.name.toLowerCase().includes(searchText);
                  })
                  .map((entity, index) => (
                    <div
                      key={entity._id}
                      ref={index === mentionIndex ? selectedItemRef : null}
                      className={`mention-item ${index === mentionIndex ? 'selected' : ''}`}
                      onClick={() => handleEntitySelect(entity)}
                    >
                      <img 
                        src={entity.iconPath}
                        alt={entity.name} 
                        className="mention-icon"
                      />
                      <span>@{entity.name}</span>
                    </div>
                  ))}
              </div>
            )}
            <textarea
              ref={inputRef}
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder={`Start a conversation ${selectedEntity ? `with ${selectedEntity.name}` : 'using @'}`}
              rows={1}
              className={`chat-input ${isEnhancing ? 'enhancing' : ''}`}
              disabled={isEnhancing || isUploading}
            />
            {/*<HighlightedInput
              input={input}
              selectedEntities={selectedEntities}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder={`Start a conversation ${selectedEntity ? `with ${selectedEntity.name}` : 'using @'}`}
              isEnhancing={isEnhancing}
              disabled={isEnhancing || isUploading}
            />*/}
            <div className="input-buttons-wrapper">
              <div className="input-buttons-left">
                <Tooltip content="Import files">
                  <IconButton radius="full" variant="soft">
                    <button 
                      className={`action-button ${isEnhancing ? 'enhancing' : ''}`}
                      onClick={() => fileInputRef.current.click()}
                      disabled={isUploading}
                    >
                      <MdOutlineAttachFile size="22"/>
                    </button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      multiple
                      onChange={handleFileUpload}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip content="Enhance your prompt">
                  <IconButton radius="full" variant="soft">
                    <button 
                      className={`action-button enhance ${isEnhancing ? 'enhancing' : ''}`}
                      onClick={handleEnhancePrompt}
                      disabled={isEnhancing || isUploading}
                    >
                      {/*✨*/}
                    <FaWandMagicSparkles size="22"/>
                    </button>
                  </IconButton>
                </Tooltip>
                {/*<Tooltip content={isSearchEnabled ? "Internet search enabled" : "Click to enable Internet Search"}>
                  <IconButton 
                    radius="full" 
                    variant="soft"
                    onClick={() => setIsSearchEnabled(!isSearchEnabled)}
                    className={`action-button tool ${isSearchEnabled ? 'active' : ''}`}
                  >
                    <IoIosGlobe 
                      size="30" 
                      style={{ 
                        padding: '1px',
                        paddingBottom: '1px'
                      }}
                    />
                  </IconButton>
                </Tooltip>*/}
              </div>
              <div className="input-buttons-right">
                <Tooltip content="Send message">
                  <IconButton radius="full">
                    <button 
                      className={`action-button ${isEnhancing ? 'enhancing' : ''}`}
                      onClick={handleSendClick}
                      disabled={!input.trim() || !selectedEntity || isUploading}
                    >
                      <IoIosSend size="20"/>
                    </button>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>

        {/* Models/Agents Tabs - Only shown when conversation hasn't started */}
        {!isConversationStarted && (
          <Tabs.Root 
            defaultValue="models" 
            onValueChange={value => setCurrentMode(value)}
            className="tabs-root"
          >
            <Tabs.List className="tabs-list-home">
              <Tabs.Trigger value="models" className="tab-trigger">Models</Tabs.Trigger>
              <Tabs.Trigger value="agents" className="tab-trigger">Agents</Tabs.Trigger>
              <Button onClick={handleCreateAgent} className="tab-trigger create-button-home">Create Agent +</Button>
            </Tabs.List>

            <ScrollArea className="tabs-content">
              <Tabs.Content value="models" className="tab-content">
                <div className="cards-grid">
                  {models.map(model => (
                    <Card 
                      key={model._id} 
                      className="entity-card"
                      onClick={() => handleCardClick(model)}
                    >
                      <div className="entity-header">
                        <div className="entity-info">
                          <img src={`/images/all_icons/${model.icon}`} alt={model.name} />
                          <h3>{model.name}</h3>
                        </div>
                        <div onClick={(e) => e.stopPropagation()}>
                          <ModelInfoHoverCard model={model} />
                        </div>
                      </div>
                      <p>{model.description}</p>
                    </Card>
                  ))}
                </div>
              </Tabs.Content>

              <Tabs.Content value="agents" className="tab-content">
               <div className="cards-grid">
                 {[...agents].sort((a, b) => {
                   if (a.name === 'Calk') return -1;
                   if (b.name === 'Calk') return 1;
                   if (a.name === 'helper') return -1;
                   if (b.name === 'helper') return 1;
                   return 0;
                 }).map(agent => (
                   <Card 
                     key={agent._id} 
                     className="entity-card"
                     onClick={() => handleCardClick(agent)}
                   >
                     <div className="entity-header">
                       <div className="entity-info">
                         <img src={`/images/all_icons/${agent.icon}`} alt={agent.name} />
                         <h3>{agent.name}</h3>
                       </div>
                     </div>
                     <p>{agent.miniDescription}</p>
                   </Card>
                 ))}
               </div>
              </Tabs.Content>
            </ScrollArea>
          </Tabs.Root>
        )}
      </div>
    </div>
  );
};

export default HomeChat;