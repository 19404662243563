import React from 'react';
// import { ThemeProvider } from "next-themes";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { SidebarProvider, useSidebar } from './context/SidebarContext';
import { AuthProvider, useAuth } from './context/AuthContext';
import CreateAdminAgentModal from './pages/CreateAdminAgentModal';
import CreateAgentModal from './pages/CreateAgentModal';
import ChatManagement from './pages/ChatManagement';
import EditAgentPage from './pages/EditAgentPage';
import Integrations from './pages/Integrations';
import RegisterSSO from './pages/RegisterSSO';
import Knowledge from './pages/Knowledge';
import Analytics from './pages/Analytics';
import HomeChat from './pages/HomeChat';
import HomeChat2 from './pages/HomeChat2';
import Sidebar from './pages/Sidebar';
import Agents from './pages/Agents';
import Login from './pages/Login';
import Register from './pages/Register';
import Pricing from './pages/Pricing';
import MyAccount from './pages/MyAccount';
import Team from './pages/Team';
import HelpPopup from './pages/HelpPopup';
import "@radix-ui/themes/styles.css";
import Intercom from '@intercom/messenger-js-sdk';
import { Theme } from "@radix-ui/themes";
// import UnderConstruction from './pages/in_waiting/UnderConstruction';
// import Documentation from './pages/in_waiting/Documentation';
// import CompanyInformation from './pages/CompanyInformation';
// import HelpCenter from './pages/in_waiting/HelpCenter';
// import Tutorials from './pages/in_waiting/Tutorials';
// import Community from './pages/in_waiting/Community';
// import Finetune from './pages/in_waiting/Finetune';
// import Feedback from './pages/in_waiting/Feedback'
// import Roadmap from './pages/in_waiting/Roadmap';
// import Prompts from './pages/in_waiting/Prompts';
// import Settings from './pages/Settings'


import './App.css';


const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const SuperAdminProtectedRoute = ({ children }) => {
  const { isAuthenticated, isSuperAdmin } = useAuth();
  
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  
  if (!isSuperAdmin) {
    return <Navigate to="/home" />;
  }
  
  return children;
};

function AppContent() {
  const { isAuthenticated, loading } = useAuth();
  const { isCollapsed } = useSidebar(); // Add this
  const showSidebar = isAuthenticated && !['/login', '/register', '/pricing'].includes(window.location.pathname);
  const showHelp = isAuthenticated && !['/login', '/register', '/pricing'].includes(window.location.pathname);

  return (
    <div className="app">
      {showSidebar && <Sidebar />}
      {showHelp && <HelpPopup />}
      <main className={`main-content ${showSidebar ? 'with-sidebar' : ''} ${isCollapsed ? 'sidebar-collapsed' : ''}`}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register-sso" element={<RegisterSSO />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/" element={<ProtectedRoute><HomeChat2 /></ProtectedRoute>} />
          <Route path="/home" element={<ProtectedRoute><HomeChat2 /></ProtectedRoute>} />
          <Route path="/home/chat/:chatId" element={<ProtectedRoute><HomeChat2 /></ProtectedRoute>} />
          <Route path="/home/agents/:chatId" element={<ProtectedRoute><HomeChat2 /></ProtectedRoute>} />
          <Route path="/agents" element={<ProtectedRoute><Agents /></ProtectedRoute>} />
          <Route path="/agents/create" element={<ProtectedRoute><CreateAgentModal /></ProtectedRoute>} />
          <Route path="/agents/create/admin" element={<SuperAdminProtectedRoute><CreateAdminAgentModal /></SuperAdminProtectedRoute>} />
          <Route path="/agents/edit/:agentId" element={<ProtectedRoute><EditAgentPage /></ProtectedRoute>} />
          <Route path="/knowledge" element={<ProtectedRoute><Knowledge /></ProtectedRoute>} />
          <Route path="/integrations" element={<ProtectedRoute><Integrations /></ProtectedRoute>} />
          <Route path="/analytics" element={<ProtectedRoute><Analytics /></ProtectedRoute>} />
          <Route path="/my-account" element={<ProtectedRoute><MyAccount /></ProtectedRoute>} />
          <Route path="/team" element={<ProtectedRoute><Team /></ProtectedRoute>} />
          <Route path="/chat-management" element={<ProtectedRoute><ChatManagement /></ProtectedRoute>} />
          {/*<Route path="/finetune" element={<ProtectedRoute><Finetune /></ProtectedRoute>} />
          <Route path="/help-center" element={<ProtectedRoute><HelpCenter /></ProtectedRoute>} />
          <Route path="/prompts" element={<ProtectedRoute><Prompts /></ProtectedRoute>} />
          <Route path="/company-info" element={<ProtectedRoute><CompanyInformation /></ProtectedRoute>} />
          <Route path="/maintenance" element={<ProtectedRoute><UnderConstruction /></ProtectedRoute>} />
          <Route path="/tutorials" element={<ProtectedRoute><Tutorials /></ProtectedRoute>} />
          <Route path="/documentation" element={<ProtectedRoute><Documentation /></ProtectedRoute>} />
          <Route path="/feedback" element={<ProtectedRoute><Feedback /></ProtectedRoute>} />
          <Route path="/community" element={<ProtectedRoute><Community /></ProtectedRoute>} />
          <Route path="/roadmap" element={<ProtectedRoute><Roadmap /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />*/}
        </Routes>
      </main>
      
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <SidebarProvider>
        <Router>
          <Theme>
            <AppContent />
          </Theme>
        </Router>
      </SidebarProvider>
    </AuthProvider>
  );
}

export default App;