import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Eye, EyeOff } from 'lucide-react';
import '../styles/Login.css';
import Swal from 'sweetalert2'

const Login = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login, googleLogin } = useAuth();

  useEffect(() => {
    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleSignIn
      });

      window.google.accounts.id.renderButton(
        document.getElementById('google-signin-button'),
        { theme: 'outline', size: 'large' }
      );
    };

    if (window.google && window.google.accounts) {
      initializeGoogleSignIn();
    } else {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    }
  }, []);


  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const success = await login(credentials);
    if (success) {
      navigate('/home');
    } else {
      setError('Invalid email or password');
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Login Error.",
        text: "Invalid email or password.",
        showConfirmButton: false,
        timer: 2000,
        width: 300,
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    }
  };

  const handleGoogleSignIn = async (response) => {
    try {
      const { credential } = response;
      // console.log('Google Sign-In successful. Credential:', credential);

      const result = await googleLogin(credential);

      if (result.success) {
        if (result.isNewUser) {
          // Redirect new users to the RegisterSSO component
          navigate('/register-sso', { 
            state: { 
              googleUser: {
                firstName: result.user.firstName,
                lastName: result.user.lastName,
                email: result.user.email,
                password: result.user.password
              }
            } 
          });
        } else {
          // Existing users go straight to the dashboard
          navigate('/home');
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Login successful",
            showConfirmButton: false,
            timer: 1000,
            width: 300,
          });
        }
      } else {
        setError(result.message || 'An error occurred during Google Sign-In');
      }
    } catch (error) {
      console.error('Error during Google Sign-In:', error);
      setError('An unexpected error occurred during Google Sign-In');
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <img src="/calk-name.png" alt="Calk Logo" className="logo" />
        <h2>Welcome</h2>
        {error && <p className="error">{error}</p>}
        <div id="google-signin-button"></div>
        <div className="separator">
          <span>or</span>
        </div>
        <div className="input-group">
          <label htmlFor="email">Work Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={credentials.email}
            onChange={handleChange}
            placeholder="elon@spacex.com"
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password</label>
          <div className="password-input">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              placeholder="Password"
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="password-toggle"
            >
              {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
        </div>
        <button type="submit" className="submit-button">Log in</button>
        <p className="form-link">
          No account yet? <Link to="/register">Create an account for free</Link>
        </p>
        <div className="policies">
          <a href="https://www.notion.so/Privacy-policy-Calk-11240873dc9b81c2ad8cfe58677951cb?pvs=4" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
          <a href="https://calk-ai.notion.site/Terms-of-Use-11440873dc9b80a88a2ee14cd872bd7a?pvs=4" rel="noopener noreferrer" target="_blank">Terms of Service</a>
        </div>
      </form>
    </div>
  );
};

export default Login;




