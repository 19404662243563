// FileUploadDropdown.js
import React, { useState, useRef, useEffect } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { AlertDialog, Box, Button, Flex, ScrollArea, Text } from '@radix-ui/themes';
import { FaTimes } from "react-icons/fa";
import axios from 'axios';

const ALL_INTEGRATIONS = [
  { name: 'Google Drive', icon: 'google-drive.png', key: 'google' },
  { name: 'OneDrive', icon: 'onedrive.png', key: 'onedrive' }
];

const FileUploadDropdown = ({ onFileSelect, connectedIntegrations }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showGoogleDrivePopup, setShowGoogleDrivePopup] = useState(false);
  const [showOneDrivePopup, setShowOneDrivePopup] = useState(false);
  const [googleDriveFiles, setGoogleDriveFiles] = useState([]);
  const [oneDriveFiles, setOneDriveFiles] = useState([]);
  const fileInputRef = useRef(null);

  // Excluded MIME types - match Knowledge.js
  const excludedMimeTypes = [
    'application/vnd.google-apps.folder',
    'application/vnd.google-apps.shortcut'
  ];

  const fetchGoogleDriveFiles = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/google/files`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const filteredFiles = response.data.filter(file => 
        !excludedMimeTypes.some(mimeType => file.mimeType.includes(mimeType))
      );
      setGoogleDriveFiles(filteredFiles);
    } catch (error) {
      console.error('Error fetching Google Drive files:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOneDriveFiles = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/onedrive/files`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const filteredFiles = response.data.filter(file => 
        !excludedMimeTypes.some(mimeType => file.file && file.file.mimeType.includes(mimeType))
      );
      setOneDriveFiles(filteredFiles);
    } catch (error) {
      console.error('Error fetching OneDrive files:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleDriveSelect = async (file) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/google/import`,
        { fileId: file.id },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      onFileSelect({
        name: file.name,
        content: response.data.content,
        type: file.mimeType,
        source: 'google-drive'
      });
      setShowGoogleDrivePopup(false);
    } catch (error) {
      console.error('Error importing Google Drive file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOneDriveSelect = async (file) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/onedrive/import`,
        { fileId: file.id },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      onFileSelect({
        name: file.name,
        content: response.data.content,
        type: file.file ? file.file.mimeType : 'application/octet-stream',
        source: 'onedrive'
      });
      setShowOneDrivePopup(false);
    } catch (error) {
      console.error('Error importing OneDrive file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLocalFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const fileContent = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.readAsDataURL(file);
      });

      onFileSelect({
        name: file.name,
        content: fileContent,
        type: file.type,
        source: 'local'
      });
    } catch (error) {
      console.error('Error reading local file:', error);
    }
  };

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button className="action-button">
            📎
          </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="DropdownMenuContent"
            style={{
              backgroundColor: 'white',
              borderRadius: '6px',
              padding: '5px',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
              border: '1px solid rgba(0, 0, 0, 0.1)'
            }}
            sideOffset={5}
          >
            <DropdownMenu.Item 
              className="DropdownMenuItem"
              onSelect={() => fileInputRef.current?.click()}
              style={{
                padding: '8px 12px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                cursor: 'pointer'
              }}
            >
              <img src="/images/computer.png" alt="Computer" style={{ width: 20, height: 20 }} />
              Upload from computer
            </DropdownMenu.Item>

            {ALL_INTEGRATIONS.map(integration => (
              connectedIntegrations.includes(integration.key) && (
                <DropdownMenu.Item
                  key={integration.key}
                  className="DropdownMenuItem"
                  onSelect={() => {
                    if (integration.key === 'google') {
                      setShowGoogleDrivePopup(true);
                      fetchGoogleDriveFiles();
                    } else if (integration.key === 'onedrive') {
                      setShowOneDrivePopup(true);
                      fetchOneDriveFiles();
                    }
                  }}
                  style={{
                    padding: '8px 12px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    cursor: 'pointer'
                  }}
                >
                  <img 
                    src={`/images/${integration.icon}`} 
                    alt={integration.name} 
                    style={{ width: 20, height: 20 }} 
                  />
                  Add from {integration.name}
                </DropdownMenu.Item>
              )
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleLocalFileSelect}
        multiple
      />

      {/* Google Drive Dialog */}
      {showGoogleDrivePopup && (
        <AlertDialog.Root defaultOpen>
          <AlertDialog.Content className="popup-modal">
            <Flex align="center" gap="2" mb="4">
              <img src="/images/google-drive.png" alt="Google Drive" className="integration-icon-small" />
              <AlertDialog.Title>Your Google Drive Files</AlertDialog.Title>
              <Box ml="auto">
                <Button variant="ghost" onClick={() => setShowGoogleDrivePopup(false)}>
                  <FaTimes />
                </Button>
              </Box>
            </Flex>
            <ScrollArea className="files-scrollarea">
              {googleDriveFiles.map(file => (
                <Flex key={file.id} justify="between" align="center" p="2">
                  <Text>{file.name}</Text>
                  <Button onClick={() => handleGoogleDriveSelect(file)} size="2">
                    Select
                  </Button>
                </Flex>
              ))}
            </ScrollArea>
          </AlertDialog.Content>
        </AlertDialog.Root>
      )}

      {/* OneDrive Dialog */}
      {showOneDrivePopup && (
        <AlertDialog.Root defaultOpen>
          <AlertDialog.Content className="popup-modal">
            <Flex align="center" gap="2" mb="4">
              <img src="/images/onedrive.png" alt="OneDrive" className="integration-icon-small" />
              <AlertDialog.Title>Your OneDrive Files</AlertDialog.Title>
              <Box ml="auto">
                <Button variant="ghost" onClick={() => setShowOneDrivePopup(false)}>
                  <FaTimes />
                </Button>
              </Box>
            </Flex>
            <ScrollArea className="files-scrollarea">
              {oneDriveFiles.map(file => (
                <Flex key={file.id} justify="between" align="center" p="2">
                  <Text>{file.name}</Text>
                  <Button onClick={() => handleOneDriveSelect(file)} size="2">
                    Select
                  </Button>
                </Flex>
              ))}
            </ScrollArea>
          </AlertDialog.Content>
        </AlertDialog.Root>
      )}
    </>
  );
};

export default FileUploadDropdown;