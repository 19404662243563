"use client";

import { 
    Bar, 
    BarChart as BarChartPrimitive, 
    Line, 
    LineChart as LineChartPrimitive, 
    ResponsiveContainer as ResponsiveContainerPrimitive, 
    XAxis, 
    YAxis, 
    Tooltip 
} from "recharts";

// Export primitive components
export const BarChart = BarChartPrimitive;
export const LineChart = LineChartPrimitive;
export const ResponsiveContainer = ResponsiveContainerPrimitive;

// Export other components
export { Bar, Line, XAxis, YAxis, Tooltip };