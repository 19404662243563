// EntityTabs.jsx
import React from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { Button, Card } from '@radix-ui/themes';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import ModelInfoHoverCard from './ModelInfoHoverCard';
import '../styles/HomeChat.css';

export const EntityTabs = ({
  currentMode,
  setCurrentMode,
  models,
  agents,
  handleCardClick,
  handleCreateAgent
}) => {
  return (
    <Tabs.Root 
      defaultValue="models" 
      onValueChange={value => setCurrentMode(value)}
      className="tabs-root"
    >
      <Tabs.List className="tabs-list-home">
        <Tabs.Trigger value="models" className="tab-trigger">Models</Tabs.Trigger>
        <Tabs.Trigger value="agents" className="tab-trigger">Agents</Tabs.Trigger>
        <Button onClick={handleCreateAgent} className="tab-trigger create-button-home">
          Create Agent +
        </Button>
      </Tabs.List>

      <ScrollArea className="tabs-content">
        <Tabs.Content value="models" className="tab-content">
          <ModelCards models={models} handleCardClick={handleCardClick} />
        </Tabs.Content>

        <Tabs.Content value="agents" className="tab-content">
          <AgentCards agents={agents} handleCardClick={handleCardClick} />
        </Tabs.Content>
      </ScrollArea>
    </Tabs.Root>
  );
};

// ModelCards.jsx
export const ModelCards = ({ models, handleCardClick }) => {
  return (
    <div className="cards-grid">
      {models.map(model => (
        <Card 
          key={model._id} 
          className="entity-card"
          onClick={() => handleCardClick(model)}
        >
          <div className="entity-header">
            <div className="entity-info">
              <img src={`/images/all_icons/${model.icon}`} alt={model.name} />
              <h3>{model.name}</h3>
            </div>
            <div onClick={(e) => e.stopPropagation()}>
              <ModelInfoHoverCard model={model} />
            </div>
          </div>
          <p>{model.description}</p>
        </Card>
      ))}
    </div>
  );
};

// AgentCards.jsx
export const AgentCards = ({ agents, handleCardClick }) => {
  return (
    <div className="cards-grid">
      {[...agents].sort((a, b) => {
        if (a.name === 'Calk') return -1;
        if (b.name === 'Calk') return 1;
        if (a.name === 'helper') return -1;
        if (b.name === 'helper') return 1;
        return 0;
      }).map(agent => (
        <Card 
          key={agent._id} 
          className="entity-card"
          onClick={() => handleCardClick(agent)}
        >
          <div className="entity-header">
            <div className="entity-info">
              <img src={`/images/all_icons/${agent.icon}`} alt={agent.name} />
              <h3>{agent.name}</h3>
            </div>
          </div>
          <p>{agent.miniDescription}</p>
        </Card>
      ))}
    </div>
  );
};