import React, { useState, useEffect } from 'react';
import { AlertDialog, Flex, Text, Checkbox, Select } from '@radix-ui/themes';
import { FaTimes, FaFolder, FaFileAlt } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import '../styles/DrivePopup.css';

// Cache configuration
const DRIVE_CACHE_KEY = 'drive_files_cache';
const DRIVE_CACHE_DURATION = 30 * 60 * 1000; // 15 minutes
const AUTO_REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes

const FileList = ({ files, selectedItems, onItemSelect, onStatusChange, integration }) => {
  const [expandedFolders, setExpandedFolders] = useState({});
  const [fileStructure, setFileStructure] = useState({ rootFiles: [], folderContents: {} });

  useEffect(() => {
    organizeFiles(files);
  }, [files]);

  const organizeFiles = (files) => {
    const structure = {
      rootFiles: [],
      folderContents: {}
    };

    // First, identify all folders and create containers for their contents
    files.forEach(file => {
      if (file.mimeType === 'application/vnd.google-apps.folder') {
        structure.folderContents[file.id] = [];
      }
    });

    // Then, organize files into their respective folders or root
    files.forEach(file => {
      if (file.parents && file.parents.length > 0) {
        const parentId = file.parents[0];
        if (structure.folderContents[parentId]) {
          structure.folderContents[parentId].push(file);
        } else {
          structure.rootFiles.push(file);
        }
      } else {
        structure.rootFiles.push(file);
      }
    });

    setFileStructure(structure);
  };

  const toggleFolder = (folderId) => {
    setExpandedFolders(prev => ({
      ...prev,
      [folderId]: !prev[folderId]
    }));
  };

  const handleFolderSelect = (folder) => {
    onItemSelect(folder);
    
    // If folder is being selected, also select all its contents
    const contents = fileStructure.folderContents[folder.id] || [];
    if (!selectedItems.some(item => item.id === folder.id)) {
      contents.forEach(file => {
        if (!selectedItems.some(item => item.id === file.id)) {
          onItemSelect(file);
        }
      });
    }
  };

  const renderFile = (file) => {
    const isFolder = file.mimeType === 'application/vnd.google-apps.folder';
    const isExpanded = expandedFolders[file.id];
    const folderContents = isFolder ? fileStructure.folderContents[file.id] || [] : [];
    const isSelected = selectedItems.some(item => item.id === file.id);

    return (
      <div key={file.id} className="drive-file-entry">
        <div className="drive-file-item">
          <Flex align="center" gap="2">
            <Checkbox 
              checked={isSelected}
              onCheckedChange={() => isFolder ? handleFolderSelect(file) : onItemSelect(file)}
            />
            {isFolder && (
              <button 
                className="drive-folder-button"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFolder(file.id);
                }}
              >
                {isExpanded ? <IoIosArrowDown /> : <IoIosArrowForward />}
              </button>
            )}
            {isFolder ? (
              <FaFolder className="drive-folder-icon" />
            ) : (
              <FaFileAlt className="drive-file-icon" />
            )}
            <span className="drive-file-name">{file.name}</span>
          </Flex>
          
          {isFolder && (
            <span className="drive-folder-count">
              {folderContents.length} items
            </span>
          )}
          {isSelected && (
            <Select.Root 
              defaultValue="Personal"
              onValueChange={(value) => onStatusChange(file.id, value)}
            >
              <Select.Trigger />
              <Select.Content>
                <Select.Item value="Personal">Personal</Select.Item>
                <Select.Item value="Public">Public</Select.Item>
              </Select.Content>
            </Select.Root>
          )}
        </div>
        
        {isFolder && isExpanded && (
          <div className="drive-folder-contents">
            {folderContents.map(childFile => renderFile(childFile))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="drive-file-list">
      {fileStructure.rootFiles.map(file => renderFile(file))}
    </div>
  );
};//

const DrivePopup = ({ 
  isOpen, 
  onClose, 
  files: propFiles, 
  selectedItems,
  onItemSelect,
  onStatusChange,
  onSave,
  integration,
  isLoading: propsIsLoading,
}) => {
  const [cachedFiles, setCachedFiles] = useState([]);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);

  const getCachedFiles = () => {
    const cached = localStorage.getItem(DRIVE_CACHE_KEY);
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp < DRIVE_CACHE_DURATION) {
        return data;
      }
    }
    return null;
  };

  const setCacheData = (data) => {
    localStorage.setItem(DRIVE_CACHE_KEY, JSON.stringify({
      data,
      timestamp: Date.now()
    }));
    setCachedFiles(data);
  };

  // Pre-fetch files when component mounts
  useEffect(() => {
    const fetchFiles = async () => {
      // First check cache
      const cached = getCachedFiles();
      if (cached) {
        setCachedFiles(cached);
        return;
      }

      setIsLoadingFiles(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/drive/files`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();
        setCacheData(data); // Use the combined function instead of duplicate calls
      } catch (error) {
        console.error('Error prefetching drive files:', error);
      } finally {
        setIsLoadingFiles(false);
      }
    };

    // Set up auto-refresh interval
    const intervalId = setInterval(() => {
      if (isOpen) {
        localStorage.removeItem(DRIVE_CACHE_KEY);
        fetchFiles();
      }
    }, AUTO_REFRESH_INTERVAL);

    // Only fetch if the popup is open
    if (isOpen) {
      fetchFiles();
    }

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);    
  }, [isOpen]); // Add isOpen to dependencies

  // Use cached files if available, otherwise use props
  const displayFiles = cachedFiles.length > 0 ? cachedFiles : propFiles;
  const isLoading = propsIsLoading || isLoadingFiles;

  return (
    <AlertDialog.Root open={isOpen}>
      <AlertDialog.Content className="drive-popup-modal">
        <div className="drive-popup-header">
          <Flex align="center" justify="between">
            <Flex align="center" gap="2">
              <img 
                src={`/images/google-drive.png`} 
                alt={integration} 
                className="integration-icon" 
              />
              <Text className="drive-popup-title">
                Save Google Drive References
              </Text>
            </Flex>
            <button className="drive-popup-close" onClick={onClose}>
              <FaTimes />
            </button>
          </Flex>
        </div>

        <div className="drive-popup-content">
          {isLoading ? (
            <div className="drive-analyzing-container">
              <video autoPlay loop muted playsInline className="drive-loader-video">
                <source src="/loader.mp4" type="video/mp4" />
              </video>
            </div>
          ) : (
            <FileList 
              files={displayFiles}
              selectedItems={selectedItems}
              onItemSelect={onItemSelect}
              onStatusChange={onStatusChange}
              integration={integration}
            />
          )}
        </div>

        <div className="drive-popup-footer">
          <span className="drive-selected-count">
            Selected: {selectedItems.length} items
          </span>
          <button 
            className="drive-continue-button"
            disabled={selectedItems.length === 0}
            onClick={onSave}
          >
            Save References
          </button>
        </div>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};

export default DrivePopup;