import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import * as Slider from '@radix-ui/react-slider';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Checkbox from '@radix-ui/react-checkbox';
import * as Progress from '@radix-ui/react-progress';
import { Check, Upload, Trash2 } from 'lucide-react';
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoIosInformationCircleOutline } from "react-icons/io";
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Callout, Button, ScrollArea, Flex, TextField, Switch } from '@radix-ui/themes';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import axios from 'axios';
import { FiChevronUp } from "react-icons/fi";
import '../styles/CreateAgentModal.css';
import CategorizedIconDropdown from '../components/CategorizedIconDropdown';
import Swal from 'sweetalert2'
import AgentDrivePopup from '../components/AgentDrivePopup';
import NotionPopup from '../components/NotionPopup';
import FileBrowser from '../components/FileBrowser';
import * as HoverCard from '@radix-ui/react-hover-card';
import CreateAgentChat from '../components/CreateAgentChat';
import { GoPeople } from "react-icons/go";
import { 
  PersonIcon, 
  TargetIcon,
  FileTextIcon,
  MixerHorizontalIcon,
  LightningBoltIcon,
  ListBulletIcon,
  MagnifyingGlassIcon,
  ChatBubbleIcon,
  StackIcon,
  SlashIcon,
  BookmarkIcon
} from '@radix-ui/react-icons';

const TAB_NAMES = ['Identity', 'Mission', 'Knowledge and tools'];
const AGENT_TAGS = ['Business', 'Marketing', 'Productivity', 'Sales', 'Dev', 'Data', 'HR', 'Miscellaneous'];

const CreateAgentModal = ({ onCreateAgent }) => {
  const navigate = useNavigate();
  const topRef = useRef(null);
  const [currentTab, setCurrentTab] = useState('identity');
  const [name, setName] = useState('');
  const [miniDescription, setMiniDescription] = useState('');
  const [description, setDescription] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [aiModel, setAiModel] = useState('');
  const [availableModels, setAvailableModels] = useState([]);
  const [mood, setMood] = useState(50);
  const [files, setFiles] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [availableIcons, setAvailableIcons] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState('');
  const [isIconDropdownOpen, setIsIconDropdownOpen] = useState(false);
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileProgress, setFileProgress] = useState({});
  const [overallProgress, setOverallProgress] = useState(0);
  const [showCompletionDialog, setShowCompletionDialog] = useState(false);
  const [existingDocuments, setExistingDocuments] = useState([]);
  const [showDrivePopup, setShowDrivePopup] = useState(false);
  const [showNotionPopup, setShowNotionPopup] = useState(false);
  const [savedDriveRefs, setSavedDriveRefs] = useState([]);
  const [selectedDriveRefs, setSelectedDriveRefs] = useState([]);
  const [savedNotionRefs, setSavedNotionRefs] = useState([]);
  const [showDriveRefsPopup, setShowDriveRefsPopup] = useState(false);
  const [showSectionsDropdown, setShowSectionsDropdown] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const textareaRef = useRef(null);
  const dropdownRef = useRef(null);
  const [urlInput, setUrlInput] = useState('');
  const [isScrapingUrl, setIsScrapingUrl] = useState(false);
  const [tools, setTools] = useState({
      webSearch: false
  });
  const [validationErrors, setValidationErrors] = useState({
    name: false,
    miniDescription: false,
    aiModel: false,
    tags: false
  });

  const promptSections = [
    { icon: PersonIcon, name: "Role", description: "Define the Agent persona, its expertise", info: "Example:\nYou are an expert in digital marketing with a focus on social media strategy and SEO optimization." },
    { icon: TargetIcon, name: "Goal", description: "Specify the main goal of the tasks", info: "Example:\nThe goal is to create a detailed marketing plan for a product launch targeting Gen Z" },
    { icon: FileTextIcon, name: "Context", description: "Provide background information", info: "Example:\nThe client operates a small e-commerce business selling eco-friendly products and is planning a Black Friday sale." },
    { icon: MixerHorizontalIcon, name: "Format", description: "Specify specific output format, struture", info: "Example:\nOutput the response as a bulleted list, each point being no more than 20 words." },
    { icon: LightningBoltIcon, name: "Example", description: "Provide what a good output look like", info: "Example:\nA good example of a marketing email: “Subject: [Catchy Title]. Body: Short description + call-to-action.”" },
    { icon: GoPeople, name: "Audience", description: "Describe who will read the output", info: "Example:\nThe audience is mid-level managers in the tech industry who are familiar with agile methodologies." },
    { icon: ListBulletIcon, name: "Step by step Instructions", description: "Guide the agent to do a task step by step", info: "Example:\nYou are an expert in digital marketing with a focus on social media strategy and SEO optimization." },
    { icon: MagnifyingGlassIcon, name: "Reasoning Approach", description: "Provide a logical way to do the tasks", info: "Example:\nStep 1: Identify the main goals. Step 2: Research the audience. Step 3: Write a draft and review." },
    { icon: ChatBubbleIcon, name: "Tone & Style", description: "Set the tone & style for the output", info: "Example:\nThe tone should be concise and formal, with no slang, but approachable for non-technical stakeholders." },
    { icon: StackIcon, name: "Prioritization", description: "Tell your agent what to prioritize", info: "Example:\nFocus first on clarity and then on creativity." },
    { icon: SlashIcon, name: "Out of scope", description: "Provide the limits of the scope", info: "Example:\nAvoid discussing competitor brands or recommending specific tools in the final report." },
    { icon: BookmarkIcon, name: "Resources", description: "Mention any knowledge that can be useful", info: "Example:\nUse the attached PDF report and the provided link to industry benchmarks." }
  ];

  const getAvailableIcons = () => {
    const iconContext = require.context('/public/images/all_icons/', false, /\.png$/);
    return iconContext.keys().map(path => path.replace('./', ''));
  };

  useEffect(() => {
    fetchExistingDocuments();
  }, []);

  useEffect(() => {
    const fetchSavedRefs = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/google/saved-references`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        setSavedDriveRefs(response.data);
      } catch (error) {
        console.error('Error fetching saved references:', error);
      }
    };

    fetchSavedRefs();
  }, []);

  useEffect(() => {
    const fetchSavedRefs = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/notion/saved-references`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        setSavedNotionRefs(response.data);
      } catch (error) {
        console.error('Error fetching saved Notion references:', error);
      }
    };

    fetchSavedRefs();
  }, []);

  useEffect(() => {
    fetchAvailableModels();
  }, []);

  useEffect(() => {
    const icons = getAvailableIcons();
    setAvailableIcons(icons);
    setSelectedIcon(icons[0]); // Set first icon as default
  }, []);

  const handleAddSection = () => {
    const textarea = textareaRef.current;
    const cursorPosition = textarea.selectionStart;
    const textBeforeCursor = textarea.value.substring(0, cursorPosition);
    const textAfterCursor = textarea.value.substring(cursorPosition);
    
    const newValue = textBeforeCursor + '#' + textAfterCursor;
    setDescription(newValue);
    
    // Simulate an input event
    const event = {
      target: {
        value: newValue,
        selectionStart: cursorPosition + 1
      }
    };
    
    handleTextareaChange(event);
    
    // Set focus back to textarea and move cursor after the inserted '#'
    textarea.focus();
    textarea.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
  };

  const handleTextareaChange = (e) => {
    const newValue = e.target.value;
    setDescription(newValue);
    
    const lastChar = newValue[e.target.selectionStart - 1];
    if (lastChar === '#') {
      const textarea = textareaRef.current;
      // Get caret coordinates
      // const caretPosition = getCaretCoordinates(textarea);
      // const dropdownHeight = 480;
      const caretPosition = getCaretCoordinates(textarea, e.target.selectionStart - 1);
      
      if (dropdownRef.current) {
        const rect = textarea.getBoundingClientRect();
        dropdownRef.current.style.position = 'absolute';
        dropdownRef.current.style.top = `${caretPosition.top + 24}px`;
        dropdownRef.current.style.left = `${caretPosition.left}px`;
      }
      
      setShowSectionsDropdown(true);
      setSelectedIndex(0);
    } else {
      setShowSectionsDropdown(false);
    }
  };

  // Add this helper function
  const getCaretCoordinates = (textarea) => {
    const position = textarea.selectionStart;
    
    // Create a copy of the textarea's content up to the caret
    const front = textarea.value.substring(0, position);
    
    // Create a hidden div with same styling as textarea
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.top = '0';
    div.style.left = '0';
    div.style.visibility = 'hidden';
    div.style.whiteSpace = 'pre-wrap';
    div.style.height = 'auto';
    div.style.width = textarea.offsetWidth + 'px';
    div.style.fontSize = window.getComputedStyle(textarea).fontSize;
    div.style.fontFamily = window.getComputedStyle(textarea).fontFamily;
    div.style.lineHeight = window.getComputedStyle(textarea).lineHeight;
    div.style.padding = window.getComputedStyle(textarea).padding;
    
    // Create a span for the content before caret
    const textContent = document.createTextNode(front);
    const span = document.createElement('span');
    span.appendChild(textContent);
    div.appendChild(span);
    
    document.body.appendChild(div);
    const rect = textarea.getBoundingClientRect();
    const spanRect = span.getBoundingClientRect();
    
    document.body.removeChild(div);
    
    return {
      top: spanRect.top - rect.top,
      left: spanRect.left - rect.left
    };
  };

  const handleKeyDown = (e) => {
    if (!showSectionsDropdown) return;

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => {
          const newIndex = prev > 0 ? prev - 1 : promptSections.length - 1;
          // Scroll into view
          const selectedItem = document.querySelector(`.agent-section-item:nth-child(${newIndex + 2})`); // +2 because of header
          if (selectedItem && dropdownRef.current) {
            selectedItem.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
          }
          return newIndex;
        });
        break;
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => {
          const newIndex = prev < promptSections.length - 1 ? prev + 1 : 0;
          // Scroll into view
          const selectedItem = document.querySelector(`.agent-section-item:nth-child(${newIndex + 2})`); // +2 because of header
          if (selectedItem && dropdownRef.current) {
            selectedItem.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
          }
          return newIndex;
        });
        break;
      case 'Tab':
      case 'Enter':
        e.preventDefault();
        insertSection(promptSections[selectedIndex].name);
        break;
      case 'Escape':
        e.preventDefault();
        setShowSectionsDropdown(false);
        break;
      default:
        break;
    }
  };

  const insertSection = (sectionName) => {
    if (!textareaRef.current) return;
    const textarea = textareaRef.current;
    const text = textarea.value;
    const cursorPos = textarea.selectionStart;
    
    const lastHashIndex = text.lastIndexOf('#', cursorPos - 1);
    if (lastHashIndex === -1) return;
    
    const beforeHash = text.substring(0, lastHashIndex);
    const afterCursor = text.substring(cursorPos);
    const newText = `${beforeHash}# ${sectionName}\n${afterCursor}`;
    
    setDescription(newText);
    setShowSectionsDropdown(false);
    
    textarea.focus();
    const newCursorPosition = lastHashIndex + sectionName.length + 4;
    setTimeout(() => {
      textarea.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };


  const handleSelectDriveRef = (ref) => {
    setSelectedDriveRefs(prev => {
      const isSelected = prev.some(r => r._id === ref._id);
      if (isSelected) {
        return prev.filter(r => r._id !== ref._id);
      }
      return [...prev, ref];
    });
  };

  const handleAddDriveFile = (file) => {
    if (file.type === 'folder') {
      // Recursively add all files in folder
      const addFilesInFolder = (folder) => {
        folder.children.forEach(child => {
          if (child.type === 'folder') {
            addFilesInFolder(child);
          } else {
            handleAddExistingFile({
              ...child,
              sourceDocId: child.id
            });
          }
        });
      };
      addFilesInFolder(file);
    } else {
      handleAddExistingFile({
        ...file,
        sourceDocId: file.id
      });
    }
  };

  const handleAddNotionFile = (file) => {
    handleAddExistingFile({
      ...file,
      sourceDocId: file.id
    });
  };

  const validateIdentityTab = () => {
    const errors = {
      name: !name.trim(),
      miniDescription: !miniDescription.trim(),
      aiModel: !aiModel,
      tags: selectedTags.length === 0
    };

    setValidationErrors(errors);
    return !Object.values(errors).some(error => error);
  };

  const resetForm = () => {
    setCurrentTab('identity');
    setName('');
    setMiniDescription('');
    setDescription('');
    setSelectedTags([]);
    setAiModel('');
    setMood(75);
    setFiles([]);
    setIsDropdownOpen(false);
  };

  const handleBackReal = () => {
    resetForm();
    navigate('/agents');
  };

  const handleBack = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "All changes will be lost",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, leave page',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        resetForm();
        navigate('/agents');
      }
    });
  };

  const fetchExistingDocuments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/documents`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setExistingDocuments(response.data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  const handleUrlAdd = async () => {
    if (!urlInput.trim()) return;
    
    setIsScrapingUrl(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/agents/scrape-url`,
        { url: urlInput },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const scrapedContent = response.data;
      const fileName = `${new URL(urlInput).hostname}_scraped.txt`;
      
      // Create a file-like object with the scraped content
      const file = new File(
        [new Blob([scrapedContent], { type: 'text/plain' })],
        fileName,
        { type: 'text/plain' }
      );

      // Add metadata for tracking the source
      file.isScrapedContent = true;
      file.sourceUrl = urlInput;

      // Initialize progress for this file
      setFileProgress(prev => ({
        ...prev,
        [fileName]: 0
      }));

      // Add to files list
      setFiles(prev => [...prev, file]);

      // Set progress to complete
      setFileProgress(prev => ({
        ...prev,
        [fileName]: 100
      }));

      setUrlInput('');

      // Show success message
      Swal.fire({
        position: "bottom-end",
        icon: "success",
        title: "URL content added successfully",
        showConfirmButton: false,
        timer: 1500,
        width: 300,
      });

    } catch (error) {
      console.error('Error scraping URL:', error);
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Error scraping URL",
        text: "Website blocks scraping. Too bad.",
        showConfirmButton: false,
        timer: 3000,
        width: 300,
      });
    } finally {
      setIsScrapingUrl(false);
    }
  };


  const handleAddExistingFile = async (file) => {
    try {
      // Handle Notion pages/databases
      if (file.pageId) {
        const notionFile = {
          name: file.name,
          pageId: file.pageId,
          type: file.type,
          path: file.path,
          isNotionFile: true
        };
        setFileProgress(prev => ({
          ...prev,
          [file.name]: 0
        }));
        
        setFiles(prev => {
          if (prev.some(f => f.pageId === file.pageId)) {
            console.log('File already added:', file.name);
            return prev;
          }
          return [...prev, notionFile];
        });
      // Handle Google Drive files
      } else if (file.driveId) {
        if (file.type === 'folder') {
          // Recursively get all non-folder files from the folder
          const getAllNestedFiles = (folder) => {
            const files = [];
            const processItem = (item) => {
              if (item.type !== 'folder') {
                files.push({
                  name: item.name,
                  driveId: item.driveId,
                  type: item.type,
                  path: item.path,
                  isGoogleDriveFile: true
                });
              }
              if (item.children && item.children.length > 0) {
                item.children.forEach(processItem);
              }
            };
            processItem(folder);
            return files;
          };

          const nestedFiles = getAllNestedFiles(file);
          
          // Add all nested files to state
          nestedFiles.forEach(nestedFile => {
            setFileProgress(prev => ({
              ...prev,
              [nestedFile.name]: 0
            }));
            
            setFiles(prev => {
              if (prev.some(f => f.driveId === nestedFile.driveId)) {
                console.log('File already added:', nestedFile.name);
                return prev;
              }
              return [...prev, nestedFile];
            });

            // setFileProgress(prev => ({
            //   ...prev,
            //   [nestedFile.name]: 100
            // }));
          });
        } else {
          // Handle single file
          const driveFile = {
            name: file.name,
            driveId: file.driveId,
            type: file.type,
            path: file.path,
            isGoogleDriveFile: true
          };
          setFileProgress(prev => ({
            ...prev,
            [file.name]: 0
          }));
          
          setFiles(prev => {
            if (prev.some(f => f.driveId === file.driveId)) {
              console.log('File already added:', file.name);
              return prev;
            }
            return [...prev, driveFile];
          });

          // setFileProgress(prev => ({
          //   ...prev,
          //   [file.name]: 100
          // }));
        }
      } else {
        // Handle regular existing documents
        const localFile = {
          name: file.name,
          sourceDocId: file._id,
          isExistingDocument: true
        };
        
        setFileProgress(prev => ({
          ...prev,
          [file.name]: 0
        }));
        
        setFiles(prev => {
          if (prev.some(f => f.sourceDocId === file._id)) {
            console.log('File already added:', file.name);
            return prev;
          }
          return [...prev, localFile];
        });

        setFileProgress(prev => ({
          ...prev,
          [file.name]: 100
        }));
      }
    } catch (error) {
      console.error('Error adding file:', error);
    }
  };

  const handleRemoveFile = (fileToRemove, index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setFileProgress(prev => {
      const newProgress = { ...prev };
      delete newProgress[fileToRemove.name];
      return newProgress;
    });
  };


  const fetchAvailableModels = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/chatmodels`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      // Filter out Multi Modal as per the current implementation
      const filteredModels = data.filter(model => model.name !== 'Multi Modal');
      setAvailableModels(filteredModels);
    } catch (error) {
      console.error('Error fetching available models:', error);
    }
  };

  const getModelIcon = (modelName) => {
    const modelsIcons = {
      'GPT4': '/images/all_icons/gpt.png',
      'GPT4o': '/images/all_icons/gpt.png',
      'GPT4oMini': '/images/all_icons/gpt.png',
      'Claude3.5': '/images/all_icons/claude.png',
      'ClaudeSonnet': '/images/all_icons/claude.png',
      'Claude Opus': '/images/all_icons/claude.png',
      'Claude Haiku': '/images/all_icons/claude.png',
      'Mistral AI Large': '/images/all_icons/mistral.png',
      'Mistral AI Medium': '/images/all_icons/mistral.png',
      'Mistral AI Small': '/images/all_icons/mistral.png',
      'Codestral': '/images/all_icons/mistral.png',
      'Llama3.1': '/images/all_icons/llama.png',
      'Gemini1.5': '/images/all_icons/gemini.png',
      'Cohere': '/images/all_icons/cohere.png',
      'Perplexity': '/images/all_icons/perplexity.png',
      'Stable Diffusion': '/images/all_icons/stablediffusion.png',
      'DALL-E 3': '/images/all_icons/gpt.png',
    };
    
    const normalizedModelName = modelName.replace(/\s+/g, '').toLowerCase();
    const iconPath = Object.entries(modelsIcons).find(([key]) => 
      key.replace(/\s+/g, '').toLowerCase() === normalizedModelName
    );
    
    return iconPath ? iconPath[1] : '/images/default.png';
  };

  const getModelEndpoint = (model) => {
    switch (model.toLowerCase()) {
      case 'gpt 4o':
        return 'gpt4o';
      case 'gpt 4o mini':
        return 'gpt4omini';
      case 'gpt 4':
        return 'gpt4';
      case 'claude 3.5':
        return 'claude3.5';
      case 'claude sonnet':
        return 'claudesonnet';
      case 'claude opus':
        return 'claude-opus';
      case 'claude haiku':
        return 'claude-haiku';
      case 'mistral ai large':
        return 'mistral-large';
      case 'mistral ai medium':
        return 'mistral-medium';
      case 'mistral ai small':
        return 'mistral-small';
      case 'codestral':
        return 'codestral';
      case 'llama 3.1':
        return 'llama3.1';
      case 'gemini 2':
        return 'gemini1.5';
      case 'cohere':
        return 'cohere';      
      case 'perplexity':
        return 'perplexity';
      case 'stable diffusion':
      case 'stablediffusion':
        return 'stablediffusion';
      default:
        return model.toLowerCase().replace(' ', '');
    }
  };

  // const handleNext = () => {
  //   setCurrentTab(prev => prev + 1);
  // };

  const handleNext = () => {
    if (currentTab === 'identity') {
      if (!validateIdentityTab()) {
        return;
      }
    }
    setCurrentTab(prev => {
      if (prev === 'identity') return 'mission';
      if (prev === 'mission') return 'knowledge';
      return prev;
    });
  };

  const goUpPage = () => {
    topRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const handleEnhancePrompt = async () => {
    setIsEnhancing(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/agents/generate-description`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ userDescription: description })
      });
      const data = await response.json();
      setDescription(data.generatedDescription);
    } catch (error) {
      console.error('Error enhancing prompt:', error);
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Oops...",
        text: "Error improving prompt. Please try again.",
        showConfirmButton: false,
        timer: 3000,
        width: 300,
      });
    } finally {
      setIsEnhancing(false);
    }
  };

  // Similarly update handleFileDrop
  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    const initialProgress = {};
    droppedFiles.forEach(file => {
      initialProgress[file.name] = 0;
    });
    setFileProgress(prev => ({...prev, ...initialProgress}));
    setFiles([...files, ...droppedFiles]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // const handleFileSelect = (e) => {
  //   const selectedFiles = Array.from(e.target.files);
  //   setFiles([...files, ...selectedFiles]);
  // };

  // Update handleFileSelect to initialize progress
  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    // Initialize progress for each file
    const initialProgress = {};
    selectedFiles.forEach(file => {
      initialProgress[file.name] = 0;
    });
    setFileProgress(prev => ({...prev, ...initialProgress}));
    setFiles([...files, ...selectedFiles]);
  };

  const estimateProcessingTime = (file) => {
    const sizeInMB = file.size / (1024 * 1024);
    const fileType = file.type.toLowerCase();
    
    // Base time includes reading file and initial processing
    let baseTime = 2000; // 2 seconds base time
    
    // Adjust extraction time multipliers
    if (fileType.includes('pdf')) {
      baseTime += sizeInMB * 5000; // 5 seconds per MB for PDFs
    } else if (fileType.includes('spreadsheet') || fileType.includes('excel')) {
      baseTime += sizeInMB * 4000; // 4 seconds per MB for spreadsheets
    } else if (fileType.includes('presentation')) {
      baseTime += sizeInMB * 4500; // 4.5 seconds per MB for presentations
    } else if (fileType.includes('document')) {
      baseTime += sizeInMB * 4000; // 4 seconds per MB for documents
    } else {
      baseTime += sizeInMB * 3000; // 3 seconds per MB for other files
    }
    
    // Adjust chunk processing time
    const estimatedChunks = Math.ceil((sizeInMB * 1024 * 1024) / 500);
    const chunkProcessingTime = estimatedChunks * 3000; // 3 seconds per chunk
    
    return Math.ceil(baseTime + chunkProcessingTime);
  };

  const updateFileProgress = async (file, agentId) => {
    setFileProgress(prev => ({
      ...prev,
      [file.name]: 0
    }));

    const formData = new FormData();
    formData.append('files', file);
    formData.append('topics', `Topic ${file.name}`);

    const updateProgressInterval = setInterval(() => {
      setFileProgress(prev => {
        const currentProgress = prev[file.name] || 0;
        if (currentProgress < 90) {
          return {
            ...prev,
            [file.name]: currentProgress + 1
          };
        }
        return prev;
      });
    }, 100);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/agents/${agentId}/add-knowledge`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      clearInterval(updateProgressInterval);
      setFileProgress(prev => ({
        ...prev,
        [file.name]: 100
      }));

      return response;
    } catch (error) {
      clearInterval(updateProgressInterval);
      setFileProgress(prev => ({
        ...prev,
        [file.name]: -1
      }));
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsCreating(true);
    setOverallProgress(0);

    try {
      const companyId = localStorage.getItem('companyId');
      const agentData = {
        name,
        description,
        miniDescription,
        specialization: selectedTags,
        icon: selectedIcon,
        aiModel: getModelEndpoint(aiModel),
        mood: mood / 100,
        tools,
        companyId
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/agents/create`,
        agentData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data && response.data._id) {
        const totalFiles = files.length;
        let completedFiles = 0;

        for (const file of files) {
          try {
            if (file.isNotionFile) {
              // Import Notion content
              await axios.post(
                `${process.env.REACT_APP_BACKEND}/api/notion/agent-knowledge/${response.data._id}`,
                { 
                  pageId: file.pageId,
                  name: file.name,
                  type: file.type
                },
                {
                  headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                  }
                }
              );
              completedFiles++;
            } else if (file.isGoogleDriveFile) {
              // Initialize progress
              setFileProgress(prev => ({
                ...prev,
                [file.name]: 0
              }));

              // Start progress simulation
              const progressInterval = setInterval(() => {
                setFileProgress(prev => {
                  const currentProgress = prev[file.name] || 0;
                  if (currentProgress < 90) {
                    return {
                      ...prev,
                      [file.name]: currentProgress + 2
                    };
                  }
                  return prev;
                });
              }, 100);

              try {
                // Import Google Drive file
                const result = await axios.post(
                  `${process.env.REACT_APP_BACKEND}/api/google/agent-knowledge/${response.data._id}`,
                  { 
                    fileId: file.driveId,
                    name: file.name
                  },
                  {
                    headers: {
                      'Authorization': `Bearer ${localStorage.getItem('token')}`,
                      'Content-Type': 'application/json'
                    }
                  }
                );

                // Clear interval and set to 100% if successful
                clearInterval(progressInterval);
                setFileProgress(prev => ({
                  ...prev,
                  [file.name]: 100
                }));

                if (!result.data.addedKnowledge || result.data.addedKnowledge.length === 0) {
                  throw new Error('No knowledge chunks were created');
                }

                completedFiles++;
              } catch (error) {
                // Clear interval and show error state
                clearInterval(progressInterval);
                setFileProgress(prev => ({
                  ...prev,
                  [file.name]: -1
                }));
                throw error;
              }
            } else if (file.isExistingDocument) {
              // Copy existing document knowledge
              await axios.post(
                `${process.env.REACT_APP_BACKEND}/api/agents/${response.data._id}/copy-knowledge`,
                { sourceDocId: file.sourceDocId },
                {
                  headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                  }
                }
              );
              completedFiles++;
            } else {
              // Handle regular file upload
              await updateFileProgress(file, response.data._id);
              completedFiles++;
            }
            setOverallProgress((completedFiles / totalFiles) * 100);
          } catch (error) {
            console.error(`Failed to process file ${file.name}:`, error);
            Swal.fire({
              position: "bottom-end",
              icon: "error",
              title: "Oops...",
              text: `Failed to process file ${file.name}.`,
              showConfirmButton: false,
              timer: 1500,
              width: 300
            });
          }
        }

        setOverallProgress(100);
        setTimeout(() => {
          handleBackReal();
          Swal.fire({
            position: "bottom-end",
            icon: "success",
            title: `Successfully created ${agentData.name}!`,
            showConfirmButton: false,
            timer: 1500,
            width: 300,
          });
        }, 1000);
      }
    } catch (error) {
      console.error('Error creating agent:', error);
      Swal.fire({
        position: "bottom-end",
        icon: "error",
        title: "Oops...",
        text: `Failed to create the agent!`,
        showConfirmButton: false,
        timer: 1500,
        width: 300,
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
      setIsCreating(false);
    }
  };

  const getMoodEmoji = (value) => {
    switch (value) {
      case 0:
        return '😐'; // Robot/Strict
      case 25:
        return '🤓'; // Analytical
      case 50:
        return '😜'; // Balanced
      case 75:
        return '🤪'; // Creative
      case 100:
        return '🤡'; // Very Creative/Artistic
      default:
        return '😜';
    }
  };

return (
    <div className="create-agent-page">
      <button onClick={handleBack} className="back-button">
        <FaArrowLeftLong size={20} />
      </button>
      <div className="create-agent-content">
        <Tabs.Root value={currentTab}>
          <Tabs.List className="tab-list">
            <Tabs.Trigger value="identity" className="tab-trigger">
              Identity
            </Tabs.Trigger>
            <Tabs.Trigger value="mission" className="tab-trigger">
              Mission
            </Tabs.Trigger>
            <Tabs.Trigger value="knowledge" className="tab-trigger">
              Knowledge & Tools
            </Tabs.Trigger>
          </Tabs.List>

          <Tabs.Content value="identity" className="tab-content">
            {/*<div className="form-group">
              <label>Agent Icon</label>
              <DropdownMenu.Root open={isIconDropdownOpen} onOpenChange={setIsIconDropdownOpen}>
                <DropdownMenu.Trigger className="icon-dropdown-trigger">
                  <img
                    src={`/images/all_icons/${selectedIcon}`}
                    alt={selectedIcon}
                    className="agent-icon"
                  /> 
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                  <DropdownMenu.Content 
                    className="icon-dropdown-content" 
                    side="bottom"
                    sideOffset={5}
                    align="start"
                  >
                  {availableIcons.map((icon) => (
                    <DropdownMenu.Item
                      key={icon}
                      className="icon-item"
                      onSelect={() => {
                        setSelectedIcon(icon);
                        setIsIconDropdownOpen(false);
                      }}
                    >
                      <img
                        src={`/images/all_icons/${icon}`}
                        alt={icon}
                        className="icon-preview"
                      />
                    </DropdownMenu.Item>
                  ))}
                  </DropdownMenu.Content>
                </DropdownMenu.Portal>
              </DropdownMenu.Root>
            </div>*/}
            <div className="form-group">
              <label>Agent Icon</label>
              <CategorizedIconDropdown
                selectedIcon={selectedIcon}
                onIconSelect={(icon) => {
                  setSelectedIcon(icon);
                  setIsIconDropdownOpen(false);
                }}
                isOpen={isIconDropdownOpen}
                onOpenChange={setIsIconDropdownOpen}
                availableIcons={availableIcons}
              />
            </div>

            <div className="form-group">
              <label htmlFor="agentName">Agent Name <span className="required-star">📝</span></label>
              <input
                id="agentName"
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setValidationErrors(prev => ({ ...prev, name: false }));
                }}
                placeholder="Give it a cool and short name to remember it"
                className="text-input"
              />
              {validationErrors.name && (
                <Callout.Root color="red" size="1" className="mt-2">
                  <Callout.Icon>
                    <ExclamationTriangleIcon />
                  </Callout.Icon>
                  <Callout.Text>
                    Oups! You forgot to name the Agent
                  </Callout.Text>
                </Callout.Root>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="miniDescription">Description <span className="required-star">📋</span></label>
              <input
                id="miniDescription"
                type="text"
                value={miniDescription}
                onChange={(e) => {
                  setMiniDescription(e.target.value);
                  setValidationErrors(prev => ({ ...prev, miniDescription: false }));
                }}
                placeholder="Describe it in one sentence"
                className="text-input"
              />
              {validationErrors.miniDescription && (
                <Callout.Root color="red" size="1" className="mt-2">
                  <Callout.Icon>
                    <ExclamationTriangleIcon />
                  </Callout.Icon>
                  <Callout.Text>
                    Oups! You forgot to add a description
                  </Callout.Text>
                </Callout.Root>
              )}
            </div>

            <div className="form-group">
              <label>AI Model 🤖</label>
              <DropdownMenu.Root open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
                <DropdownMenu.Trigger className="model-dropdown-trigger">
                  {aiModel || "Select Model"} <FiChevronUp />
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                  <DropdownMenu.Content 
                    className="model-dropdown-content" 
                    side="top"
                    sideOffset={5}
                    align="center"
                  >
                    {availableModels.map((model) => (
                      <DropdownMenu.Item
                        key={model._id}
                        className="model-item"
                        onSelect={() => {
                          setAiModel(model.name);
                          setIsDropdownOpen(false);
                        }}
                      >

                        <div className="model-info">
                          <div className="model-name"><img src={getModelIcon(model.name)} alt={model.name} className="model-icon" />{model.name}</div>
                          <div className="model-description">{model.miniDescription}</div>
                        </div>
                      </DropdownMenu.Item>
                    ))}
                  </DropdownMenu.Content>
                </DropdownMenu.Portal>
              </DropdownMenu.Root>
              {validationErrors.aiModel && (
                <Callout.Root color="red" size="1" className="mt-2">
                  <Callout.Icon>
                    <ExclamationTriangleIcon />
                  </Callout.Icon>
                  <Callout.Text>
                    Oups! You forgot to select a model
                  </Callout.Text>
                </Callout.Root>
              )}
            </div>

            <div className="form-group">
              <label>Mood of the agent 🌡️</label>
              <div className="mood-slider-container">
                <div className="mood-labels">
                  <span>Serious</span>
                  <span>Super Creative</span>
                </div>
                <div 
                  className="mood-value-tooltip"
                  style={{ 
                    left: `${(mood/100) * 100}%`,
                    transform: 'translateX(-50%)',
                  }}
                >
                  {getMoodEmoji(mood)}
                </div>
                <div className="numeric-labels">
                  <span>0</span>
                  <span>1</span>
                </div>
                <Slider.Root
                  className="slider-root"
                  defaultValue={[50]}
                  max={100}
                  step={25}
                  onValueChange={([value]) => setMood(value)}
                >
                  <Slider.Track className="slider-track">
                    <Slider.Range className="slider-range" />
                  </Slider.Track>
                  <Slider.Thumb className="slider-thumb" />
                </Slider.Root>
                <Callout.Root color="darkblue" size="1">
                  <Callout.Icon>
                    <IoIosInformationCircleOutline />
                  </Callout.Icon>
                  <Callout.Text>
                    Temperature is your Agent's "mood", 0 is all being serious, 100 is wild ideas!
                  </Callout.Text>
                </Callout.Root>
              </div>
            </div>

            <div className="form-group">
              <label>Tag them:</label>
              <div className="tag-help-text">
                Here you can tag the agent to be affiliated to a specific domain of expertise
              </div>
              <div className="tags-grid">
                {AGENT_TAGS.map((tag) => (
                  <div key={tag} className="tag-container">
                    <span className="tag-label">{tag}</span>
                    <Checkbox.Root
                      className="checkbox-root"
                      checked={selectedTags.includes(tag)}
                      onCheckedChange={(checked) => {
                        if (checked) {
                          setSelectedTags([...selectedTags, tag]);
                        } else {
                          setSelectedTags(selectedTags.filter(t => t !== tag));
                        }
                      }}
                    >
                      <Checkbox.Indicator className="checkbox-indicator">
                        <Check className="check-icon" size={16} />
                      </Checkbox.Indicator>
                    </Checkbox.Root>
                  </div>
                ))}
              </div>
              {validationErrors.tags && (
                <Callout.Root color="red" size="1" className="mt-2">
                  <Callout.Icon>
                    <ExclamationTriangleIcon />
                  </Callout.Icon>
                  <Callout.Text>
                    Oups! You need to select at least one tag
                  </Callout.Text>
                </Callout.Root>
              )}
            </div>

            <div className="form-actions">
              <button className="next-button" onClick={handleNext}>
                Next →
              </button>
            </div>
          </Tabs.Content>

          <Tabs.Content value="mission" className="tab-content">
            <h2 className="mission-title">Mission Statement</h2>
            <p className="mission-description">
              Here you can give your agent a <b><strong>prompt</strong></b> to have the best in class instructions to do its tasks.
            </p>
            <button 
              className='how-to-prompt-button' 
              onClick={() => window.open('https://calk-ai.notion.site/how-to-prompt-101?pvs=74', '_blank')}
            >
              Check our Class on how to prompt ↗
            </button>
            <div className="mission-content-wrapper">
              {/* Left side */}

              <div className="mission-textarea-container">
                <div className="textarea-wrapper">
                  <textarea
                    ref={textareaRef}
                    value={description}
                    onChange={handleTextareaChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Write your best prompt or just write what you want to with this agent and click the 'Enhance Prompt' button for some help."
                    className="mission-textarea"
                  />
                  {showSectionsDropdown && (
                    <div 
                      className="sections-dropdown" 
                      ref={dropdownRef}
                    >
                      <div className="sections-header">Add sections to your prompt</div>
                      {promptSections.map((section, index) => {
                        const Icon = section.icon;
                        return (
                          <HoverCard.Root openDelay={20} closeDelay={20}>
                            <HoverCard.Trigger asChild>
                              <div
                                key={section.name}
                                className={`agent-section-item ${index === selectedIndex ? 'selected' : ''}`}
                                onClick={() => insertSection(section.name)}
                                onMouseEnter={() => setSelectedIndex(index)}
                              >
                                <div className="agent-section-icon">
                                  <Icon />
                                </div>
                                <div className="agent-section-info">
                                  <div className="agent-section-name">{section.name}</div>
                                  <div className="agent-section-description">{section.description}</div>
                                </div>
                              </div>
                            </HoverCard.Trigger>
                            <HoverCard.Portal>
                              <HoverCard.Content sideOffset={5} className="agent-radix-hover-content" side="right">
                                {section.info}
                              </HoverCard.Content>
                            </HoverCard.Portal>
                          </HoverCard.Root>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="form-actions-mission">
                  <button className="back-button-mission" disabled={isEnhancing} onClick={() => setCurrentTab('identity')}>
                    ← Back
                  </button>
                  <button 
                    className="add-section-button-mission" 
                    disabled={isEnhancing} 
                    onClick={handleAddSection}
                  >
                    + Add Section #
                  </button>
                  <button 
                    className={`enhance-button ${isEnhancing ? 'disabled' : ''}`} 
                    onClick={handleEnhancePrompt}
                    disabled={isEnhancing}
                  >
                    {isEnhancing ? 'Enhancing...' : 'Enhance prompt ✨'}
                  </button>
                </div>
              </div>

              {/*<div className="mission-textarea-container">
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Write your best prompt or just write what you want to with this agent and click the 'Enhance Prompt' button for some help."
                  className="mission-textarea"
                />
                <div className="form-actions-mission">
                  <button className="back-button-mission" disabled={isEnhancing} onClick={() => setCurrentTab('identity')}>
                    ← Back
                  </button>
                  <button 
                    className={`enhance-button ${isEnhancing ? 'disabled' : ''}`} 
                    onClick={handleEnhancePrompt}
                    disabled={isEnhancing}
                  >
                    {isEnhancing ? 'Enhancing...' : 'Enhance prompt ✨'}
                  </button>
                </div>
              </div>*/}

              {/* Right side */}
              <div className="mission-right-section">
                <CreateAgentChat 
                  missionPrompt={description} 
                  model={aiModel}
                  onModelChange={(newModel) => setAiModel(newModel)}
                  agentName={name}
                  agentIcon={selectedIcon}
                  temp={mood}
                  onTemperatureChange={(newTemp) => setMood(newTemp)}
                />
                <button className="next-button-mission" disabled={isEnhancing} onClick={() => setCurrentTab('knowledge')}>
                  Next →
                </button>
              </div>
            </div>

            {/*<div className="mission-tips-container">
              <div className="mission-tip-card">
                <span className="mission-tip-icon">📌</span>
                <span className="mission-tip-text">
                  Be precise: specify goals and audience details for tailored responses.
                </span>
              </div>
              <div className="mission-tip-card">
                <span className="mission-tip-icon">🎯</span>
                <span className="mission-tip-text">
                  Define the brand voice, formality level, and any industry-specific jargon.
                </span>
              </div>
              <div className="mission-tip-card">
                <span className="mission-tip-icon">💡</span>
                <span className="mission-tip-text">
                  Use examples or templates to guide structure, like "List benefits in bullet points."
                </span>
              </div>
              <div 
                className="mission-tip-card clickable"
                onClick={() => window.open('https://calk-ai.notion.site/how-to-prompt-101?pvs=74', '_blank')}
                role="link"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    window.open('https://calk-ai.notion.site/how-to-prompt-101?pvs=74', '_blank')
                  }
                }}
              >
                <span className="mission-tip-icon">ℹ️</span>
                <span className="mission-tip-text">
                  Check our Class on how to prompt or click Help me button ↗
                </span>
              </div>
            </div>*/}

          </Tabs.Content>

          <Tabs.Content value="knowledge" className="tab-content">

            <h2>Tools</h2>

            <div className="url-input-section">
              <input
                type="text" 
                placeholder="Enter URL to scrape and add to the knowledge base (e.g. https://example.com)" 
                value={urlInput}
                onChange={(e) => setUrlInput(e.target.value)}
                disabled={isCreating || isScrapingUrl}
                className="url-text-input"
              />
              <Button 
                onClick={handleUrlAdd}
                disabled={isCreating || isScrapingUrl || !urlInput.trim()}
                variant="solid"
                className="url-button-section"
              >
                {isScrapingUrl ? 'Scraping...' : 'Add URL'}
              </Button>
            </div>


            <div className="tools-section">
              <div className="tools-container">
                <div className="tool-item">
                  <div className="tool-info">
                    <div className="tool-icon">
                      <img 
                        src="/images/all_icons/symbols_globe with meridians.png" 
                        alt="Web Search"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                    </div>
                    <div className="tool-details">
                      <span className="tool-name">Web Search</span>
                      <span className="tool-description">Enable real-time web search capabilities</span>
                    </div>
                  </div>
                  <Switch
                    checked={tools.webSearch}
                    onCheckedChange={(checked) => setTools(prev => ({ ...prev, webSearch: checked }))}
                    className="toggle-switch"
                  >
                    <Switch.Thumb className="toggle-switch-thumb" />
                  </Switch>
                </div>
              </div>
            </div>

            <h2 className="knowledge-title">Knowledge Upload</h2>

            <div className="knowledge-flex-container">
              {/* Left side - Original file upload zone */}
              <div className="upload-section">
                <div 
                  className={`file-upload-zone ${isCreating ? 'creating' : ''}`}
                  onDrop={handleFileDrop} 
                  onDragOver={handleDragOver}
                >
                  {isCreating && (
                    <div className="absolute inset-0 z-10 bg-black/5 backdrop-blur-sm rounded-lg flex items-center justify-center">
                      <div className="w-3/4">
                        <div className="overall-progress-bar">
                          <div 
                            className="overall-progress-fill"
                            style={{ width: `${overallProgress}%` }}
                          />
                        </div>
                        <p className="text-center mt-2 text-sm text-slate-600">
                          Creating Agent: {Math.round(overallProgress)}%
                        </p>
                      </div>
                    </div>
                  )}
                  
                  <input
                    type="file"
                    multiple
                    onChange={handleFileSelect}
                    className="file-input"
                    id="file-upload"
                  />
                  <label htmlFor="file-upload" className="file-upload-label">
                    <Upload className="w-12 h-12 text-slate-400" />
                    <span>Drop your files here or click to browse</span>
                  </label>
                </div>
              </div>

              {/* Right side - Existing documents */}
              <FileBrowser
                existingDocuments={existingDocuments}
                savedDriveRefs={savedDriveRefs}
                savedNotionRefs={savedNotionRefs}
                selectedFiles={files}
                onFileSelect={handleAddExistingFile}
              />
            </div>

            {files.length > 0 && (
              <div className="files-list">
                {files.map((file, index) => (
                  <div 
                    key={index}
                    className={`file-item ${
                      isCreating ? 'shadow-lg' : ''
                    } ${
                      fileProgress[file.name] === 100 ? 'opacity-50' : ''
                    }`}
                  >
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center">
                        <Button 
                          onClick={() => handleRemoveFile(file, index)}
                          className="remove-file-button"
                          disabled={isCreating}
                          variant="ghost"
                          color="red" 
                        >
                          <Trash2 />
                        </Button>
                        <span className="text-sm font-medium">{file.name}</span>
                      </div>
                      <span className="text-xs text-slate-500">
                        {file.size ? `${(file.size / (1024 * 1024)).toFixed(2)} MB` : ''}
                      </span>
                    </div>
                    
                    <div className="progress-bar-container">
                      <div 
                        className="progress-bar-fill"
                        style={{ 
                          width: `${fileProgress[file.name] || 0}%`,
                          transition: 'width 0.5s ease-in-out'
                        }}
                      />
                    </div>
                    <div className="text-xs text-slate-500 mt-1">
                      {Math.round(fileProgress[file.name] || 0)}%
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="form-actions">
              <button className="back-button-mission" disabled={isEnhancing} onClick={() => setCurrentTab('mission')}>
              ← Back
              </button>
              <button
                className={`create-button ${isCreating ? 'creating' : ''}`} 
                onClick={handleSubmit}
                disabled={isCreating}
              />
            </div>
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </div>
  );
};

export default CreateAgentModal;