import React, { useState, useEffect } from 'react';
import { ChevronRight, ChevronDown } from 'lucide-react';
import { useAuth } from '../context/AuthContext';

const FileTreeItem = ({ item, onSelect, selectedFiles, level = 0 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasChildren = (item.type === 'database' && item.pages?.length > 0) || 
                     (item.children && item.children.length > 0);

  const isSelected = selectedFiles.some(f => 
    (item.pageId && f.pageId === item.pageId) || 
    (item.driveId && f.driveId === item.driveId) || 
    (item._id && f.sourceDocId === item._id)
  );

  const getIcon = () => {
    if (item.type === 'folder') return 'folder.png';
    if (item.type === 'database') return 'database.png';
    return 'file.png';
  };

  return (
    <div className="drive-file-entry">
      <div 
        className="file-tree-item"
        style={{ paddingLeft: `${level * 24}px` }}
      >
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => onSelect(item)}
        />
        {hasChildren && (
          <button 
            className="drive-folder-button"
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
          </button>//
        )}
        <img 
          src={`/images/${getIcon()}`}
          alt={item.type}
          style={{ width: 16, height: 16 }}
        />
        <span className="drive-file-name">{item.name}</span>
      </div>
      
      {hasChildren && isExpanded && (
        <div className="drive-folder-contents">
          {item.type === 'database' ? 
            item.pages.map(page => (
              <FileTreeItem
                key={page.id}
                item={{
                  ...page,
                  name: page.title,
                  type: 'page',
                  pageId: page.id,
                  path: `${item.path}/${page.title}`
                }}
                onSelect={onSelect}
                selectedFiles={selectedFiles}
                level={level + 1}
              />
            )) :
            item.children.map(child => (
              <FileTreeItem
                key={child._id || child.driveId || child.pageId}
                item={child}
                onSelect={onSelect}
                selectedFiles={selectedFiles}
                level={level + 1}
              />
            ))
          }
        </div>
      )}
    </div>
  );
};

const FileBrowser = ({ existingDocuments, savedDriveRefs, savedNotionRefs, onFileSelect, selectedFiles }) => {
  const { userId } = useAuth();
  const [activeSource, setActiveSource] = useState('local');
  const [organizedFiles, setOrganizedFiles] = useState([]);

  useEffect(() => {
    if (activeSource === 'drive') {
      organizeGoogleDriveFiles(savedDriveRefs);
    } else if (activeSource === 'notion') {
      setOrganizedFiles(savedNotionRefs || []);
    } else if (activeSource === 'local') {
      setOrganizedFiles(existingDocuments);
    }
  }, [activeSource, savedDriveRefs, savedNotionRefs, existingDocuments]);

  // const organizeGoogleDriveFiles = (files) => {
  //   const fileMap = new Map();
  //   files.forEach(file => {
  //     fileMap.set(file.driveId, { ...file, children: [] });
  //   });

  //   const rootFiles = [];
  //   files.forEach(file => {
  //     const fileWithChildren = fileMap.get(file.driveId);
  //     if (file.parentId && fileMap.has(file.parentId)) {
  //       const parent = fileMap.get(file.parentId);
  //       parent.children.push(fileWithChildren);
  //     } else {
  //       rootFiles.push(fileWithChildren);
  //     }
  //   });

  //   setOrganizedFiles(rootFiles);
  // };

  const organizeGoogleDriveFiles = (files) => {
    if (!files || files.length === 0) return [];

    // Filter files to only show personal and public ones
    const accessibleFiles = files.filter(file => 
      file.status === 'Public' || (file.savedBy?._id === userId)
    );

    const fileMap = new Map();
    const rootFiles = [];

    // Get all folder names we actually have
    const folderNames = accessibleFiles
      .filter(file => file.type === 'folder')
      .map(folder => folder.name);

    // console.log('Available folders:', folderNames);

    // First create all file objects
    accessibleFiles.forEach(file => {
      // Clean path - find first part that matches an actual folder
      const pathParts = file.path.split('/');
      const firstFolderIndex = pathParts.findIndex(part => folderNames.includes(part));
      const cleanPath = firstFolderIndex >= 0 ? 
        pathParts.slice(firstFolderIndex).join('/') : 
        file.path;
        
      // console.log(`File: ${file.name}, Clean path: ${cleanPath}`);
      
      fileMap.set(file.driveId, { 
        ...file, 
        children: [],
        cleanPath 
      });
    });

    // Then organize by clean paths
    fileMap.forEach((file) => {
      const pathParts = file.cleanPath.split('/');
      
      if (pathParts.length > 1) {
        // Find the parent folder for this file
        const parentFolderName = pathParts[pathParts.length - 2];
        const parent = Array.from(fileMap.values()).find(
          f => f.name === parentFolderName && f.type === 'folder'
        );
        
        // console.log(`File: ${file.name}, Parent folder: ${parent?.name || 'none'}`);
        
        if (parent) {
          parent.children.push(file);
        } else {
          rootFiles.push(file);
        }
      } else {
        rootFiles.push(file);
      }
    });

    // console.log('Final structure:', rootFiles);
    setOrganizedFiles(rootFiles);
  };

  const sources = [
    { id: 'local', name: 'Local Files', icon: 'file.png' },
    { id: 'drive', name: 'Google Drive', icon: 'google-drive.png' },
    // { id: 'notion', name: 'Notion', icon: 'notion.png' }
  ];

  return (
    <div className="documents-section">
      <div className="file-sidebar">
        {sources.map(source => (
          <div
            key={source.id}
            className={`sidebar-item ${activeSource === source.id ? 'active' : ''}`}
            onClick={() => setActiveSource(source.id)}
          >
            <img 
              src={`/images/${source.icon}`} 
              alt={source.name}
              style={{ width: 20, height: 20 }}
            />
            <span>{source.name}</span>
          </div>
        ))}
      </div>

      <div className="file-content">
        {organizedFiles.map(file => (
          <FileTreeItem
            key={file._id || file.driveId || file.pageId}
            item={file}
            onSelect={onFileSelect}
            selectedFiles={selectedFiles}
          />
        ))}
      </div>
    </div>
  );
};

export default FileBrowser;