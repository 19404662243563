import React, { useState, useRef, useEffect } from 'react';

const HighlightedInput = ({
  input,
  selectedEntities,
  onChange,
  onKeyDown,
  placeholder,
  isEnhancing,
  disabled
}) => {
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(0);
  const contentEditableRef = useRef(null);

  const getHighlightedContent = () => {
    let content = input;
    const mentions = [];

    // Sort entities by name length (longest first) to avoid partial replacements
    const sortedEntities = [...selectedEntities].sort(
      (a, b) => b.name.length - a.name.length
    );

    // Find all entity mentions and their positions
    sortedEntities.forEach(entity => {
      const mention = `@${entity.name}`;
      let index = content.indexOf(mention);
      while (index !== -1) {
        mentions.push({
          start: index,
          end: index + mention.length,
          mention
        });
        index = content.indexOf(mention, index + 1);
      }
    });

    // Sort mentions by position
    mentions.sort((a, b) => b.start - a.start);

    // Replace mentions with styled spans
    mentions.forEach(({ start, end, mention }) => {
      content = 
        content.substring(0, start) +
        `<span class="entity-mention" style="color: #0D00FF; font-weight: bold;">${mention}</span>` +
        content.substring(end);
    });

    return content;
  };

  const handleInput = (e) => {
    e.preventDefault();
    const plainText = e.target.innerText;
    
    // Check if any entity was partially deleted
    selectedEntities.forEach(entity => {
      const mention = `@${entity.name}`;
      const partialMentionRegex = new RegExp(`@${entity.name.slice(0, -1)}(?![\\w.-])`, 'g');
      
      if (partialMentionRegex.test(plainText) && !plainText.includes(mention)) {
        // Remove the partial mention
        const newText = plainText.replace(partialMentionRegex, '');
        onChange({ target: { value: newText } });
        return;
      }
    });

    onChange({ target: { value: plainText } });
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  };

  useEffect(() => {
    if (contentEditableRef.current) {
      const content = getHighlightedContent();
      if (contentEditableRef.current.innerHTML !== content) {
        contentEditableRef.current.innerHTML = content;

        // Restore cursor position
        const selection = window.getSelection();
        const range = document.createRange();
        const childNodes = contentEditableRef.current.childNodes;
        let cursorPosition = 0;
        let targetPosition = selectionStart;

        // Find the correct text node and position
        for (let i = 0; i < childNodes.length; i++) {
          const node = childNodes[i];
          const length = node.textContent.length;

          if (cursorPosition + length >= targetPosition) {
            if (node.nodeType === Node.TEXT_NODE) {
              range.setStart(node, targetPosition - cursorPosition);
              range.setEnd(node, targetPosition - cursorPosition);
            } else {
              // If it's a span element (entity mention), place cursor at end
              range.setStartAfter(node);
              range.setEndAfter(node);
            }
            break;
          }
          cursorPosition += length;
        }

        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }, [input, selectedEntities]);

  return (
    <div
      ref={contentEditableRef}
      contentEditable={!disabled}
      onInput={handleInput}
      onKeyDown={onKeyDown}
      onPaste={handlePaste}
      spellCheck="false" 
      className={`chat-input ${isEnhancing ? 'enhancing' : ''}`}
      style={{
        minHeight: '24px',
        maxHeight: '300px',
        overflowY: 'auto',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word'
      }}
      placeholder={placeholder}
      data-placeholder={placeholder}
      onBlur={() => {
        const selection = window.getSelection();
        setSelectionStart(selection.anchorOffset);
        setSelectionEnd(selection.focusOffset);
      }}
    />
  );
};

export default HighlightedInput;