// ChatHeader.jsx
import React from 'react';
import { Button, Flex, Tooltip } from '@radix-ui/themes';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { IoArrowBack } from "react-icons/io5";
import { PiFilesBold } from "react-icons/pi";
import { FaShareAlt, FaTrash } from "react-icons/fa";

const ParticipatingEntities = ({ entities }) => {
  return (
    <div className="participating-entities">
      {entities.map((entity, index) => (
        <Tooltip key={index} content={entity.name}>
          <div className="participating-entity-card" style={{ zIndex: entities.length - index }}>
            <img 
              src={entity.icon} 
              alt={entity.name}
              className="participating-entity-icon"
            />
          </div>
        </Tooltip>
      ))}
    </div>
  );
};//

export const ChatHeader = ({
  navigate,
  title,
  isEditingTitle,
  titleInputRef,
  handleTitleChange,
  handleTitleSave,
  handleTitleKeyPress,
  handleTitleEdit,
  handleDeleteChat,
  chatId,
  currentMode,
  participatingEntities = [],
}) => {
  return (
    <div className="chat-header-section">
      <Button 
        onClick={() => {
          navigate('/home');
          window.location.reload();
        }}
        variant="ghost"
        className="back-button"
      >
        <Flex align="center" gap="2">
          <IoArrowBack />
        </Flex>
      </Button>

      <div className="title-section">
        {isEditingTitle ? (
          <input
            ref={titleInputRef}
            type="text"
            value={title}
            onChange={handleTitleChange}
            onBlur={handleTitleSave}
            onKeyPress={handleTitleKeyPress}
            className="chat-title-input"
          />
        ) : (
          <h2 className="chat-title">{title}</h2>
        )}
        <button onClick={handleTitleEdit} className="edit-title-btn">
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
      </div>

      <div className="header-right-section">
        <ParticipatingEntities entities={participatingEntities} />
        <Tooltip content="View the conversation files">
          <button className="header-icon-btn">
            <PiFilesBold size={18} />
          </button>
        </Tooltip>

        <Tooltip content="Share your conversation">
          <button className="header-icon-btn">
            <FaShareAlt size={16} />
          </button>
        </Tooltip>

        <AlertDialog.Root>
          <Tooltip content="Delete the conversation">
            <AlertDialog.Trigger asChild>
                <button className="header-icon-btn">
                  <FaTrash size={14} />
                </button>

            </AlertDialog.Trigger>
          </Tooltip>
          
          <AlertDialog.Portal>
            <AlertDialog.Overlay className="dialog-overlay" />
            <AlertDialog.Content className="dialog-content">
              <AlertDialog.Title className="dialog-title">Delete the conversation?</AlertDialog.Title>
              <AlertDialog.Description className="dialog-description">
                Are you sure you want to delete <b>{title}</b>? This chat will no longer be accessible.
              </AlertDialog.Description>
              
              <div style={{ display: 'flex', gap: '12px', justifyContent: 'flex-end', marginTop: '20px' }}>
                <AlertDialog.Cancel asChild>
                  <button className="chat-delete-button chat-delete-button-cancel">
                    Cancel
                  </button>
                </AlertDialog.Cancel>
                <AlertDialog.Action asChild>
                  <button 
                    className="chat-delete-button chat-delete-button-delete"
                    onClick={() => {
                      handleDeleteChat(chatId, currentMode === 'agents' ? 'agents' : 'models');
                      navigate('/home');
                      window.location.reload();
                    }}
                  >
                    Delete
                  </button>
                </AlertDialog.Action>
              </div>
            </AlertDialog.Content>
          </AlertDialog.Portal>
        </AlertDialog.Root>
      </div>
    </div>
  );
};