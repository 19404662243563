import React, { useState, useEffect } from 'react';

const HomeHeader = ({ userFirstName }) => {
  const [greeting, setGreeting] = useState('');

  const greetings = {
    morning: [
      `Good morning, ${userFirstName}!☀️`,
      `Rise and shine, ${userFirstName}!🌞`,
      `Fuel up and let's go, ${userFirstName}!🍳🚀`,
      `Bonjour, ${userFirstName}!🥐⚙️`,
      `Coffee first, ${userFirstName}.☕️🤖`
    ],
    afternoon: [
      `Good afternoon, ${userFirstName}!⚡️`,
      `Keep the vibe strong, ${userFirstName}!💪`,
      `Lunch done? Let's roll, ${userFirstName}!🍔💻`,
      `Bonjour, ${userFirstName}! Onward!🕶️✨`,
      `You've got this, ${userFirstName}!🚀`
    ],
    evening: [
      `Good evening, ${userFirstName}!🌙`,
      `Dinner vibes, ${userFirstName}?🍕`,
      `Bonsoir, ${userFirstName}!✨`,
      `Let's wrap this up, ${userFirstName}!🌌`,
      `AI's here, ${userFirstName}! Let's finish it.🤖`
    ],
    lateNight: [
      `Burning the midnight oil, ${userFirstName}?🕯️`,
      `Still up, ${userFirstName}? Let's crush it.🌙`,
      `Bonsoir, ${userFirstName}! Let's go.⚡️`,
      `Night mode on, ${userFirstName}.🌌`,
      `No sleep? Let's focus, ${userFirstName}!🧠`
    ]
  };

  const getTimeOfDay = () => {
    const hour = new Date().getHours();
    
    if (hour >= 5 && hour < 12) return 'morning';
    if (hour >= 12 && hour < 17) return 'afternoon';
    if (hour >= 17 && hour < 22) return 'evening';
    return 'lateNight';
  };

  const getRandomGreeting = (timeOfDay) => {
    const timeGreetings = greetings[timeOfDay];
    const randomIndex = Math.floor(Math.random() * timeGreetings.length);
    return timeGreetings[randomIndex];
  };

  useEffect(() => {
    const timeOfDay = getTimeOfDay();
    const randomGreeting = getRandomGreeting(timeOfDay);
    setGreeting(randomGreeting);
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <div className="chat-header">
      <h1>
        {/*<img src="/favicon-calk.svg" className="chat-logo" alt="Chat logo" />*/}
        {greeting}
      </h1>
    </div>
  );
};

export default HomeHeader;