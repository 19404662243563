import React from 'react';
import './components.css';

export const Card = ({ children, className = '', ...props }) => (
  <div className={`card ${className}`} {...props}>
    {children}
  </div>
);

export const Button = ({ children, variant = 'default', className = '', ...props }) => (
  <button className={`button ${variant === 'outline' ? 'button-outline' : 'button-default'} ${className}`} {...props}>
    {children}
  </button>
);

export const Select = ({ value, onChange, placeholder, children }) => (
  <div className="select">
    <select className="select-input" value={value} onChange={e => onChange(e.target.value)}>
      <option value="" disabled>{placeholder}</option>
      {children}
    </select>
  </div>
);

export const SelectItem = ({ value, children }) => (
  <option value={value}>{children}</option>
);

export const DateRangePicker = ({ startDate, endDate, onDatesChange }) => {
  return (
    <div className="date-range-picker">
      <input 
        type="date" 
        value={startDate} 
        onChange={(e) => onDatesChange({ start: e.target.value, end: endDate })}
      />
      <span>→</span>
      <input 
        type="date" 
        value={endDate}
        onChange={(e) => onDatesChange({ start: startDate, end: e.target.value })}
      />
    </div>
  );
};