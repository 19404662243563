import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import * as Slider from '@radix-ui/react-slider';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Checkbox from '@radix-ui/react-checkbox';
import * as Progress from '@radix-ui/react-progress';
import { Check, Upload } from 'lucide-react';
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoIosInformationCircleOutline } from "react-icons/io";
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Callout, Button, ScrollArea, Flex, Switch } from '@radix-ui/themes';
import { FaWandMagicSparkles } from "react-icons/fa6";
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import axios from 'axios';
import { FiChevronUp } from "react-icons/fi";
import '../styles/CreateAdminAgentModal.css';
import CategorizedIconDropdown from '../components/CategorizedIconDropdown';
import Swal from 'sweetalert2'
import DrivePopup from '../components/DrivePopup';
import NotionPopup from '../components/NotionPopup';
import FileBrowser from '../components/FileBrowser';

const TAB_NAMES = ['Identity', 'Mission', 'Knowledge and tools'];
const AGENT_TAGS = ['Business', 'Marketing', 'Productivity', 'Sales', 'Dev', 'Data', 'HR', 'Miscellaneous'];

const CreateAdminAgentModal = ({ onCreateAgent }) => {
  const navigate = useNavigate();
  const topRef = useRef(null);
  const [currentTab, setCurrentTab] = useState('identity');
  const [name, setName] = useState('');
  const [miniDescription, setMiniDescription] = useState('');
  const [description, setDescription] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [aiModel, setAiModel] = useState('');
  const [availableModels, setAvailableModels] = useState([]);
  const [mood, setMood] = useState(50);
  const [files, setFiles] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [availableIcons, setAvailableIcons] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState('');
  const [isIconDropdownOpen, setIsIconDropdownOpen] = useState(false);
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [fileProgress, setFileProgress] = useState({});
  const [overallProgress, setOverallProgress] = useState(0);
  const [showCompletionDialog, setShowCompletionDialog] = useState(false);
  const [existingDocuments, setExistingDocuments] = useState([]);
  const [showDrivePopup, setShowDrivePopup] = useState(false);
  const [showNotionPopup, setShowNotionPopup] = useState(false);
  const [savedDriveRefs, setSavedDriveRefs] = useState([]);
  const [selectedDriveRefs, setSelectedDriveRefs] = useState([]);
  const [showDriveRefsPopup, setShowDriveRefsPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [urlInput, setUrlInput] = useState('');
  const [isScrapingUrl, setIsScrapingUrl] = useState(false);
  const [tools, setTools] = useState({
      webSearch: false
  });
  const [validationErrors, setValidationErrors] = useState({
    name: false,
    miniDescription: false,
    aiModel: false,
    tags: false
  });

  const getAvailableIcons = () => {
    const iconContext = require.context('/public/images/all_icons/', false, /\.png$/);
    return iconContext.keys().map(path => path.replace('./', ''));
  };

  useEffect(() => {
    fetchExistingDocuments();
  }, []);

  useEffect(() => {
    fetchAvailableModels();
  }, []);

  useEffect(() => {
    const fetchSavedRefs = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/google/saved-references`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        setSavedDriveRefs(response.data);
      } catch (error) {
        console.error('Error fetching saved references:', error);
      }
    };

    fetchSavedRefs();
  }, []);

  useEffect(() => {
    const icons = getAvailableIcons();
    setAvailableIcons(icons);
    setSelectedIcon(icons[0]); // Set first icon as default
  }, []);

  const validateIdentityTab = () => {
    const errors = {
      name: !name.trim(),
      miniDescription: !miniDescription.trim(),
      aiModel: !aiModel,
      tags: selectedTags.length === 0
    };

    setValidationErrors(errors);
    return !Object.values(errors).some(error => error);
  };

  const handleSelectDriveRef = async (ref) => {
    try {
      // If it's a folder, we need to get all files in it
      if (ref.type === 'folder') {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/google/folder-contents/${ref.driveId}`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        setSelectedDriveRefs(prev => [...prev, ...response.data]);
      } else {
        setSelectedDriveRefs(prev => [...prev, ref]);
      }
    } catch (error) {
      console.error('Error selecting drive reference:', error);
    }
  };

  const resetForm = () => {
    setCurrentTab('identity');
    setName('');
    setMiniDescription('');
    setDescription('');
    setSelectedTags([]);
    setAiModel('');
    setMood(75);
    setFiles([]);
    setIsDropdownOpen(false);
  };

  const handleBack = () => {
    resetForm();
    navigate('/home');
  };

  const fetchExistingDocuments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/documents`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setExistingDocuments(response.data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  const handleUrlAdd = async () => {
    if (!urlInput.trim()) return;
    
    setIsScrapingUrl(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/agents/scrape-url`,
        { url: urlInput },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const scrapedContent = response.data;
      const fileName = `${new URL(urlInput).hostname}.txt`;
      
      // Create a file-like object with the scraped content
      const file = new File(
        [new Blob([scrapedContent], { type: 'text/plain' })],
        fileName,
        { type: 'text/plain' }
      );

      // Add metadata for tracking the source
      file.isScrapedContent = true;
      file.sourceUrl = urlInput;

      // Initialize progress for this file
      setFileProgress(prev => ({
        ...prev,
        [fileName]: 0
      }));

      // Add to files list
      setFiles(prev => [...prev, file]);

      // Set progress to complete
      setFileProgress(prev => ({
        ...prev,
        [fileName]: 100
      }));

      setUrlInput('');

      // Show success message
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "URL content added successfully",
        showConfirmButton: false,
        timer: 1500
      });

    } catch (error) {
      console.error('Error scraping URL:', error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Error scraping URL",
        text: "Website blocks scraping. Too bad.",
        showConfirmButton: false,
        timer: 3000
      });
    } finally {
      setIsScrapingUrl(false);
    }
  };

  // const handleAddExistingFile = async (doc) => {
  //   try {
  //     // Create a file object with the document information
  //     const file = new File(
  //       [new Blob([''])],
  //       doc.name,
  //       { type: 'application/octet-stream' }
  //     );
      
  //     // Store the document information
  //     file.isExistingDocument = true;
  //     file.sourceDocId = doc._id;
  //     file.sourceDocName = doc.name;
      
  //     // Initialize progress
  //     setFileProgress(prev => ({
  //       ...prev,
  //       [doc.name]: 0
  //     }));
      
  //     setFiles(prev => {
  //       // Check if file already exists
  //       if (prev.some(f => f.name === doc.name)) {
  //         console.log('File already added:', doc.name);
  //         return prev;
  //       }
  //       return [...prev, file];
  //     });

  //     // Set progress to complete
  //     setFileProgress(prev => ({
  //       ...prev,
  //       [doc.name]: 100
  //     }));

  //     console.log('Added existing document:', doc.name, 'with ID:', doc._id);

  //   } catch (error) {
  //     console.error('Error adding existing file:', error);
  //   }
  // };

  const handleAddExistingFile = async (file) => {
    try {
      // Handle Google Drive files
      if (file.driveId) {
        const driveFile = {
          name: file.name,
          driveId: file.driveId,
          type: file.type,
          path: file.path,
          isGoogleDriveFile: true
        };

        setFileProgress(prev => ({
          ...prev,
          [file.name]: 0
        }));
        
        setFiles(prev => {
          if (prev.some(f => f.driveId === file.driveId)) {
            console.log('File already added:', file.name);
            return prev;
          }
          return [...prev, driveFile];
        });

        setFileProgress(prev => ({
          ...prev,
          [file.name]: 100
        }));

      } else {
        // Handle regular existing documents
        const localFile = {
          name: file.name,
          sourceDocId: file._id,
          isExistingDocument: true
        };
        
        setFileProgress(prev => ({
          ...prev,
          [file.name]: 0
        }));
        
        setFiles(prev => {
          if (prev.some(f => f.sourceDocId === file._id)) {
            console.log('File already added:', file.name);
            return prev;
          }
          return [...prev, localFile];
        });

        setFileProgress(prev => ({
          ...prev,
          [file.name]: 100
        }));
      }
    } catch (error) {
      console.error('Error adding file:', error);
    }
  };

  const fetchAvailableModels = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/chatmodels`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      // Filter out Multi Modal as per the current implementation
      const filteredModels = data.filter(model => model.name !== 'Multi Modal');
      setAvailableModels(filteredModels);
    } catch (error) {
      console.error('Error fetching available models:', error);
    }
  };

  const getModelIcon = (modelName) => {
    const modelsIcons = {
      'GPT4': '/images/all_icons/gpt.png',
      'GPT4o': '/images/all_icons/gpt.png',
      'GPT4oMini': '/images/all_icons/gpt.png',
      'Claude3.5': '/images/all_icons/claude.png',
      'ClaudeSonnet': '/images/all_icons/claude.png',
      'Claude Opus': '/images/all_icons/claude.png',
      'Claude Haiku': '/images/all_icons/claude.png',
      'Mistral AI Large': '/images/all_icons/mistral.png',
      'Mistral AI Medium': '/images/all_icons/mistral.png',
      'Mistral AI Small': '/images/all_icons/mistral.png',
      'Codestral': '/images/all_icons/mistral.png',
      'Llama3.1': '/images/all_icons/llama.png',
      'Gemini1.5': '/images/all_icons/gemini.png',
      'Cohere': '/images/all_icons/cohere.png',
      'Perplexity': '/images/all_icons/perplexity.png',
      'Stable Diffusion': '/images/all_icons/stablediffusion.png',
      'DALL-E 3': '/images/all_icons/gpt.png',
    };
    
    const normalizedModelName = modelName.replace(/\s+/g, '').toLowerCase();
    const iconPath = Object.entries(modelsIcons).find(([key]) => 
      key.replace(/\s+/g, '').toLowerCase() === normalizedModelName
    );
    
    return iconPath ? iconPath[1] : '/images/default.png';
  };

  const getModelEndpoint = (model) => {
    switch (model.toLowerCase()) {
      case 'gpt 4o':
        return 'gpt4o';
      case 'gpt 4o mini':
        return 'gpt4omini';
      case 'gpt 4':
        return 'gpt4';
      case 'claude 3.5':
        return 'claude3.5';
      case 'claude sonnet':
        return 'claudesonnet';
      case 'claude opus':
        return 'claude-opus';
      case 'claude haiku':
        return 'claude-haiku';
      case 'mistral ai large':
        return 'mistral-large';
      case 'mistral ai medium':
        return 'mistral-medium';
      case 'mistral ai small':
        return 'mistral-small';
      case 'codestral':
        return 'codestral';
      case 'llama 3.1':
        return 'llama3.1';
      case 'gemini 2':
        return 'gemini1.5';
      case 'cohere':
        return 'cohere';  
      case 'perplexity':
        return 'perplexity';
      case 'stable diffusion':
      case 'stablediffusion':
        return 'stablediffusion';
      default:
        return model.toLowerCase().replace(' ', '');
    }
  };

  // const handleNext = () => {
  //   setCurrentTab(prev => prev + 1);
  // };

  const handleNext = () => {
    if (currentTab === 'identity') {
      if (!validateIdentityTab()) {
        return;
      }
    }
    setCurrentTab(prev => {
      if (prev === 'identity') return 'mission';
      if (prev === 'mission') return 'knowledge';
      return prev;
    });
  };

  const goUpPage = () => {
    topRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const handleEnhancePrompt = async () => {
    setIsEnhancing(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/agents/generate-description`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ userDescription: description })
      });
      const data = await response.json();
      setDescription(data.generatedDescription);
    } catch (error) {
      console.error('Error enhancing prompt:', error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Oops...",
        text: "Error improving prompt. Please try again.",
        showConfirmButton: false,
        timer: 3000
      });
    } finally {
      setIsEnhancing(false);
    }
  };

  // Similarly update handleFileDrop
  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    const initialProgress = {};
    droppedFiles.forEach(file => {
      initialProgress[file.name] = 0;
    });
    setFileProgress(prev => ({...prev, ...initialProgress}));
    setFiles([...files, ...droppedFiles]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // const handleFileSelect = (e) => {
  //   const selectedFiles = Array.from(e.target.files);
  //   setFiles([...files, ...selectedFiles]);
  // };

  // Update handleFileSelect to initialize progress
  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    // Initialize progress for each file
    const initialProgress = {};
    selectedFiles.forEach(file => {
      initialProgress[file.name] = 0;
    });
    setFileProgress(prev => ({...prev, ...initialProgress}));
    setFiles([...files, ...selectedFiles]);
  };

  const estimateProcessingTime = (file) => {
    const sizeInMB = file.size / (1024 * 1024);
    const fileType = file.type.toLowerCase();
    
    // Base time includes reading file and initial processing
    let baseTime = 2000; // 2 seconds base time
    
    // Adjust extraction time multipliers
    if (fileType.includes('pdf')) {
      baseTime += sizeInMB * 5000; // 5 seconds per MB for PDFs
    } else if (fileType.includes('spreadsheet') || fileType.includes('excel')) {
      baseTime += sizeInMB * 4000; // 4 seconds per MB for spreadsheets
    } else if (fileType.includes('presentation')) {
      baseTime += sizeInMB * 4500; // 4.5 seconds per MB for presentations
    } else if (fileType.includes('document')) {
      baseTime += sizeInMB * 4000; // 4 seconds per MB for documents
    } else {
      baseTime += sizeInMB * 3000; // 3 seconds per MB for other files
    }
    
    // Adjust chunk processing time
    const estimatedChunks = Math.ceil((sizeInMB * 1024 * 1024) / 500);
    const chunkProcessingTime = estimatedChunks * 3000; // 3 seconds per chunk
    
    return Math.ceil(baseTime + chunkProcessingTime);
  };

  const updateFileProgress = async (file, agentId) => {
    setFileProgress(prev => ({
      ...prev,
      [file.name]: 0
    }));

    const formData = new FormData();
    formData.append('files', file);
    formData.append('topics', `Topic ${file.name}`);

    const updateProgressInterval = setInterval(() => {
      setFileProgress(prev => {
        const currentProgress = prev[file.name] || 0;
        if (currentProgress < 90) {
          return {
            ...prev,
            [file.name]: currentProgress + 1
          };
        }
        return prev;
      });
    }, 100);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/agents/${agentId}/add-knowledge`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      clearInterval(updateProgressInterval);
      setFileProgress(prev => ({
        ...prev,
        [file.name]: 100
      }));

      return response;
    } catch (error) {
      clearInterval(updateProgressInterval);
      setFileProgress(prev => ({
        ...prev,
        [file.name]: -1
      }));
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsCreating(true);
    setOverallProgress(0);

    try {
      const companyId = localStorage.getItem('companyId');
      const agentData = {
        name,
        description,
        miniDescription,
        specialization: selectedTags,
        icon: selectedIcon,
        aiModel: getModelEndpoint(aiModel),
        mood: mood / 100,
        tools,
        companyId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/agents/create-calk-agent`,
        agentData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data && response.data._id) {
        const totalFiles = files.length;
        let completedFiles = 0;

        for (const file of files) {
          try {
            if (file.isGoogleDriveFile) {
              // Import Google Drive file
              await axios.post(
                `${process.env.REACT_APP_BACKEND}/api/google/agent-knowledge/${response.data._id}`,
                { 
                  fileId: file.driveId,
                  name: file.name
                },
                {
                  headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                  }
                }
              );
              completedFiles++;
            } else if (file.isExistingDocument) {
              // Copy existing document knowledge
              await axios.post(
                `${process.env.REACT_APP_BACKEND}/api/agents/${response.data._id}/copy-knowledge`,
                { sourceDocId: file.sourceDocId },
                {
                  headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                  }
                }
              );
              completedFiles++;
            } else {
              // Handle regular file upload
              await updateFileProgress(file, response.data._id);
              completedFiles++;
            }
            setOverallProgress((completedFiles / totalFiles) * 100);
          } catch (error) {
            console.error(`Failed to process file ${file.name}:`, error);
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Oops...",
              text: `Failed to process file ${file.name}.`,
              showConfirmButton: false,
              timer: 3000
            });
          }
        }

        setOverallProgress(100);
        setTimeout(() => {
          handleBack();
          Swal.fire({
            icon: "success",
            title: `Successfully created ${agentData.name}!`,
            showConfirmButton: false,
            timer: 1500
          });
        }, 1000);
      }
    } catch (error) {
      console.error('Error creating agent:', error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Oops...",
        text: `Failed to create the agent!`,
        showConfirmButton: false,
        timer: 3000
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
      setIsCreating(false);
    }
  };

  const getMoodEmoji = (value) => {
    switch (value) {
      case 0:
        return '😐'; // Robot/Strict
      case 25:
        return '🤓'; // Analytical
      case 50:
        return '😜'; // Balanced
      case 75:
        return '🤪'; // Creative
      case 100:
        return '🤡'; // Very Creative/Artistic
      default:
        return '😜';
    }
  };

return (
    <div className="create-agent-page">
      <button onClick={handleBack} className="back-button">
        <FaArrowLeftLong size={20} />
      </button>
      <div className="create-agent-content">
        <Tabs.Root value={currentTab}>
          <Tabs.List className="tab-list">
            <Tabs.Trigger value="identity" className="tab-trigger">
              Identity
            </Tabs.Trigger>
            <Tabs.Trigger value="mission" className="tab-trigger">
              Mission
            </Tabs.Trigger>
            <Tabs.Trigger value="knowledge" className="tab-trigger">
              Knowledge & Tools
            </Tabs.Trigger>
          </Tabs.List>

          <Tabs.Content value="identity" className="tab-content">
            <div className="form-group">
              <label>Agent Icon</label>
              <CategorizedIconDropdown
                selectedIcon={selectedIcon}
                onIconSelect={(icon) => {
                  setSelectedIcon(icon);
                  setIsIconDropdownOpen(false);
                }}
                isOpen={isIconDropdownOpen}
                onOpenChange={setIsIconDropdownOpen}
                availableIcons={availableIcons}
              />
            </div>

            <div className="form-group">
              <label htmlFor="agentName">Agent Name <span className="required-star">📝</span></label>
              <input
                id="agentName"
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setValidationErrors(prev => ({ ...prev, name: false }));
                }}
                placeholder="Give it a cool and short name to remember it"
                className="text-input"
              />
              {validationErrors.name && (
                <Callout.Root color="red" size="1" className="mt-2">
                  <Callout.Icon>
                    <ExclamationTriangleIcon />
                  </Callout.Icon>
                  <Callout.Text>
                    Oups! You forgot to name the Agent
                  </Callout.Text>
                </Callout.Root>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="miniDescription">Description <span className="required-star">📋</span></label>
              <input
                id="miniDescription"
                type="text"
                value={miniDescription}
                onChange={(e) => {
                  setMiniDescription(e.target.value);
                  setValidationErrors(prev => ({ ...prev, miniDescription: false }));
                }}
                placeholder="Describe it in one sentence"
                className="text-input"
              />
              {validationErrors.miniDescription && (
                <Callout.Root color="red" size="1" className="mt-2">
                  <Callout.Icon>
                    <ExclamationTriangleIcon />
                  </Callout.Icon>
                  <Callout.Text>
                    Oups! You forgot to add a description
                  </Callout.Text>
                </Callout.Root>
              )}
            </div>

            <div className="form-group">
              <label>AI Model 🤖</label>
              <DropdownMenu.Root open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
                <DropdownMenu.Trigger className="model-dropdown-trigger">
                  {aiModel || "Select Model"} <FiChevronUp />
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                  <DropdownMenu.Content 
                    className="model-dropdown-content" 
                    side="top"
                    sideOffset={5}
                    align="center"
                  >
                    {availableModels.map((model) => (
                      <DropdownMenu.Item
                        key={model._id}
                        className="model-item"
                        onSelect={() => {
                          setAiModel(model.name);
                          setIsDropdownOpen(false);
                        }}
                      >

                        <div className="model-info">
                          <div className="model-name"><img src={getModelIcon(model.name)} alt={model.name} className="model-icon" />{model.name}</div>
                          <div className="model-description">{model.miniDescription}</div>
                        </div>
                      </DropdownMenu.Item>
                    ))}
                  </DropdownMenu.Content>
                </DropdownMenu.Portal>
              </DropdownMenu.Root>
              {validationErrors.aiModel && (
                <Callout.Root color="red" size="1" className="mt-2">
                  <Callout.Icon>
                    <ExclamationTriangleIcon />
                  </Callout.Icon>
                  <Callout.Text>
                    Oups! You forgot to select a model
                  </Callout.Text>
                </Callout.Root>
              )}
            </div>

            <div className="form-group">
              <label>Mood of the agent 🌡️</label>
              <div className="mood-slider-container">
                <div className="mood-labels">
                  <span>Serious</span>
                  <span>Super Creative</span>
                </div>
                {/* Floating tooltip - add this div */}
                <div 
                  className="mood-value-tooltip"
                  style={{ 
                    left: `${(mood/100) * 100}%`,
                    transform: 'translateX(-50%)',
                  }}
                >
                  {getMoodEmoji(mood)}
                </div>
                <div className="numeric-labels">
                  <span>0</span>
                  <span>1</span>
                </div>
                <Slider.Root
                  className="slider-root"
                  defaultValue={[50]}
                  max={100}
                  step={25}
                  onValueChange={([value]) => setMood(value)}
                >
                  <Slider.Track className="slider-track">
                    <Slider.Range className="slider-range" />
                  </Slider.Track>
                  <Slider.Thumb className="slider-thumb" />
                </Slider.Root>
                <Callout.Root color="darkblue" size="1">
                  <Callout.Icon>
                    <IoIosInformationCircleOutline />
                  </Callout.Icon>
                  <Callout.Text>
                    Temperature is your Agent's "mood", 0 is all being serious, 100 is wild ideas!
                  </Callout.Text>
                </Callout.Root>
              </div>
            </div>

            <div className="form-group">
              <label>Tag them:</label>
              <div className="tag-help-text">
                Here you can tag the agent to be affiliated to a specific domain of expertise
              </div>
              <div className="tags-grid">
                {AGENT_TAGS.map((tag) => (
                  <div key={tag} className="tag-container">
                    <span className="tag-label">{tag}</span>
                    <Checkbox.Root
                      className="checkbox-root"
                      checked={selectedTags.includes(tag)}
                      onCheckedChange={(checked) => {
                        if (checked) {
                          setSelectedTags([...selectedTags, tag]);
                        } else {
                          setSelectedTags(selectedTags.filter(t => t !== tag));
                        }
                      }}
                    >
                      <Checkbox.Indicator className="checkbox-indicator">
                        <Check className="check-icon" size={16} />
                      </Checkbox.Indicator>
                    </Checkbox.Root>
                  </div>
                ))}
              </div>
              {validationErrors.tags && (
                <Callout.Root color="red" size="1" className="mt-2">
                  <Callout.Icon>
                    <ExclamationTriangleIcon />
                  </Callout.Icon>
                  <Callout.Text>
                    Oups! You need to select at least one tag
                  </Callout.Text>
                </Callout.Root>
              )}
            </div>

            <div className="form-actions">
              <button className="next-button" onClick={handleNext}>
                Next →
              </button>
            </div>
          </Tabs.Content>

          <Tabs.Content value="mission" className="tab-content">
            <h2 className="mission-title">Mission Statement</h2>
            <p className="mission-description">
              Here you can give your agent a <b><strong>prompt</strong></b> to have the best in class instructions to do its tasks.
            </p>
            <div className="mission-textarea-container">
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Write your best prompt or just write what you want to with this agent and click the 'Enhance Prompt' button for some help."
                className="mission-textarea"
              />

            </div>
            <div className="form-actions-mission">
              <button className="back-button-mission" disabled={isEnhancing} onClick={() => setCurrentTab('identity')}>
              ← Back
              </button>
              <button 
                className={`enhance-button ${isEnhancing ? 'disabled' : ''}`} 
                onClick={handleEnhancePrompt}
                disabled={isEnhancing}
              >
                {isEnhancing ? 'Enhancing...' : 'Enhance prompt ✨'}
              </button>
              <button className="next-button-mission" disabled={isEnhancing} onClick={() => setCurrentTab('knowledge')}>
                Next →
              </button>
            </div>

            <div className="mission-tips-container">
              <div className="mission-tip-card">
                <span className="mission-tip-icon">📌</span>
                <span className="mission-tip-text">
                  Be precise: specify goals and audience details for tailored responses.
                </span>
              </div>
              <div className="mission-tip-card">
                <span className="mission-tip-icon">🎯</span>
                <span className="mission-tip-text">
                  Define the brand voice, formality level, and any industry-specific jargon.
                </span>
              </div>
              <div className="mission-tip-card">
                <span className="mission-tip-icon">💡</span>
                <span className="mission-tip-text">
                  Use examples or templates to guide structure, like "List benefits in bullet points."
                </span>
              </div>
              <div 
                className="mission-tip-card clickable"
                onClick={() => window.open('https://calk-ai.notion.site/how-to-prompt-101?pvs=74', '_blank')}
                role="link"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    window.open('https://calk-ai.notion.site/how-to-prompt-101?pvs=74', '_blank')
                  }
                }}
              >
                <span className="mission-tip-icon">ℹ️</span>
                <span className="mission-tip-text">
                  Check our Class on how to prompt or click Help me button ↗
                </span>
              </div>
            </div>

          </Tabs.Content>

          <Tabs.Content value="knowledge" className="tab-content">

            <h2>Tools</h2>

            <div className="url-input-section">
              <input
                type="text" 
                placeholder="Enter URL to scrape and add to the knowledge base (e.g. https://example.com)" 
                value={urlInput}
                onChange={(e) => setUrlInput(e.target.value)}
                disabled={isCreating || isScrapingUrl}
                className="url-text-input"
              />
              <Button 
                onClick={handleUrlAdd}
                disabled={isCreating || isScrapingUrl || !urlInput.trim()}
                variant="solid"
              >
                {isScrapingUrl ? 'Scraping...' : 'Add URL'}
              </Button>
            </div>


            <div className="tools-section">
              <div className="tools-container">
                <div className="tool-item">
                  <div className="tool-info">
                    <div className="tool-icon">
                      <img 
                        src="/images/all_icons/symbols_globe with meridians.png" 
                        alt="Web Search"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                    </div>
                    <div className="tool-details">
                      <span className="tool-name">Web Search</span>
                      <span className="tool-description">Enable real-time web search capabilities</span>
                    </div>
                  </div>
                  <Switch
                    checked={tools.webSearch}
                    onCheckedChange={(checked) => setTools(prev => ({ ...prev, webSearch: checked }))}
                    className="toggle-switch"
                  >
                    <Switch.Thumb className="toggle-switch-thumb" />
                  </Switch>
                </div>
              </div>
            </div>

            <h2 className="knowledge-title">Knowledge Upload</h2>
            
            <div className="knowledge-flex-container">
              {/* Left side - Original file upload zone */}
              <div className="upload-section">
                <div 
                  className={`file-upload-zone ${isCreating ? 'creating' : ''}`}
                  onDrop={handleFileDrop} 
                  onDragOver={handleDragOver}
                >
                  {isCreating && (
                    <div className="absolute inset-0 z-10 bg-black/5 backdrop-blur-sm rounded-lg flex items-center justify-center">
                      <div className="w-3/4">
                        <div className="overall-progress-bar">
                          <div 
                            className="overall-progress-fill"
                            style={{ width: `${overallProgress}%` }}
                          />
                        </div>
                        <p className="text-center mt-2 text-sm text-slate-600">
                          Creating Agent: {Math.round(overallProgress)}%
                        </p>
                      </div>
                    </div>
                  )}
                  
                  <input
                    type="file"
                    multiple
                    onChange={handleFileSelect}
                    className="file-input"
                    id="file-upload"
                  />
                  <label htmlFor="file-upload" className="file-upload-label">
                    <Upload className="w-12 h-12 text-slate-400" />
                    <span>Drop your files here or click to browse</span>
                  </label>
                </div>
              </div>

              {/* Right side - Existing documents */}
              <FileBrowser
                existingDocuments={existingDocuments}
                savedDriveRefs={savedDriveRefs}
                selectedFiles={files}
                onFileSelect={handleAddExistingFile}
              />
            </div>

            {files.length > 0 && (
              <div className="files-list">
                {files.map((file, index) => (
                  <div 
                    key={index}
                    className={`file-item ${
                      isCreating ? 'shadow-lg' : ''
                    } ${
                      fileProgress[file.name] === 100 ? 'opacity-50' : ''
                    }`}
                  >
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-sm font-medium">{file.name}</span>
                      <span className="text-xs text-slate-500">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{(file.size / (1024 * 1024)).toFixed(2)} MB
                      </span>
                    </div>
                    
                    <div className="progress-bar-container">
                      <div 
                        className="progress-bar-fill"
                        style={{ 
                          width: `${fileProgress[file.name] || 0}%`,
                          transition: 'width 0.5s ease-in-out'
                        }}
                      />
                    </div>
                    <div className="text-xs text-slate-500 mt-1">
                      {Math.round(fileProgress[file.name] || 0)}%
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="form-actions">
              <button className="back-button-mission" disabled={isEnhancing} onClick={() => setCurrentTab('mission')}>
              ← Back
              </button>
              <button
                className={`create-button ${isCreating ? 'creating' : ''}`} 
                onClick={handleSubmit}
                disabled={isCreating}
              />
            </div>
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </div>
  );
};

export default CreateAdminAgentModal;