import React, { useState } from 'react';
import { QuestionMarkCircledIcon, Cross2Icon, ArrowLeftIcon } from '@radix-ui/react-icons';
import * as Dialog from '@radix-ui/react-dialog';
import { useNavigate } from 'react-router-dom';
import '../styles/HelpPopup.css';
import YoutubeVideo from '../components/YoutubeVideo';

const HelpPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showCalk101, setShowCalk101] = useState(false);
  const navigate = useNavigate();

  const togglePopup = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setShowCalk101(false);
    }
  };

  const handleManageClick = () => {
    setIsOpen(false);
    navigate('/integrations');
  };

  const handleKnowledgeClick = () => {
    setIsOpen(false);
    navigate('/knowledge');
  };

  const handleBuildClick = () => {
    setIsOpen(false);
    navigate('/agents/create');
  };

  return (
    <>
      <button 
        className="help-trigger"
        onClick={togglePopup}
        aria-label="Help"
      >
        <QuestionMarkCircledIcon className="help-icon" />
      </button>

      <div className={`help-popup ${isOpen ? 'open' : ''}`}>
        <div className={`help-popup-pages ${showCalk101 ? 'show-calk101' : ''}`}>
          {/* Main Help Page */}
          <div className="help-popup-page main-page">
            <div className="help-popup-content">
              <div className="help-popup-header">
                <div className="help-popup-logo">
                  <img src="/calk-name.png" alt="Logo" className="logo" />
                </div>
                <button 
                  className="close-button-help"
                  onClick={togglePopup}
                >
                  ×
                </button>
              </div>
              <h2>Learn more about Calk's possibilities</h2>
              <div className="help-sections">
                <button 
                  className="help-section clickable"
                  onClick={() => setShowCalk101(true)}
                >
                  <div className="section-icon">🎯</div>
                  <div className="section-content">
                    <h3>Calk 101</h3>
                    <p>Learn everything about Calk in a 4 minutes.</p>
                  </div>
                </button>
                
                <a 
                  href="https://docs.calk-ai.com/" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="help-section clickable external-link"
                >
                  <div className="section-icon">📚</div>
                  <div className="section-content">
                    <h3>Tutorials and Documentation</h3>
                    <p>Master the Calk environment.</p>
                  </div>
                </a>
                
                <a 
                  href="https://join.slack.com/t/calkcommunity/shared_invite/zt-2rr3y121v-2L6KQ3l9tC8l47mkwUcJ8Q" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="help-section clickable external-link"
                >
                  <div className="section-icon"><img src="/images/slack.png" className="help-icon"/></div>
                  <div className="section-content">
                    <h3>Send us a Slack in the community</h3>
                    <p>Feeling lost ? Shoot us a DM in slack and we'll give you an answer!</p>
                  </div>
                </a>

                <div 
                  onClick={() => window.Intercom('show')}
                  className="help-section clickable external-link"
                  style={{ cursor: 'pointer' }}
                >
                  <div className="section-icon"><img src="/images/intercom.png" className="help-icon"/></div>
                  <div className="section-content">
                    <h3>Send us a message on Intercom</h3>
                    <p>Need a more structured answer ? We'll get back to you within 30 minutes ⤵️</p>
                  </div>
                </div>
                <img src="/images/docs/inter2.png" className="intercom-image"/>

                <div className="hot-topics">
                  <h3>Hot topics for you :</h3>
                  <div className="hot-topics-links">
                    <a 
                      href="https://docs.calk-ai.com/getting-started/chat" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="hot-topic-link"
                    >
                      → How to use Calk ?
                    </a>
                    <a 
                      href="https://docs.calk-ai.com/getting-started/create-and-manage-agent" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="hot-topic-link"
                    >
                      → How to create an Agent ?
                    </a>
                    <a 
                      href="https://docs.calk-ai.com/getting-deeper/knowledge" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="hot-topic-link"
                    >
                      → How to add some knowledge ?
                    </a>
                  </div>
                  {/*<h3>Ask for help to our agent</h3>
                  <div className="preset-questions">
                    <button className="preset-question">What would be the best agents for me ?</button>
                    <button className="preset-question">How to use an agent ?</button>
                    <button className="preset-question">How to use an integration ?</button>
                    <button className="preset-question">What model to use ?</button>
                  </div>
                  <div className="help-input-section">
                    <div className="help-input-container">
                      <input
                        type="text"
                        className="help-input"
                        placeholder="Ask a question or mention @help"
                      />
                    </div>
                    <div className="input-buttons-help">
                      <button 
                        className="action-button-help"
                      >
                        ↑
                      </button>
                    </div>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>

          {/* Calk 101 Page */}
          <div className="help-popup-page calk101-page">
            <div className="help-popup-content-calk-101">
              <div className="page-header">
                <button 
                  className="back-button-help"
                  onClick={() => setShowCalk101(false)}
                >
                  <ArrowLeftIcon />
                </button>
                <h2>Calk 101 🏁</h2>
              </div>

              <div className="calk-101-body">
                <h3 className="video-section-title">
                  <span className="camera-icon">📹</span> Discover in less than 3 mins
                </h3>
                
                <div className="video-container">
                  {/*<div className="play-button">▶</div>*/}
                  <YoutubeVideo />
                </div>

                {/* Rest of your Calk 101 content... */}
                <div className="feature-section">
                  <h3>Say hi to any AI Model!</h3>
                  <div className="feature-box">
                    <div className="feature-text">
                      <p className="feature-instruction">
                        ⌨️ Calk lets you chat with any model, simply go to Home and press @ to mention any model and start a chat
                      </p>
                    </div>
                    <div className="feature-image-placeholder">
                      <img src="/images/docs/docs-1.svg" className="docs-image"/>
                    </div>
                  </div>

                </div>

                <div className="feature-section">
                  <h3>Feed Calk Agents with your knowledge</h3>
                  <div className="knowledge-grid-help">
                    <div className="knowledge-card-help">
                      <div className="knowledge-header-help">
                        <span className="knowledge-icon-help">📖</span>
                        <span className="knowledge-title-help">Documents</span>
                      </div>
                      <p>Upload files (text, pdf, csv) directly in Calk.</p>
                      <button 
                        className="manage-button-help" 
                        onClick={handleKnowledgeClick}
                      >
                        <span className="manage-icon-help">📚</span> Knowledge
                      </button>
                    </div>
                    <div className="knowledge-card-help">
                      <div className="knowledge-header-help">
                        <span className="knowledge-icon-help">🔌</span>
                        <span className="knowledge-title-help">Integrations</span>
                      </div>
                      <p>Upload knowledge from slack, notion, drive and many more directly in Calk.</p>
                      <button 
                        className="manage-button-help" 
                        onClick={handleManageClick}
                      >
                        <span className="manage-icon-help">⚙️</span> Manage
                      </button>
                    </div>
                  </div>
                </div>

                <div className="feature-section">
                  <h3>Have a chat with an Agent!</h3>
                  <div className="agents-grid-help">
                    <div className="agent-card-help premade">
                      <h4>
                        <span className="calk-icon">📦</span> Pre made agents by Calk
                      </h4>
                      <p>Calk offers specialized assistants to tackle tasks with precision—whether provided by Calk, your company, or shared by coworkers.</p>
                      <p>Assistants combine AI models with tailored instructions and access to specific knowledge.</p>
                      <p className="muted">Generic model assistants like @mistral are also available for flexible support.</p>
                    </div>
                    <div className="agent-card-help custom">
                      <h4>
                        <span className="agent-icon">🤖</span> Agent made by you for you
                      </h4>
                      <p>With Calk, you can build custom agents with their own unique identity, tailored model, prompts, specialized knowledge, and tools—designed to perfectly fit your needs.</p>
                      <p className="muted">Agent with no specific knowledge will access everything in your knowledge</p>
                      <button 
                        className="build-button"
                        onClick={handleBuildClick}
                      >
                        + Build one
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && <div className="help-popup-overlay" onClick={togglePopup} />}
    </>
  );
};

export default HelpPopup;