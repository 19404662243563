import React, { useState, useRef, useEffect } from 'react';
import { Card, Button, ScrollArea } from '@radix-ui/themes';
import * as Dropdown from '@radix-ui/react-dropdown-menu';
import * as Slider from '@radix-ui/react-slider';
import { IoIosSend } from "react-icons/io";
import { FiChevronDown } from "react-icons/fi";
import axios from 'axios';
import { formatMessage } from './MessageFormatter-test';
import '../styles/CreateAgentChat.css';

const CreateAgentChat = ({ missionPrompt, model, onModelChange, agentName, agentIcon, temp, onTemperatureChange }) => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [temperature, setTemperature] = useState(temp/100);
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const [isTempDropdownOpen, setIsTempDropdownOpen] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesBoxRef = useRef(null);
  const inputRef = useRef(null);
  const isFirstMessage = useRef(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchCompaniesAndModels();
    return () => {
      if (chatId) {  // Add this check
        deleteTestChat(chatId);  // Pass chatId explicitly
      }
    };
  }, [chatId]); // Add chatId as dependency

  useEffect(() => {
    setTemperature(temp/100);
  }, [temp]);

  useEffect(() => {
    if (model && companies.length > 0) {
      // Find the company and model that matches the passed model name
      for (const company of companies) {
        const matchingModel = company.models.find(m => 
          m.name.toLowerCase() === model.toLowerCase()
        );
        if (matchingModel) {
          setSelectedCompany(company.companyName);
          setSelectedModel(matchingModel);
          break;
        }
      }
    }
  }, [model, companies]);

  const fetchCompaniesAndModels = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chat/companies-models`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      const filteredCompanies = response.data.filter(company => 
        company.companyName.toLowerCase() !== 'no one'
      );
      setCompanies(filteredCompanies);
    } catch (error) {
      console.error('Error fetching companies and models:', error);
    }
  };

  const deleteTestChat = async () => {
    if (!chatId) return;
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/chat/${chatId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
    } catch (error) {
      console.error('Error deleting test chat:', error);
    }
  };

  const startNewChat = async () => {
    if (!selectedModel) return;
    
    try {
      const chatResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/chat/new`,
        {
          title: 'Test Chat',
          aiModel: selectedModel.name,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      const newChatId = chatResponse.data._id;
      setChatId(chatResponse.data._id);
      
      if (inputRef.current) {
        inputRef.current.style.height = 'auto';
      }

      return newChatId;
    } catch (error) {
      console.error('Error starting chat:', error);
    }
  };

  const getModelEndpoint = (model) => {
    switch (model.toLowerCase()) {
      case 'o1':
        return 'o1';
      case 'o1 mini':
        return 'o1mini';
      case 'gpt 4o mini':
        return 'gpt4omini';
      case 'gpt 4':
        return 'gpt4';
      case 'claude 3.5':
        return 'claude3.5';
      case 'claude sonnet':
        return 'claudesonnet';
      case 'claude opus':
        return 'claude-opus';
      case 'claude haiku':
        return 'claude-haiku';
      case 'mistral ai large':
        return 'mistral-large';
      case 'mistral ai medium':
        return 'mistral-medium';
      case 'mistral ai small':
        return 'mistral-small';
      case 'codestral':
        return 'codestral';
      case 'llama 3.1':
        return 'llama3.1';
      case 'gemini 1.5':
        return 'gemini1.5';
      case 'cohere':
        return 'cohere';
      case 'stable diffusion':
      case 'stablediffusion':
        return 'stablediffusion';
      case 'dall-e 3':
      case 'dall-e3':
        return 'dall-e3';
      default:
        return model.toLowerCase().replace(' ', '');
    }
  };

  const handleSendMessage = async () => {
    if (!input.trim() || !selectedModel || isLoading) return;

    const messageContent = input;
    setInput('');

    let currentChatId = chatId;
    // Start new chat if this is the first message and wait for it to complete
    if (isFirstMessage.current) {
      try {
        currentChatId = await startNewChat();
        if (!currentChatId) {
          console.error('Failed to create chat');
          return;
        }
        isFirstMessage.current = false;
      } catch (error) {
        console.error('Error creating chat:', error);
        return;
      }
    }

    // Verify we have a chatId before proceeding
    // if (!chatId) {
    //   console.error('No chat ID available');
    //   return;
    // }

    const newMessage = {
      content: messageContent,
      role: 'user',
    };

    setMessages(prev => [...prev, newMessage]);
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/chat/${currentChatId}/${getModelEndpoint(selectedModel.name.toLowerCase())}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            message: messageContent,
            isTest: true,
            missionPrompt,
            temperature
          })
        }
      );

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n').filter(line => line.trim() !== '');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            setIsLoading(false);
            const data = line.slice(5);
            if (data === '[DONE]') continue;

            try {
              const parsed = JSON.parse(data);
              if (parsed.content) {
                setMessages(prev => {
                  const lastMessage = prev[prev.length - 1];
                  if (lastMessage?.role === 'assistant') {
                    return [
                      ...prev.slice(0, -1),
                      { ...lastMessage, content: lastMessage.content + parsed.content }
                    ];
                  } else {
                    return [...prev, { role: 'assistant', content: parsed.content }];
                  }
                });
                scrollToBottom();
              }
            } catch (e) {
              // Ignore parsing errors
            }
          }
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleCompanySelect = (companyName) => {
    setSelectedCompany(companyName);
    const company = companies.find(c => c.companyName === companyName);
    if (company && company.models.length > 0) {
      handleModelSelect(company.models[0]);
    }
  };

  const handleModelSelect = (model) => {
    setSelectedModel(model);
    if (onModelChange) {
      onModelChange(model.name);
    }
  };

  // Modify the temperature change handler
  const handleTemperatureChange = (value) => {
    setTemperature(value);
    if (onTemperatureChange) {
      onTemperatureChange(value * 100); // Convert back to 0-100 scale when sending to parent
    }
  };


  // const scrollToBottom = () => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  // };

  const scrollToBottom = () => {
    if (messagesBoxRef.current) {
      const scrollArea = messagesBoxRef.current;
      scrollArea.scrollTop = scrollArea.scrollHeight;
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    adjustTextareaHeight(e.target);
  };

  return (
    <div className="agent-chat-container">
      <div className="agent-chat-model-selection">
        <Dropdown.Root>
          <Dropdown.Trigger className="agent-chat-model-trigger">
            <div className="agent-chat-trigger-content">
              <span>
                {selectedModel ? selectedModel.name : 'Select Model'} • Temp {temperature}
              </span>
              <FiChevronDown />
            </div>
          </Dropdown.Trigger>

          <Dropdown.Portal>
            <Dropdown.Content className="agent-chat-selector-window">
              <h3 className="agent-chat-window-title">Choose a provider and model</h3>
              <div className="agent-chat-selector-section">
                <Dropdown.Root>
                  <Dropdown.Trigger className="agent-chat-section-trigger">
                    <div className="agent-chat-section-header">
                      {selectedCompany ? (
                        <>
                          <img 
                            src={`/images/all_icons/${companies.find(c => c.companyName === selectedCompany)?.models[0]?.icon}`} 
                            alt={selectedCompany} 
                          />
                          <span>{selectedCompany}</span>
                        </>
                      ) : (
                        <span>Choose a provider and model</span>
                      )}
                      <FiChevronDown />
                    </div>
                  </Dropdown.Trigger>

                  <Dropdown.Content className="agent-chat-section-content">
                    {companies.map((company) => (
                      <Dropdown.Item
                        key={company.companyName}
                        className="agent-chat-company-item"
                        onSelect={() => handleCompanySelect(company.companyName)}
                      >
                        <img 
                          src={`/images/all_icons/${company.models[0]?.icon}`} 
                          alt={company.companyName} 
                        />
                        <span>{company.companyName}</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Content>
                </Dropdown.Root>

                {selectedCompany && (
                  <Dropdown.Root>
                    <Dropdown.Trigger className="agent-chat-section-trigger">
                      <div className="agent-chat-section-header">
                        {selectedModel ? (
                          <>
                            <img 
                              src={`/images/all_icons/${selectedModel.icon}`} 
                              alt={selectedModel.name} 
                            />
                            <span>{selectedModel.name}</span>
                          </>
                        ) : (
                          <span>Select Model</span>
                        )}
                        <FiChevronDown />
                      </div>
                    </Dropdown.Trigger>

                    <Dropdown.Content className="agent-chat-section-content">
                      {companies
                        .find(c => c.companyName === selectedCompany)
                        ?.models.map((model) => (
                          <Dropdown.Item
                            key={model._id}
                            className="agent-chat-model-item"
                            onSelect={() => handleModelSelect(model)}
                          >
                            <img 
                              src={`/images/all_icons/${model.icon}`} 
                              alt={model.name} 
                            />
                            <span>{model.name}</span>
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Content>
                  </Dropdown.Root>
                )}
                <h3 className="agent-chat-window-title">Configuration</h3>
                <Dropdown.Root>
                  <Dropdown.Trigger className="agent-chat-section-trigger">
                    <div className="agent-chat-section-header">
                      <div className="agent-chat-temp-header">
                        <span className="agent-chat-temp-icon">🌡️</span>
                        Temperature
                      </div>
                      <FiChevronDown />
                    </div>
                  </Dropdown.Trigger>

                  <Dropdown.Content className="agent-chat-section-content">
                    <div className="agent-chat-temperature-panel">
                      <div className="agent-chat-temp-value">{temperature}</div>
                      <div className="agent-chat-temp-control">
                        <Slider.Root
                          value={[temperature]}
                          onValueChange={([value]) => {
                            setTemperature(value);
                            if (onTemperatureChange) {
                              onTemperatureChange(Math.round(value * 100));
                            }
                          }}
                          max={1}
                          step={0.1}
                          className="agent-chat-slider-root"
                        >
                          <Slider.Track className="agent-chat-slider-track">
                            <Slider.Range className="agent-chat-slider-range" />
                          </Slider.Track>
                          <Slider.Thumb className="agent-chat-slider-thumb" />
                        </Slider.Root>
                      </div>
                      <div className="agent-chat-temp-description">
                        Temperature controls the generation creativity. Low values generate consistent responses, while high value create more varied, creative outputs
                      </div>
                    </div>
                  </Dropdown.Content>
                </Dropdown.Root>
              </div>
            </Dropdown.Content>
          </Dropdown.Portal>
        </Dropdown.Root>
      </div>

      <div className="agent-chat-messages">
        <ScrollArea ref={messagesBoxRef}>
          {messages.map((message, index) => (
            <React.Fragment key={index}>
              <div className="agent-message-header">
                {message.role === 'assistant' ? (
                  <>
                    <img 
                      src={`/images/all_icons/${agentIcon}`} 
                      alt={agentName} 
                    />
                    <span>{agentName}</span>
                  </>
                ) : (
                  <span>You</span>
                )}
              </div>
              <div className={`agent-chat-message ${message.role}`}>
                {formatMessage(message)}
              </div>
            </React.Fragment>
          ))}
          {isLoading && (
            <div className="agent-analyzing-container">
              <video 
                autoPlay 
                loop 
                muted 
                playsInline
                className="agent-loader-video"
              >
                <source src="/loader.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
          <div ref={messagesEndRef} />
        </ScrollArea>
      </div>

      <div className="agent-chat-input-container">
        <textarea
          ref={inputRef}
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Test your agent..."
          rows={1}
          className="agent-chat-input"
        />
        <Button 
          className="agent-chat-send-button"
          onClick={handleSendMessage}
          disabled={!selectedModel || !input.trim() || isLoading}
        >
          <IoIosSend size="20"/>
        </Button>
      </div>
    </div>
  );
};

export default CreateAgentChat;